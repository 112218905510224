import React, { Component } from "react";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from '@react-oauth/google';
import { GetText, MD5, RandomNumber } from '../config/helper';
import { Container, Card, Form, Button } from "react-bootstrap";
// import { FaInfoCircle, FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { FaCircleInfo, FaCircleCheck, FaTriangleExclamation } from "react-icons/fa6";
import '../config/global';

// import { ic_10k } from "react-icons-kit/md";
//  const {google} = require('googleapis');

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;
  
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  user_id: '',
	  fullname: '',
	  email: '',
	  phone: '',
	  username: '',
	  password: '',
	  password_confirm: '',
	  otp: '',
	  expiry: '',
	  language: localStorage.getItem('language'),
      redirect: '',
	  tab_form: '',
	  form_error: []
    }
	this.inputFullname = this.inputFullname.bind(this);
    this.inputEmail = this.inputEmail.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.inputUsername = this.inputUsername.bind(this);
    this.inputPassword = this.inputPassword.bind(this);
    this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
    this.inputOtp = this.inputOtp.bind(this);
    this.login = this.login.bind(this);
	this.register = this.register.bind(this);
    this.activate = this.activate.bind(this);
    this.forgot = this.forgot.bind(this);
    this.reset = this.reset.bind(this);
    this.confirmOtp = this.confirmOtp.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  
  getLanguage() {
	fetch('https://ipapi.co/json/', {
			method: "GET",
	}).then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			console.log("Something happened wrong");
		}
	}).then((resultJson) => {
		let language = '';
		if (resultJson.country_name === 'Indonesia') { language = 'Indonesian'; } 
		else { language = 'English'; }

		this.setState({
			language: language
		});

		// let params = new URLSearchParams(window.location.search);
		// let redirect = params.get('redirect');
		// if (redirect) {
		// 	this.setState({
		// 		language: language,
		// 		redirect: redirect
		// 	});
		// } else {
		// 	this.setState({
		// 		language: language
		// 	});
		// }
	}); 
  }

  setTab() {
	var redirect = -1, res_redirect = "";
	var token="", res_token = "", docid="", expiry=0;
	var current = new Date().getTime();
	var url = window.location.href;

	var params = url.split('?'); 
	var tabKey = params[0].substring(params[0].lastIndexOf('/')+ 1);
	
	if(params.length > 1) { 
		if(params[1].indexOf('redirect',0) > -1) { 
			res_redirect = decodeURIComponent(params[1].toString().replace("redirect=","")); 
			this.setState({
				tab_form: 'login',
				redirect: res_redirect
			});
		} else if(params[1].indexOf('token',0) > -1) { 

			token = params[1].toString().replace("token=",""); 
			docid = token.substring(1,3) + token.substring(5,9) + token.substring(12,17) + token.substring(23,28) + token.substring(token.length - 4);
		
			if(tabKey === "activation") { 
				this.setState({
					tab_form: tabKey
				});		
				this.activate(docid,tabKey);
			} else  if(tabKey === "reset") { 
				var key = token.substring(0,1);
				var hash = token.substring(3,5) + token.substring(9,12) + token.substring(17,23) + token.substring(28,29);
				var expiry = Math.floor(hash / key * 100).toString().substring(0,13);
				var current = new Date().getTime();
		
				this.setState({
					user_id: docid,
					expiry: expiry,
					tab_form: (parseInt(expiry) < parseInt(current) ? 'reset-expired' : 'reset')
				});
			} else {
				if(tabKey === "") { tabKey = "login"; } 
				this.setState({
					tab_form: tabKey
				});
			}
		} else {
			if(tabKey === "") { tabKey = "login"; } 
			this.setState({
				tab_form: tabKey
			});
		}
	} else {
		if(tabKey === "") { tabKey = "login"; } 
		this.setState({
			tab_form: tabKey
		});
	}
	
  }

  inputFullname(event) {
	var error = [];
	if (event.target.value.length > 80) {
		document.getElementById('submit').setAttribute('disabled', 'true');
		error.push('fullname_max');
	} else {
		document.getElementById('submit').removeAttribute('disabled');
	}
	this.setState({ 
		fullname: event.target.value,
		form_error: error
	});
  }
  inputEmail(event) {
    this.setState({ 
      email: event.target.value,
      form_error: []
    });
  }

  inputPhone(event) {
    this.setState({ 
      phone: event.target.value,
      form_error: []
    });
  }
  inputUsername(event) {
    this.setState({ 
      username: event.target.value,
      form_error: []
    });
  }

  inputPassword(event) {
    this.setState({ 
      password: event.target.value,
      form_error: []
    });
  }
  inputPasswordConfirm(event) {		
    var error = [];
    if (this.state.password !== event.target.value) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('password_same');
      this.setState({
        form_error: error
      });
    } else {
      document.getElementById('submit').removeAttribute('disabled');
      this.setState({
        form_error: []
      });
    }
    this.setState({ 
      password_confirm: event.target.value
    });
  }

  inputOtp(event) {
    this.setState({ 
      otp: event.target.value,
      form_error: []
    });
  }

  error = (response) => {
	console.log("Something happened wrong");
	document.getElementsByClassName("err-area")[0].style.display = "block";
  }

  login(event) {
	event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.username)) {
      error.push('username');
    }
    if (!(this.state.password)) {
      error.push('password');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Login';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
	
		var dataText = '{"user_email":"' + this.state.username + '", "user_username":"' + this.state.username + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"login-email"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/ceklogin", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				error.push('invalid');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = 'Login';
				document.getElementById('submit').removeAttribute('disabled');
				if(resultJson.values[0].error === "OTP required") { 
					localStorage.setItem("doc_id", resultJson.values[0].data.docid);
					localStorage.setItem("email", resultJson.values[0].data.email);
					window.location.href = "/login/confirm-otp";
				}
			} else if (resultJson.values[0].docid) {
				var member = resultJson.values[0];
				var picavatar = "";
				var setlangz = this.state.language;
				var companyDomain = "";
				if (member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
				if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
				if(member.companyName !== undefined && member.companyName !== "undefined" && member.companyName !== "") { companyDomain = member.companyName; }

				localStorage.setItem("logged_in", global.config.randomLoginKey);
				//localStorage.setItem("google_id", member.memberUserId);
				localStorage.setItem("doc_id", member.docid);
				localStorage.setItem("name", member.memberName);
				localStorage.setItem("email", member.memberEmail);
				localStorage.setItem("status", member.memberPlan);
				localStorage.setItem("ulvl", member.userLevel);
				localStorage.setItem("avatar", picavatar);
				localStorage.setItem("language", setlangz);
				localStorage.setItem("token", member.uniqueToken);
				localStorage.setItem("mcomp", companyDomain);
				window.location.href = "/" + this.state.redirect;
			}
			
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
		
	} 
  }

  cekLogin(dataText) {
	// console.log("datatext");
	// console.log(dataText);
	
	var dataJson = JSON.parse(dataText);
	fetch(global.config.urlLink+"/ceklogin", {
		method: "POST",
		headers: {
			'Accept': "application/json",
			'Content-Type': "application/json",
			'Access-Control-Allow-Origin': "*",
			'Authorization': authKey
		},
		body: JSON.stringify(dataJson),
	})
	.then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			console.log("Something happened wrong");
		}
	})
	.then((resultJson) => {
		var udocid = dataJson.docid;
		var ufullname = dataJson.user_fullname;
		var uemailname = dataJson.user_email;
		var picavatar = dataJson.user_picture;
		var setlangz = this.state.language;
		var mplan = global.config.memberPlan[0];
		var ulevel = "User";
		var uniqueToken = "", companyDomain="";
		if (resultJson.values[0].docid) {
			var member = resultJson.values[0];
			if(member.docid !== undefined && member.docid !== "") { udocid = member.docid; }
			if(member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
			if(member.memberName !== undefined && member.memberName !== "") { ufullname = member.memberName; }
			if(member.memberEmail !== undefined && member.memberEmail !== "") { uemailname = member.memberEmail; }
			if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
			if(member.memberPlan !== undefined && member.memberPlan !== "") { mplan = member.memberPlan; }
			if(member.userLevel !== undefined && member.userLevel !== "") { ulevel = member.userLevel; }
			if(member.uniqueToken !== undefined && member.uniqueToken !== "") { uniqueToken = member.uniqueToken; }
			if(member.companyName !== undefined && member.companyName !== "") { companyDomain = member.companyName; }
		}
		localStorage.setItem("logged_in", global.config.randomLoginKey);
		//localStorage.setItem("google_id", response.profileObj.googleId);
		localStorage.setItem("doc_id", udocid);
		localStorage.setItem("avatar", picavatar);
		localStorage.setItem("name", ufullname);
		localStorage.setItem("email", uemailname);
		localStorage.setItem("language", setlangz);
		localStorage.setItem("status", mplan);
		localStorage.setItem("ulvl", ulevel);
		localStorage.setItem("token", uniqueToken);
		localStorage.setItem("mcomp", companyDomain);
		window.location.href = "/" + this.state.redirect;

	})
	.catch((err) => {
		alert(err);
		window.location.href = "/" + this.state.redirect;
	});
	
  }
  successLogin = (response) => {
	document.getElementsByClassName("err-area")[0].style.display = "none";
	var dataSend = response;
	fetch(global.config.urlLink+"/getggprofile", {
		method: 'POST',
		headers: {
			'Accept': "application/json",
			'Content-Type': "application/json",
			'Access-Control-Allow-Origin': "*",
			'Authorization': authKey
		},
		body: JSON.stringify(dataSend),
	  })
	  .then(response => {
		if(response.status === 200) {  return response.json(); } 
		else { console.log('Something happened wrong.'); }
	  })
	  .then(resJson => {
		var dataTextSend = '{"user_email":"' + resJson.values.email + '","user_id":"' + resJson.values.sub + '","user_fullname":"' + resJson.values.name + '","user_picture":"' + resJson.values.picture + '","user_language":"' + this.state.language + '"}';
		this.cekLogin(dataTextSend);
	  });

	/*
	var oauth2Client = response.credential;

	var gmail = google.gmail('v1');
	gmail.users.getProfile({
	auth: oauth2Client,
	userId: 'me'
	}, function(err, res) {
	if (err) {
		console.log(err);
	} else {
		callback(res.data);
	}
	});
	*/
	/*
	// fetch("https://www.googleapis.com/oauth2/v1/userinfo?access_token="+usertoken, {
	fetch("https://www.googleapis.com/userinfo/v2/me", {
		method: 'GET',
		mode: 'no-cors',
		headers: {
		//   'Accept': 'application/json',
		//   'Content-Type': 'application/json',
		//   'Access-Control-Allow-Origin': '*',
		  'Authorization': 'Bearer '+usertoken
		}
	  })
	  .then(response => {
		console.log(response);
		if(response.status === 200) {  return response.json(); } 
		else { console.log('Something happened wrong.'); }
	  })
	  .then(resJson => {
		console.log("get user");
		console.log(resJson);
	  });
	*/
	// var dataTextSend = '{"user_email":"' + response.profileObj.email + '","user_id":"' + response.profileObj.googleId + '","user_fullname":"' + response.profileObj.name + '","user_picture":"' + response.profileObj.imageUrl + '","user_language":"' + this.state.language + '"}';
	// this.cekLogin(dataTextSend);
  }

  register(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.fullname)) {
      error.push('fullname');
    }
    if (this.state.fullname.length > 80) {
      error.push('fullname_max');
    }
    if (!(this.state.email)) {
      error.push('email');
    }
    if (!(this.state.password)) {
      error.push('password');
    }
    if (!(this.state.password_confirm)) {
      error.push('password_confirm');
    }
		if (this.state.password !== this.state.password_confirm) {
      error.push('password_same');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Daftar" : "Register";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
	
		// var dataText = '{"user_id":"' + RandomNumber(21) + '","user_fullname":"' + this.state.fullname + '","user_email":"' + this.state.email + '","user_phone":"' + this.state.phone + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"register"}';
		var dataText = '{"user_id":"'+RandomNumber(21)+'","user_fullname":"' + this.state.fullname + '","user_email":"' + this.state.email + '","user_phone":"' + this.state.phone + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"register"}';
		var dataSend = JSON.parse(dataText);
		fetch(global.config.urlLink+"/register", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				error.push('inuse');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = GetText() ? "Daftar" : "Register";
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				//window.location.href = "/register-success";	
				document.getElementById("register-page").style.display = "none";
				document.getElementById("register-message").style.display = "flex";
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
		
  }

  forgot(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.email)) {
      error.push('email');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Kirim Link" : "Send Link";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
	
		var dataText = '{"user_email":"' + this.state.email + '","user_id":"' + this.state.user_id + '","form_id":"forgot"}';
		var dataSend = JSON.parse(dataText);
		
		fetch(global.config.urlLink+"/forgot", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				if(resultJson.values[0].error === "account is deactive") {
					error.push('account is deactive');
					this.setState({
						form_error: error
					});
				} else {
					error.push('invalid');
					this.setState({
						form_error: error
					});
				}
				
				document.getElementById('submit').innerText = GetText() ? "Kirim Link" : "Send Link";
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				// window.location.href = "/forgot-success";
				document.getElementById("forgot-page").style.display = "none";
				document.getElementById("forgot-message").style.display = "flex";		
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
		
  }

  reset(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.password)) {
      error.push('password');
    }
    if (!(this.state.password_confirm)) {
      error.push('password_confirm');
    }
		if (this.state.password !== this.state.password_confirm) {
      error.push('password_same');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Simpan" : "Save";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		var dataText = '{"user_id":"' + this.state.user_id + '","user_password":"' + MD5(this.state.password) + '","form_id":"reset"}';
		var dataSend = JSON.parse(dataText);
		fetch(global.config.urlLink+"/passwordreset", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			if (resultJson.values[0].error) {
				var error = [];
				error.push('invalid');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = (GetText() ? 'Simpan' : 'Save');
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				// window.location.href = "/reset-success";	
				document.getElementById("reset-page").style.display = "none";
				document.getElementById("reset-message").style.display = "flex";				
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
		
	}

  confirmOtp(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.otp)) {
      error.push('otp');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Kirim" : "Submit";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		var dataText = '{"otpcode":"' + this.state.otp + '","user_id":"'+localStorage.getItem('doc_id')+'","email":"'+localStorage.getItem('doc_id')+'","form_id":"confirm-otp"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/ceklogin", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			document.getElementById('submit').innerText = (GetText() ? 'Kirim' : 'Submit');
			document.getElementById('submit').removeAttribute('disabled');
			if (resultJson.values[0].error) {
				var error = [];
				error.push('invalid');
				this.setState({
					form_error: error
				});
			} else {
				var member = resultJson.values[0];
				var picavatar = "";
				var setlangz = this.state.language;
				var companyDomain = "";
				if (member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
				if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
				if(member.companyName !== undefined && member.companyName !== "undefined" && member.companyName !== "") { companyDomain = member.companyName; }
				localStorage.setItem("logged_in", global.config.randomLoginKey);
				//localStorage.setItem("google_id", member.memberUserId);
				localStorage.setItem("doc_id", member.docid);
				localStorage.setItem("name", member.memberName);
				localStorage.setItem("email", member.memberEmail);
				localStorage.setItem("status", member.memberPlan);
				localStorage.setItem("ulvl", member.userLevel);
				localStorage.setItem("avatar", picavatar);
				localStorage.setItem("language", setlangz);
				localStorage.setItem("token", member.uniqueToken);
				localStorage.setItem("mcomp", companyDomain);
				window.location.href = "/" + this.state.redirect;			
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
	}
	
	

	activate(docid,tabKey) {
		var dataText = '{"docid":"' + docid + '","form_id":"activation"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/activation", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			// var error = [];
			if (resultJson.values[0].error) {
				if(resultJson.values[0].error ==="account is active") {
					document.getElementById("activation-success-message").style.display = "none";
					document.getElementById("activation-failed-message-1").style.display = "none";	
					document.getElementById("activation-failed-message-2").style.display = "flex";
					
				} else {
					document.getElementById("activation-success-message").style.display = "none";
					document.getElementById("activation-failed-message-1").style.display = "flex";	
					document.getElementById("activation-failed-message-2").style.display = "none";	
				}

				// error.push('wrong_token');
				// this.setState({
				// 	form_error: error
				// });
			} else if (resultJson.values[0].docid) {
				// console.log(resultJson.values[0]);
				document.getElementById("activation-success-message").style.display = "flex";
				document.getElementById("activation-failed-message-1").style.display = "none";	
				document.getElementById("activation-failed-message-2").style.display = "none";
				
			}
			
		});
	}

	  
	toggleForm(tab) {		
		window.location.href = "/" + tab;
  	}

	componentDidMount() {
		document.getElementsByClassName("err-area")[0].style.display = "none";
		if(localStorage.getItem('language') === undefined || localStorage.getItem('language') === "") {
			this.getLanguage();
		}
		this.setTab();
	}
	
	render() {
		return (
			<div className="Login">
				<Container>
					<div className="row justify-content-center p-5">
						<div className="col-lg-6 text-center">
						{ this.state.tab_form === 'login' && <h5 className="mb-3">{GetText() ? "Masuk ke" : "Login to"}</h5> }
							{ this.state.tab_form === 'register' && <h5 className="mb-3">{GetText() ? "Daftar ke" : "Register to"}</h5> }
							{ this.state.tab_form === 'forgot' && <h5 className="mb-3">{GetText() ? "Lupa Password" : "Forgot Password"}</h5> }
							{ this.state.tab_form === 'reset' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> }
							{ this.state.tab_form === 'reset-expired' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> }
							
							
							{/* { this.state.tab_form === 'register-success' && <h5 className="mb-3">{GetText() ? "Pendaftaran Berhasil" : "Register Success"}</h5> } */}
							{/* { this.state.tab_form === 'reset-success' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> } */}
							{ this.state.tab_form === 'activation' && <h5 className="mb-3">{GetText() ? "Aktivasi Akun" : "Activation Account"}</h5> }
							{ this.state.tab_form === 'confirm-otp' && <h5 className="mb-3">{GetText() ? "Konfirmasi OTP" : "Confirm OTP"}</h5> }
							
							<h2 className="mb-5 text-red text-800">NOVAIO ACADEMY</h2>
							<div className="err-area" style={{display: "none"}}>
								<div className="d-flex justify-content-center">
									<div><FaCircleInfo className="text-danger"/></div>
									<div className="ms-2 text-danger">{GetText() ? "tidak berhasil login" : "failed to login"}</div>
								</div>
							</div>

							{
								this.state.tab_form === 'login' && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<Form onSubmit={this.login}>
												<div className="mb-3">
													<label className="fw-bold form-label">{GetText() ? "Email" : "Email"}</label>
													{this.state.form_error.some(item => item === 'username') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="text" name="username" value={this.state.username} onChange={this.inputUsername} autoComplete="username"/>
												</div>
												<div className="mb-3">
													<label className="fw-bold form-label">{GetText() ? "Password" : "Password"}</label>
													{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="current-password"/>
												</div>
												<p className="text-link text-end mb-0" onClick={() => this.toggleForm('forgot')}>{GetText() ? "Lupa password?" : "Forgot password?"}</p>
												{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Username atau password tidak ditemukan" : "Invalid username or password"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Login" id="submit">{GetText() ? "Login" : "Login"}</Button>
											</Form>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('register')}>
													Belum memiliki akun? <span className="text-primary">Daftar</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('register')}>
														Not having an account yet? <span className="text-primary">Register</span>
													</p>
												)
											
											}
											<hr size="1" className="mt-4 mb-4"/>
											<p className="text-center mb-4">{GetText() ? "atau gunakan akun Google" : "or use Google account"}</p>
											<div className="d-flex align-items-center justify-content-center mb-4">
												<GoogleLogin 
												  type="standard"
												  theme="outline"
												  text="signin"
												  shape="rectangular"
												  onSuccess={this.successLogin}
												//   onSuccess={credentialResponse => {
												// 	console.log(credentialResponse);
												//   }}
												  onError={() => {
													console.log('Login Failed');
												  }}
												
												/> 
											</div>
											
											{/* <GoogleLogin 
												//clientId= {global.config.clientLoginId} 
												className="btn-google"
												onSuccess={credentialResponse => {
													console.log(credentialResponse);
												  }}
												  onError={() => {
													console.log('Login Failed');
												  }}
												 
												// onSuccess={this.success}
												// onError={this.error}
												// cookiePolicy={"single_host_origin"}
												// onClick={() => {
												// 	document.head.append(
												// 		<meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin" />
												// 	);
												// }}
											>	
												<img className="mt-3 mb-4" src={process.env.PUBLIC_URL + "/img/ic_ggle.jpg"} alt="Google Login"/>
												
												{GetText() ? "Masuk dengan akun Google" : "Sign in with Google"}
											</GoogleLogin>
											 */}
											
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'register' && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<div id="register-page">
												<Form onSubmit={this.register}>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Nama Lengkap" : "Full Name"}</label>
														{this.state.form_error.some(item => item === 'fullname') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														{this.state.form_error.some(item => item === 'fullname_max') ? <span className="error-message">{GetText() ? 'Maksimal 80 karakter!' : 'Max 80 characters!'}</span> : ''}
														<input className="form-control" type="text" name="fullname" value={this.state.fullname} onChange={this.inputFullname}/>
													</div>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Email" : "Email"}</label>
														{this.state.form_error.some(item => item === 'email') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														<input className="form-control" type="email" name="email" value={this.state.email} onChange={this.inputEmail} autoComplete="username"/>
													</div>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Nomor HP" : "Phone Number"}</label>
														<input className="form-control" type="tel" name="phone" value={this.state.phone} onChange={this.inputPhone}/>
													</div>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Password" : "Password"}</label>
														{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="new-password"/>
													</div>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Konfirmasi Password" : "Confirm Password"}</label>
														{this.state.form_error.some(item => item === 'password_confirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														{this.state.form_error.some(item => item === 'password_same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
														<input className="form-control" type="password" name="password_confirm" value={this.state.password_confirm} onChange={this.inputPasswordConfirm} autoComplete="confirm-password"/>
													</div>
													{this.state.form_error.some(item => item === 'inuse') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Email sudah digunakan" : "Email already in use"}</p> : ''}
													<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Register" id="submit">{GetText() ? "Daftar" : "Register"}</Button>
												</Form>

												<p className="text-center mt-5 mb-4">{GetText() ? "atau daftar menggunakan akun Google" : "or register with Google account"}</p>
												<div className="d-flex align-items-center justify-content-center mb-4">
													<GoogleLogin 
													type="standard"
													theme="outline"
													text="signin"
													shape="rectangular"
													onSuccess={this.successLogin}
													//   onSuccess={credentialResponse => {
													// 	console.log(credentialResponse);
													//   }}
													onError={() => {
														console.log('Login Failed');
													}}
													
													/> 
												</div>
											</div>
											<div id="register-message" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaCircleCheck color="green" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Selamat!"  : "Congratulations!" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Akun Anda berhasil didaftarkan.<br/>Silakan cek email anda untuk melanjutkan</div>) 
														: ( <div  className="mt-4">Your account has been successfully registered.<br/>Please check your email to continue</div> ) 
													}
												</div>
											</div>
											{
												GetText() ? (
													<p className="text-link text-center mt-5" onClick={() => this.toggleForm('login')}>
													Sudah memiliki akun? <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-5" onClick={() => this.toggleForm('login')}>
														Already have an account? <span className="text-primary">Login</span>
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								(this.state.tab_form === 'forgot') && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<div id="forgot-page">
												<Form onSubmit={this.forgot}>
													<p className="mb-4">
														{	GetText() ? 
																"Masukkan email yang Anda gunakan untuk login. Instruksi untuk mengatur ulang password Anda akan dikirimkan ke email yang Anda masukkan" 
																: "Enter the email that you use to login. We'll send you an email with instructions to reset your password."
														}
													</p>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Email" : "Email"}</label>
														{this.state.form_error.some(item => item === 'email') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														<input className="form-control" type="text" name="email" value={this.state.email} onChange={this.inputEmail} autoComplete="username"/>
													</div>
													{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Email tidak ditemukan" : "Invalid email"}</p> : ''}
													{this.state.form_error.some(item => item === 'account is deactive') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Akun tidak aktif" : "Account is deactive"}</p> : ''}
													<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Forgot" id="submit">{GetText() ? "Kirim Link" : "Send Link"}</Button>
												</Form>
											</div>
											<div id="forgot-message" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaCircleCheck color="green" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Berhasil"  : "Success" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Permintaan reset password berhasil dikirim.<br/>Silakan cek email Anda untuk dapat mengatur ulang password</div>) 
														: ( <div  className="mt-4">Password reset request was sent successfully.<br/>Please check your email to reset the password</div> ) 
													}
												</div>
											</div>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span className="text-primary">Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							
							{
								this.state.tab_form === 'reset' && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<div id="reset-page">
												<Form onSubmit={this.reset}>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Password Baru" : "New Password"}</label>
														{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="new-password"/>
													</div>
													<div className="mb-3">
														<label className="fw-bold form-label">{GetText() ? "Konfirmasi Password" : "Confirm Password"}</label>
														{this.state.form_error.some(item => item === 'password_confirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
														{this.state.form_error.some(item => item === 'password_same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
														<input className="form-control" type="password" name="password_confirm" value={this.state.password_confirm} onChange={this.inputPasswordConfirm} autoComplete="confirm-password"/>
													</div>
													{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Link tidak valid" : "Invalid Link"}</p> : ''}
													<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" id="submit">{GetText() ? "Simpan" : "Save"}</Button>
												</Form>
											</div>
											<div id="reset-message" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaCircleCheck color="green" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Password berhasil diubah"  : "Password Changed" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Selamat! Password Anda telah berhasil diubah.<br/>Silakan <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> dengan menggunakan password baru Anda.</div>) 
														: ( <div  className="mt-4">Congratulations! Your password has been changed successfully.<br/>Please <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> using your new password.</div> ) 
													}
												</div>
											</div>
										</Card.Body>
									</Card>
								)
							}
							
							{
								this.state.tab_form === 'reset-expired' && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<div id="reset-expired-message" className="w-100">
												<div  className="text-center w-100">
													<div><FaTriangleExclamation color="#ffcc00" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Tautan Kadaluwarsa"  : "Expired Link" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Tautan reset password telah kadaluwarsa.<br/>Dapatkan tautan baru dengan mencoba <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/forgot'}>Lupa Password</span> lagi.</div>) 
														: ( <div  className="mt-4">The reset password link has been expired.<br/>Get a new link by trying the <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/forgot'}>Forgot Password</span> again.</div> ) 
													}
												</div>
											</div>
											
											{
												GetText() ? (
													<div className="mt-5">
														<p className="text-link text-center">atau</p>
														<p className="text-link text-center mt-2" onClick={() => this.toggleForm('login')}>
															Kembali ke halaman <span className="text-primary">Login</span>
														</p>
													</div>
												) : (
													<div className="mt-5">
														<p className="text-link text-center">or</p>
														<p className="text-link text-center mt-2" onClick={() => this.toggleForm('login')}>
															Back to <span className="text-primary">Login</span> page
														</p>
													</div>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'activation' && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<div id="activation-success-message" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaCircleCheck color="green" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Aktivasi Berhasil"  : "Activation Success" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Selamat, akun anda sudah berhasil diaktifkan.<br/>Silakan <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> untuk melanjutkan.</div>) 
														: ( <div  className="mt-4">Congratulations, your account has been activated.<br/>Please <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> to continue.</div> ) 
													}
												</div>
											</div>
											<div id="activation-failed-message-1" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaTriangleExclamation color="#ffcc00" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Aktivasi Tidak Berhasil"  : "Activation Failed" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Link aktivasi ini tidak valid.<br/>Silakan klik tautan dari email Anda yang terdaftar.</div>) 
														: ( <div  className="mt-4">This activation link is invalid<br/>Please click the link from your registered email.</div> ) 
													}
												</div>
											</div>
											<div id="activation-failed-message-2" className="w-100" style={{display: "none"}}>
												<div  className="text-center w-100">
													<div><FaTriangleExclamation color="#ffcc00" fontSize="80px" /></div>
													<div className="mt-2 fs-3">{ GetText() ? "Aktivasi Tidak Berhasil"  : "Activation Failed" }</div>
													{	GetText() ? 
														( <div  className="mt-4">Akun anda sudah aktif.<br/>Silakan <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> untuk melanjutkan.</div>) 
														: ( <div  className="mt-4">Your account is active.<br/>Please <span className="text-primary" style={{cursor:"pointer"}} onClick={() => window.location.href = '/login'}>Login</span> to continue.</div> ) 
													}
												</div>
											</div>
										</Card.Body>
									</Card>
								)
							}
							
							{
								(this.state.tab_form === 'confirm-otp') && (
									<Card className="card-pop">
										<Card.Body className="text-start">
											<Form onSubmit={this.confirmOtp}>
												<div className="mb-3">
													<p className="text-center">{GetText() ? "Kami telah mengirimkan 6 digit kode OTP ke email Anda" : "We have sent a 6 digits OTP Code to your email"}</p>
													<label className="fw-bold form-label">{GetText() ? "Kode OTP" : "OTP Code"}</label>
													{this.state.form_error.some(item => item === 'otp') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control text-center" type="text" name="otp" value={this.state.otp} onChange={this.inputOtp} autoComplete="otp"/>
												</div>
												{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "OTP tidak ditemukan" : "Invalid OTP"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Submit" id="submit">{GetText() ? "Kirim" : "Submit"}</Button>
											</Form>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span className="text-primary">Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							<div className="mt-5">
								<a className="text-dark" href="/privacy-policy">{GetText() ? "Kebijakan Pribadi" : "Privacy Policy"}</a>
							</div>
						</div>
					</div>
				</Container>
			</div>
		);
	}
}
