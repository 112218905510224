import React, { Component } from 'react';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import { FaPlus, FaTrash, FaPen, FaFileLines, FaToggleOn, FaToggleOff } from "react-icons/fa6";
import parse from 'html-react-parser';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Popup from '../inc/PopUp';
import CTFaqForm from './ct-faq-form';
import CTFaqDetail from './ct-faq-detail';

const authKey = global.config.kindcode.training[20]+global.config.authBearerKey;


export default class CTFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      pageShow: '10',
      keyword: '',
      dataOri: {"ENG": [], "IDN": []},
      data: {"ENG": [], "IDN": []},
      defTabIndex: "",
      usageFor: '',
      selectCategory: ""
    }
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  handleSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "select_category") { 
        var resultID = this.state.dataOri.IDN;
        var resultEN = this.state.dataOri.ENG;
        if(value !== "") {
          resultID = resultID.filter((list) =>  {
            return list.content.faqCategory === value;
          });
          resultEN = resultEN.filter((list2) =>  {
            return list2.content.faqCategory === value; 
          });
        }
        
        var resFilterData = {
          "ENG": resultEN,
          "IDN": resultID
        }
        
        this.setState( prevState => {
            return {  
                ...prevState.selectCategory, selectCategory: value,
                ...prevState.data, data: resFilterData
            }
        })
    } else {
        this.setState( prevState => {
            return {  
                ...prevState.pageShow, pageShow: value
            }
        })
    }
  }
  
  urladdFaq(param,action,currpage) {
    window.location.href=global.config.urlCms+"/"+currpage+'/add/'+param;
  }
  
  urldeleteFaq(param,valId,action,currpage) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/faqdelete";
      var datatext ='{"docid":"'+valId+'","form_id":"delete-faq"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
            if(param === 'IDN') { param = "indonesia"; }
            else { param = "english"; }
            window.location.href=global.config.urlCms+"/"+currpage+'/'+param;
          } else {
              console.log('Something happened wrong delete');
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      });
    }
  }
  urlpublishFaq(valId,nextstat,param) {
    document.getElementById('loading-area').style.display = 'block';
    var datatext ='{"faq_status":"'+nextstat+'","faq_docid":"'+valId+'","form_id":"form-publish"}';
    var urlFetch = global.config.urlLink+"/faqedit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            if(param === 'IDN') { param = "indonesia"; }
            else { param = "english"; }
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/"+param;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  loadContent = (keyword,pageshow) => {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pageshow+'"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/faqlist", {
          method: 'POST',
          //mode: 'no-cors', 
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
          var resultList = resJson.values.sort((a, b) => a.content.faqNumber - b.content.faqNumber);
          var resultID = resultList.filter((list) =>  {
            return list.content.faqLanguage === "Indonesian";
          });
          var resultEN = resultList.filter((list2) =>  {
            return list2.content.faqLanguage === "English"; 
          });
          
          resolve({
            "ENG": resultEN,
            "IDN": resultID
          })
      }).catch(err => {
          resolve({"status": "500"});
      });
    });
  }

  async loadData () {
    var [result] = await Promise.all([this.loadContent(this.state.keyword,this.state.pageShow)]);
    this.setState({
            dataOri: result,
            data: result
        })
  }

  componentDidMount() {
    
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="";
    if(arrUrl.length===3 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-1];
      tabKey2 = arrUrl[arrUrl.length];
    } else if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-2];
        tabKey2 = arrUrl[arrUrl.length-1];
    }
    
    var usageFor = 'english'
    var defindex = "tab-1";
    if(tabKey2 !== undefined && tabKey2 !== "" && tabKey2.toLowerCase() === "indonesia") { defindex = "tab-2"; usageFor = "indonesia"; }

    this.setState({
      activelink: tabKey,
      defTabIndex: defindex,
      usageFor: usageFor
    });
    this.loadData();
  }
  optionCategory(param) {
    var categoryList = [];
    var categoryData = [];
    if(param === 'IDN') {
      categoryData = global.config.faqCategory.IDN;
    } else {
      categoryData = global.config.faqCategory.ENG;
    }
    if(categoryData.length > 0) {
        for(var r in categoryData) {
          categoryList.push(<option value={categoryData[r].value}>{categoryData[r].label}</option>)
        }
    }
    return categoryList;
  }
  contentTabs(param) {
    var statedata = this.state.data[param];
    var stLabel = "English Version"; if(param === 'IDN') { stLabel = "Indonesia Version"; }
    var faqList = [];
    if(statedata.length > 0) {
      for(var ls=0; ls < statedata.length; ls++) {
          var retResult = statedata[ls];
          var editURL = global.config.urlCms+"/"+this.state.activelink+"/edit/"+retResult.docid;
          var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docid;
          var iconPub = <FaToggleOff style={{color:"#999999"}} className="styleicons" onClick={this.urlpublishFaq.bind(this, retResult.docid, 1, param)}/>;
          if(retResult.content.faqStatus === 1 ) { iconPub =  <FaToggleOn className="styleicons" onClick={this.urlpublishFaq.bind(this, retResult.docid, 0, param)}/> }

          faqList.push(<tr key={retResult.docid}>
              <th scope="row-field" className="text-center">{ls+1}</th>
              <td>
                <span className="fw-bold">{retResult.content.faqQuestion}</span><br/>
                {parse(retResult.content.faqAnswer)}
              </td>
              <td>{retResult.content.faqCategory}</td>
              <td>
                  <a href={editURL}><FaPen className="styleicons" aria-label="edit" /></a>
                  <a href={viewURL}><FaFileLines className="styleicons" aria-label="view" /></a>
                  {iconPub}
                  <FaTrash className="styleicons" aria-label="delete" onClick={this.urldeleteFaq.bind(this, param, retResult.docid, 'Delete', this.state.activelink)} />
              </td>
            </tr>)
        }
    } else {
      faqList.push(<tr key="1">
            <th scope="row"></th>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
            <td></td>
          </tr>)
    }
    
    
    return(<div>
              <div className="row show">
                  <div className="col-6">
                    <select name="select_category" className="form-control"
                                value={this.state.selectCategory} 
                                onChange={this.handleSelect}
                                ref={(input) => this.textInputCategory = input}
                            >
                            <option value="">-- All Category --</option>
                            {this.optionCategory(param)}
                        </select>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                      <Button variant="secondary" size="sm" onClick={this.urladdFaq.bind(this, param.toLowerCase(),'Add',this.state.activelink)}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />Add Faq</Button>
                  </div>
              </div>
              <div className="mt-3">
                  <table className="table">
                    <thead className="table-dark">
                      <tr key="0">
                      <th width="50" className="text-center" scope="col">#</th>
                      <th scope="col">Question Answer</th>
                      <th width="150" scope="col">Category</th>
                      <th width="150" scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                        {faqList}
                    </tbody>
                  </table>
              </div>
           </div>
    )
    
  }  

  render() {
    return (
      <div className="setting">
       <Container>
      {
        (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
        (<>
          <div className="row py-5 mb-5">
            {/* === start - body */}
              <div className="col-md-3 bg-light">
                { 
                  this.state.activelink !== "none" && 
                    <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                
                }
              </div>
              <div className="col-md-9 p-4">
                <div className="row">
                  <div className="mb-4">
                    <h3>{GetText() ? "FAQ di Novaio" : "FAQ in Novaio"}</h3>
                    <span className="text-muted">{GetText() ? "Daftar FAQ di Novaio" : "List of FAQ in Novaio"}</span>
                  </div>
                  <div className="mt-3">
                    {
                    this.state.defTabIndex !== "" && 
                      <Tabs className="nav-scroll" defaultActiveKey={this.state.defTabIndex} variant="tabs" id="tab">
                        <Tab className="pt-4" eventKey="tab-1" title="English" key="1">
                          <div>
                          {this.contentTabs("ENG")}
                          </div>
                        </Tab>
                        <Tab className="pt-4" eventKey="tab-2" title="Indonesia" key="2">
                          {this.contentTabs("IDN")}
                        </Tab>

                      </Tabs>
                    }
                  </div>
                </div>
              </div>
            {/* === end - body */}
          </div>
          <div>
                {this.state.isOpen && <Popup
                  content={<><CTFaqForm/></>}
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"50%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen2 && <Popup
                  content={<><CTFaqDetail/></>}
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
          </div>
          </>
        )
      : 
        <div><CTNA/></div>
      }
      <div id="loading-area" className="d-none loading"></div> 
      </Container>
      </div>
    );
  }
}
