import React, { Component } from 'react';
// import Moment from 'moment';
import Axios from 'axios';
import { Container, Row, Col, Tab, Nav, Form, Button, Badge } from 'react-bootstrap';
import { FaFileLines, FaDownload, FaArrowRight } from 'react-icons/fa6';
import parse from 'html-react-parser';
import { GetText, MD5 } from '../config/helper';
import DateTimeFormats from '../config/date-time-format';
import NumberFormats from '../config/number-format';
import '../config/global';
import defPicProfile from '../img/pic-profile-sq.gif';
import Loading from '../inc/Loading';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class Account extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      memberEmail: localStorage.getItem('email'),
      memberPicture: defPicProfile,
      member: [],
      subscriptionList: [],
      // purchaseList: [],
      joincourseList: [],
      enrollList: [],
      certificateList: [],
      orderList: [],
      inputName: '',
      inputPhone: '',
      inputLanguage: '',
      inputPicture: null,
      inputPasswordCurrent: '',
      inputPasswordNew: '',
      inputPasswordConfirm: '',
      hasPassword: false,
      tabKey: '',
      tabPassword: 0,
      form_error: [],
      form_success: [],
      redirect: '/',
      isLoad: false
    }
    this.inputName = this.inputName.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.inputPhoto = this.inputPhoto.bind(this);
    this.inputLanguage = this.inputLanguage.bind(this);
    this.inputPasswordCurrent = this.inputPasswordCurrent.bind(this);
    this.inputPasswordNew = this.inputPasswordNew.bind(this);
    this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.setTab = this.setTab.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount() {
    var resurl = window.location.href;
    // fetch(global.config.urlLink+"/traininguserview/" + this.state.memberId, {
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': authKey
    //   }
    // })
    var dataSend = { docid: this.state.memberId }
    fetch(global.config.urlLink+"/traininguserview", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      var mFullName = content.memberName; if(mFullName === undefined) { mFullName = ""; }
      var mPhone = content.memberPhone; if(mPhone === undefined) { mPhone = ""; }
      var mLanguage = content.memberLanguage; if(mLanguage === undefined) { mLanguage = ""; }
      var mPicture = content.memberPicture; if(mPicture === undefined || mPicture === "") { mPicture = defPicProfile; }
      
      var subscriptionList = [];
      if(resultJson.values[0].scontent.length > 0) {
        subscriptionList = resultJson.values[0].scontent.sort((b, a) => a.subcontent.dateStart._seconds - b.subcontent.dateStart._seconds)
      }
      // var purchaseList = [];
      // if(resultJson.values[0].scontent2.length > 0) {
      //   purchaseList = resultJson.values[0].scontent2.sort((b, a) => a.subcontent.purchaseDate._seconds - b.subcontent.purchaseDate._seconds)
      // }
      var joincourseList = [];
      if(resultJson.values[0].scontent2.length > 0) {
        joincourseList = resultJson.values[0].scontent2.sort((b, a) => a.subcontent.createdDate._seconds - b.subcontent.createdDate._seconds)
      }
      var enrollList = [];
      if(resultJson.values[0].scontent3.length > 0) {
        enrollList = resultJson.values[0].scontent3.sort((b, a) => a.subcontent.lastVisit._seconds - b.subcontent.lastVisit._seconds)
      }
      var certificateList = [];
      if(resultJson.values[0].scontent4.length > 0) {
        certificateList = resultJson.values[0].scontent4.sort((b, a) => a.subcontent.fileDate._seconds - b.subcontent.fileDate._seconds)
      }
      var orderList = [];
      if(resultJson.values[0].scontent6.length > 0) {
        orderList = resultJson.values[0].scontent6.sort((b, a) => a.subcontent.orderDate._seconds - b.subcontent.orderDate._seconds)
      }
      
      this.setState({
        inputName: mFullName,
        inputPhone: mPhone,
        inputLanguage: mLanguage,
        inputPicture: "",
        memberPicture: mPicture,
        member : resultJson.values[0].content[0].maincontent,
        subscriptionList: subscriptionList,
        // purchaseList: purchaseList,
        joincourseList: joincourseList,
        enrollList: enrollList,
        certificateList: certificateList,
        orderList: orderList,
        hasPassword: resultJson.values[0].content[0].maincontent.userPassword === "" ? false : true,
        redirect: resurl,
        isLoad: true
      });
      
    });
  }

  inputName(event) {
    var error = [];
    if (event.target.value.length > 80) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('inputNameMax');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
		}
    this.setState({ 
      inputName: event.target.value,
      form_error: error
    });
  }

  inputPhone(event) {
    this.setState({ 
      inputPhone: event.target.value,
      form_error: []
    });
  }

  inputLanguage(event) {
    this.setState({ 
      inputLanguage: event.target.value,
      form_error: []
    });
  }

  inputPhoto(event) {
    if (event.target.files[0] !== null) {
      var value = event.target.files[0];
      if (value.type.indexOf('image') > -1) {
        this.setState({
          inputPicture: value,
          form_error: []
        }, () => {
          // document.getElementById('input-photo').innerText = this.state.inputPicture.name;
        });
      }
    }
  }
  inputPasswordCurrent(event) {
    this.setState({ 
      inputPasswordCurrent: event.target.value,
      form_error: [],
      form_success: []
    });
  }

  inputPasswordNew(event) {
    var error = [];
    if (event.target.value.length < 6) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('length');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
    }
    this.setState({ 
      inputPasswordNew: event.target.value,
      form_error: error,
      form_success: []
    });
  }

  inputPasswordConfirm(event) {
    var error = [];
    if (this.state.inputPasswordNew !== event.target.value) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('notsame');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
    }
    this.setState({ 
      inputPasswordConfirm: event.target.value,
      form_error: error,
      form_success: []
    });
  }

  updatePassword(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (this.state.hasPassword === true) {
      if (this.state.inputPasswordCurrent === '') {
        error.push('inputPasswordCurrent');
      }
    }
    if (this.state.inputPasswordNew === '') {
      error.push('inputPasswordNew');
    } else if (this.state.inputPasswordNew.length < 6) {
      error.push('length');
    } else if (this.state.inputPasswordNew === this.state.inputPasswordCurrent) {
      error.push('same');
    }
    if (this.state.inputPasswordConfirm === '') {
      error.push('inputPasswordConfirm');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      if(GetText() === true) {
        document.getElementById('submit').innerText = "Simpan";
      } else {
        document.getElementById('submit').innerText = "Update";

      }
      // document.getElementById('submit').innerText = "Update";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
        var dataText = '{"user_email":"' + this.state.memberEmail + '","user_id":"' + this.state.memberId + '","user_password_new":"' + MD5(this.state.inputPasswordNew) + '"';
        if (this.state.hasPassword === true) {
          dataText += ',"user_password_current":"' + MD5(this.state.inputPasswordCurrent) + '"';
        } else {
          dataText += ',"user_password_current":""';
        }
        dataText += ',"form_id": "update-password"}';

        // if (this.state.hasPassword === true) {
        //   dataText = '{"user_email":"' + this.state.memberEmail + '","user_id":"' + this.state.memberId + '","user_password_current":"' + MD5(this.state.inputPasswordCurrent) + '","user_password_new":"' + MD5(this.state.inputPasswordNew) + '"}';
        // } else {
        //   dataText = '{"user_email":"' + this.state.memberEmail + '","user_id":"' + this.state.memberId + '","user_password_current":"","user_password_new":"' + MD5(this.state.inputPasswordNew) + '"}';
        // }
        var dataSend = JSON.parse(dataText);

        fetch(global.config.urlLink+"/passwordedit", {
          method: "POST",
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'Access-Control-Allow-Origin': "*",
            'Authorization': authKey
          },
          body: JSON.stringify(dataSend),
        })
        .then((result) => {
          if (result.status === 200) {
            return result.json();
          } else {
            console.log("Something happened wrong.");
          }
        })
        .then((resultJson) => {
          document.getElementById('submit').innerText = 'Update';
          document.getElementById('submit').removeAttribute('disabled');
          var success = [];
          var error = [];
          if (resultJson.values[0].error === false) {
            success.push('updated');
            this.setState({
              inputPasswordCurrent: '',
              inputPasswordNew: '',
              inputPasswordConfirm: '',
              hasPassword: true,
              form_success: success
            });
          } else {
            error.push('invalid');
            this.setState({
              form_error: error
            });
          }
        })
        .catch((err) => {
          alert(err);
          // window.location.href = "/" + this.state.redirect;
          window.location.href = this.state.redirect;
        });
    }
  }

  updateToProfileDB(photos) {
    var dataText = '{';
    dataText += '"memberdoc_id":"'+ this.state.memberId +'", "member_name":"'+this.state.inputName+'","member_phone":"'+this.state.inputPhone+'"';
    dataText += ',"member_picture": "'+photos+'"'; 
    dataText += ',"member_language":"'+this.state.inputLanguage+'","form_id": "update-profile"';
    dataText += '}';
    
    var dataSend = JSON.parse(dataText);
    
    fetch(global.config.urlLink+"/trainingupdateprofile", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    }).then(resultJson => {
      localStorage.setItem('language', this.state.inputLanguage);
      window.location.reload();
    });

  }

  updateProfile(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.inputName)) {
      error.push('inputName');
    }
    // if (!(this.state.inputPhone)) {
    //   error.push('inputPhone');
    // }
    if (!(this.state.inputLanguage)) {
      error.push('inputLanguage');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Update';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
      
      if(this.state.inputPicture !== "") {
        const data = new FormData();
        data.append('user_id', this.state.memberId);
        data.append('file', this.state.inputPicture);
        data.append('source', 'profile');
        Axios.post(global.config.urlLink+"/uploadprofilephoto", 
          data, { 
            headers: { 
              'content-type': 'multipart/form-data',
              'Authorization': authKey
            }
          }
        )
        .then(result => {
          var photo = result.data.status === '400' ? this.state.member.memberPicture : result.data.values;
          this.updateToProfileDB(photo);

        });
      } else {
        var photo = "";
        if(this.state.inputPicture !== undefined && this.state.inputPicture !== "") { photo = this.state.inputPicture; }
        this.updateToProfileDB(photo);
      }

    }
    
    
    
  }

  setTab() {
    var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);

    return tabKey ? tabKey : 'account';
  }
  togglePassword() {
    if (this.state.tabPassword === 0) {
      this.setState({
        tabPassword: 1,
      });
    } else {
      this.setState({
        tabPassword: 0,
      });
    }
  }
  render() {    
    return (
      <div className="profile">
        {this.state.isLoad === true? 
        <Container>
          <Tab.Container defaultActiveKey={this.setTab}>
            <Row className="py-5 mb-5">
              <Col sm={3}>
                  <div className="text-center">
                    <img className="wh-180 mb-4" src={this.state.memberPicture} alt="Avatar"/>
                    <h5><strong>{this.state.member.memberName}</strong></h5>
                    <h6 className="mb-5 text-secondary">{this.state.member.memberEmail}</h6>
                  </div>
                  <Nav variant="pills" className="flex-column pills-scroll mt-5 mb-5 mb-md-0">
                    <Nav.Item><Nav.Link eventKey="account">{GetText() ? "Akun" : "Account"}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="subscription">{GetText() ? "Berlangganan" : "Subscription"}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="mylibraries">{GetText() ? "Library" : "My Library"}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="mycourses">{GetText() ? "Workshop" : "My Workshops"}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="mycertificates">{GetText() ? "Sertifikat" : "My Certificate"}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link eventKey="myorder">{GetText() ? "Pemesanan" : "My Order"}</Nav.Link></Nav.Item>
                  </Nav>
              </Col>
              <Col sm={1}></Col>
              <Col sm={8} className="p-4 bg-light">
                <div >
                  <Tab.Content>
                    <Tab.Pane eventKey="account">
                      <div className="row">
                        <div className="col-6">
                          <h3 className="mb-4">{GetText() ? "Akun" : "Account"}</h3>
                        </div>
                        <div className="col-6 text-end">
                          {
                            this.state.tabPassword === 0 ? (
                              <div className="link-more" onClick={this.togglePassword}>{GetText() ? "Ubah Password" : "Change Password"} <FaArrowRight/></div>
                            ) : (
                              <div className="link-more" onClick={this.togglePassword}>{GetText() ? "Pengaturan Akun" : "Account Settings"} <FaArrowRight/></div>
                            )
                          }
                        </div>
                      </div>
                      {
                        this.state.tabPassword === 0 ? (
                          <Form onSubmit={this.updateProfile}>
                          <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Nama Lengkap" : "Full Name"}</label>
                                  {this.state.form_error.some(item => item === 'inputName') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  {this.state.form_error.some(item => item === 'inputNameMax') ? <span className="error-message">{GetText() ? 'Maksimal 80 karakter!' : 'Max 80 characters!'}</span> : ''}
                                  <input className="form-control" type="text" name="profile_name" value={this.state.inputName} placeholder="Enter your name" onChange={this.inputName}/>
                                </div>
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "No. Telepon" : "Phone"}</label>
                                  {this.state.form_error.some(item => item === 'inputPhone') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  <input className="form-control" type="text" name="profile_phone" value={this.state.inputPhone} placeholder="Enter your phone" onChange={this.inputPhone}/>
                                </div>
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Foto" : "Photo"}</label>
                                  <div className="custom-file">
                                      <input type="file" className="form-control" name="profile_photo" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={this.inputPhoto}/>
                                      {/* <label className="custom-file-label" id="input-photo" htmlFor="photo">{GetText() ? "Pilih file" : "Choose file"}</label>  */}
                                  </div>
                                  {/* <input className="form-control" type="file"  name="profile_photo" aria-describedby="inputGroupFileAddon04" aria-label="Upload" />  */}
                                </div>
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Bahasa" : "Language"}</label>
                                  {this.state.form_error.some(item => item === 'inputLanguage') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="profile_language" value="Indonesian" onChange={this.inputLanguage} id="option-1" checked={this.state.inputLanguage === 'Indonesian'}/>
                                        <label className="form-check-label option-label" htmlFor="option-1">Bahasa Indonesia</label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="profile_language" value="English" onChange={this.inputLanguage} id="option-2" checked={this.state.inputLanguage === 'English'}/>
                                        <label className="form-check-label option-label" htmlFor="option-2">English</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <Button className="mt-4" variant="primary" size="lg" type="submit" value="Save" id="submit">{GetText() ? "Simpan" : "Update"}</Button>
                              </div>
                            </div>
                        </Form>
                        ) : (
                          <Form onSubmit={this.updatePassword}>
                            <div className="row">
                              <div className="col-md-8">
                              { 
                                this.state.hasPassword === true ? ( 
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Password Sekarang" : "Current Password"}</label>
                                  {this.state.form_error.some(item => item === 'inputPasswordCurrent') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  <input className="form-control" type="password" name="password_current" value={this.state.inputPasswordCurrent} onChange={this.inputPasswordCurrent} autoComplete='false'/>
                                </div>
                                ) : ''
                              }
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Password Baru" : "New Password"}</label>
                                  {this.state.form_error.some(item => item === 'inputPasswordNew') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  {this.state.form_error.some(item => item === 'length') ? <span className="error-message">{GetText() ? 'Minimal 6 karakter!' : 'Min 6 characters!'}</span> : ''}
                                  {this.state.form_error.some(item => item === 'same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
                                  <input className="form-control" type="password" name="password_new" value={this.state.inputPasswordNew} onChange={this.inputPasswordNew} autoComplete='false' />
                                </div>
                                <div className="mb-3">
                                  <label className="fw-bold form-label">{GetText() ? "Konfirmasi Password Baru" : "Confirm New Password"}</label>
                                  {this.state.form_error.some(item => item === 'inputPasswordConfirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                  {this.state.form_error.some(item => item === 'notsame') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Password doesn\'t match!'}</span> : ''}
                                  <input className="form-control" type="password" name="password_confirm" value={this.state.inputPasswordConfirm} onChange={this.inputPasswordConfirm} autoComplete='false' />
                                </div>
                                {this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none">{GetText() ? "Username atau password tidak ditemukan" : "Invalid username or password"}</p> : ''}
                                {this.state.form_success.some(item => item === 'updated') ? <p className="success-message d-block mb-2 float-none">{GetText() ? "Password berhasil diubah" : "Password has been updated"}</p> : ''}
                                <Button className="mt-4" variant="primary" size="lg" type="submit" value="Save" id="submit">{GetText() ? "Simpan" : "Update"}</Button>
                              </div>
                            </div>
                          </Form>
                        )
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="subscription">
                      <h3 className="mb-4">{GetText() ? "Berlangganan" : "Subscription"}</h3>
                      <div className="bg-secondary p-2 br-8 mb-4">
                        {
                          this.state.member.memberPlan === 'Member' ? (
                            <div>
                              <p className="text-white text-center mt-2">{GetText() ? "Anda berlangganan hingga" : "You are subscribed until" }</p><h6 className="display-5 text-white text-center"> { DateTimeFormats(this.state.member.expiredSubscribeMember._seconds,"date-long")}</h6>
                            </div>
                          ) : ( 
                            <div className="text-white text-center mt-2 mb-2">
                              <p className="mt-4">{GetText() ? "Belum berlangganan" : "No active subscription"}</p>
                              <a className="btn btn-primary btn-lg" href="/subscribe">{GetText() ? "Berlangganan sekarang" : "Subscribe Now!"}</a>
                            </div>
                          )
                        }
                      </div>
                      <div className="table-wrapper">
                        {
                          this.state.subscriptionList.length > 0 ? (
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th width="80" className="text-center">No.</th>
                                  <th>{GetText() ? "Tipe" : "Type"}</th>
                                  <th width="150">{GetText() ? "Start Period" : "Tanggal Mulai"}</th>
                                  <th width="150">{GetText() ? "End Period" : "Tanggal Berakhir"}</th>
                                  <th width="120">{GetText() ? "Jumlah" : "Amount"}</th>
                                  <th width="80">Detail</th>
                                </tr>
                              </thead>
                              <tbody>
                                { 
                                  this.state.subscriptionList
                                  .map((Subscription_Item, i) => {
                                    var orderid = "", subscribezId = "", plan_idx=""; 
                                    if(Subscription_Item.subcontent.orderId !== undefined && Subscription_Item.subcontent.orderId !== null) { orderid = Subscription_Item.subcontent.orderId; }
                                    if(Subscription_Item.subdocid !== undefined && Subscription_Item.subdocid !== null) { subscribezId = Subscription_Item.subdocid+orderid.substring(2,orderid.length); }
                                    if(Subscription_Item.subcontent.subscribePlan = global.config.subscribePlan[0]) { plan_idx = 0; } 
                                    else { plan_idx = 1; }
                                    return (
                                      <tr key={"subscription-list-" + i}>
                                        <td className="text-center">{(i + 1)}.</td>
                                        <td>
                                          {Subscription_Item.subcontent.orderPeriod} {GetText() ? global.config.subscribePlan[plan_idx].name[0] : global.config.subscribePlan[plan_idx].name[1]}
                                        </td>
                                        <td>{Subscription_Item.subcontent.dateStart? DateTimeFormats(Subscription_Item.subcontent.dateStart._seconds,"date-short") : ''}</td>
                                        <td>{Subscription_Item.subcontent.dateEnd? DateTimeFormats(Subscription_Item.subcontent.dateEnd._seconds,"date-short") : ''}</td>
                                        <td>
                                          {(Subscription_Item.subcontent.subscribeCurrency ? (Subscription_Item.subcontent.subscribeCurrency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]) : global.config.currency.symbol[1]) + NumberFormats(Subscription_Item.subcontent.subscribePrice)}
                                        </td>
                                        <td >
                                          <a className="text-secondary" href={"/orders/" + orderid}><FaFileLines className="mx-2" style={{height:"30px", cursor:"Pointer"}} /></a>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          ) : (<></>)
                        }
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mycourses">
                      <h3 className="mb-4">{GetText() ? "Workshop" : "My Workshops"}</h3>
                      <div className="table-wrapper">
                        {
                          this.state.joincourseList.length > 0 ? (
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th width="80" className="text-center">No.</th>
                                  <th>Workshop</th>
                                  <th width="120">{GetText() ? "Tanggal Pendaftaran" : "Registration Date"}</th>
                                  <th width="120">{GetText() ? "ID Tiket" : "Ticket ID"}</th>
                                  <th width="120">{GetText() ? "Kehadiran" : "Check In"}</th>
                                  <th width="80">Detail</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.joincourseList.map((JoinCourse_Item, i) => {
                                    return (
                                      <tr key={"joincourse-list-" + i}>
                                        <td className="text-center">{(i + 1)}.</td>
                                        <td>
                                          <a className="text-dark"href={'/course/' + JoinCourse_Item.subcontent.courseId}>{JoinCourse_Item.subcontent.courseTitle}</a>
                                          {JoinCourse_Item.subcontent.courseDate && <span><br/>Event Date :  {DateTimeFormats(JoinCourse_Item.subcontent.courseDate._seconds,"date-short")}</span> }
                                          {JoinCourse_Item.subcontent.courseStartTime && JoinCourse_Item.subcontent.courseEndTime? 
                                            <span className="ms-1"> ( {DateTimeFormats(JoinCourse_Item.subcontent.courseStartTime._seconds,"time-24")} - {DateTimeFormats(JoinCourse_Item.subcontent.courseEndTime._seconds,"time-24")} )</span> 
                                            : <></>
                                          }
                                        </td>
                                        <td>
                                          {DateTimeFormats(JoinCourse_Item.subcontent.createdDate._seconds,"datetime-short")}
                                        </td>
                                        <td>
                                          {JoinCourse_Item.subcontent.ticketId}
                                        </td>
                                        <td>
                                          {JoinCourse_Item.subcontent.checkInDate? DateTimeFormats(JoinCourse_Item.subcontent.checkInDate._seconds,"datetime-short") : ''}
                                        </td>
                                        <td>
                                          <a className="text-secondary" href={'/course/' + JoinCourse_Item.subcontent.courseId}><FaFileLines className="mx-2" style={{height:"30px", cursor:"Pointer"}}/></a>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          ) : ( 
                            <div>
                              <p className="mt-4">{GetText() ? "Belum ikut workshop" : "No workshops"}</p>
                              <a className="btn btn-primary btn-lg" href="/courses">{GetText() ? "Ikut Workshop" : "Browse Workshops"}</a>
                            </div>
                          )
                        }
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mylibraries">
                      <h3 className="mb-4">{GetText() ? "Library" : "My Library"}</h3>
                      <div className="table-wrapper">
                        {
                          this.state.enrollList.length > 0 ? (
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th width="80" className="text-center">No.</th>
                                  <th>Library</th>
                                  <th width="180">{GetText() ? "Terakhir Dilihat" : "Last Visited"}</th>
                                  <th width="120">{GetText() ? "Modul Terakhir" : "Last Module"}</th>
                                  <th width="80">{GetText() ? "Perkembangan" : "Progress"}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.enrollList.map((Enroll_Item, i) => {
                                    return (
                                      <tr key={"enroll-list-" + i}>
                                        <td className="text-center">{(i + 1)}.</td>
                                        <td><a className="text-dark" href={'/library/' + Enroll_Item.subcontent.libraryId}>{Enroll_Item.subcontent.libraryTitle}</a></td>
                                        <td>{DateTimeFormats(Enroll_Item.subcontent.lastVisit._seconds,"date-long")}</td>
                                        <td>{Enroll_Item.subcontent.lastLesson > 0 ? (GetText() ?  'Modul ' : 'Module ') + Enroll_Item.subcontent.lastLesson : (GetText() ?  'Pengantar ' : 'Introduction')}</td>
                                        <td className="mx-2">{Enroll_Item.subcontent.completion + '%'}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          ) : ( 
                            <div>
                              <p className="mt-4">{GetText() ? "Belum ada library" : "No library."}</p>
                              <a className="btn btn-primary btn-lg" href="/libraries">{GetText() ? "Lihat library" : "Browse Library"}</a>
                            </div>
                          )
                        }
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="mycertificates">
                      <h3 className="mb-4">{GetText() ? "Sertifikat" : "My Certificate"}</h3>
                      <div className="table-wrapper">
                        {
                          this.state.certificateList.length > 0 ? (
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th width="80" className="text-center">No.</th>
                                  <th>{GetText() ? "Judul" : "Title"}</th>
                                  <th width="180">{GetText() ? "Dibuat" : "Created"}</th>
                                  <th width="80">{GetText() ? "Skor" : "Score"}</th>
                                  <th width="80">{GetText() ? "Unduh" : "Download"}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.certificateList.map((Certificate_Item, i) => {
                                    return (
                                      <tr key={"enroll-list-" + i}>
                                        <td className="text-center">{(i + 1)}.</td>
                                        <td>
                                          <a className="text-dark" href={(Certificate_Item.subcontent.courseType === 'library' ? '/library/' : '/course/') + Certificate_Item.subcontent.courseId}>{Certificate_Item.subcontent.courseTitle}</a>
                                          <br/>
                                          <small className="text-capitalize">{Certificate_Item.subcontent.courseType}</small>
                                        </td>
                                        <td>{DateTimeFormats(Certificate_Item.subcontent.fileDate._seconds,"date-long")}</td>
                                        <td className="text-center">{Certificate_Item.subcontent.score}</td>
                                        <td>
                                          <a className="text-secondary" href={Certificate_Item.subcontent.fileUrl} target="_blank" rel="noreferrer" download><FaDownload className="mx-2" style={{height:"30px", cursor:"Pointer"}}/></a>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          ) : ( 
                            <div>
                              <p className="mt-4">{GetText() ? "Belum ada sertifikat" : "No certificate"}</p>
                              <a className="btn btn-primary btn-lg" href="/libraries">{GetText() ? "Lihat Library" : "Browse Library"}</a>
                            </div>
                          )
                        }
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="myorder">
                      <h3 className="mb-4">{GetText() ? "Pemesanan" : "My Order"}</h3>
                      <div className="table-wrapper">
                        {
                          this.state.orderList.length > 0 ? (
                            <table className="table">
                              <thead className="table-dark">
                                <tr>
                                  <th width="80" className="text-center">No.</th>
                                  <th width="120">{GetText() ? "Tanggal Pemesanan" : "Order Date"}</th>
                                  <th>{GetText() ? "Kode Pesanan" : "Order Code"}</th>
                                  <th width="150">{GetText() ? "Total Pemesanan" : "Total Order"}</th>
                                  <th width="200">{GetText() ? "Cara Pembayaran" : "Payment Method"}</th>
                                  <th width="150">{GetText() ? "Status" : "Status"}</th>
                                  <th width="80">Detail</th>
                                </tr>
                              </thead>
                              <tbody>
                                { 
                                  this.state.orderList
                                  .map((Order_Item, i) => {
                                    var order2Id = "", subscribezId = ""; 
                                    if(Order_Item.subcontent.orderId !== undefined && Order_Item.subcontent.orderId !== null) { order2Id = Order_Item.subcontent.orderId; }
                                    if(Order_Item.subdocid !== undefined && Order_Item.subdocid !== null) { subscribezId = Order_Item.subdocid+order2Id.substring(2,order2Id.length); }
                                    return (
                                      <tr key={"order-list-" + i}>
                                        <td className="text-center">{(i + 1)}.</td>
                                        <td>
                                        {(DateTimeFormats(Order_Item.subcontent.orderDate._seconds,"datetime-short-hhmmap"))}
                                        </td>
                                        <td>
                                          {Order_Item.subcontent.orderId}
                                        </td>
                                        
                                        
                                        <td>
                                          {(Order_Item.subcontent.paymentCurrency ? (Order_Item.subcontent.paymentCurrency === global.config.currency.name[0] ? global.config.currency.symbol[0] : global.config.currency.symbol[1]) : global.config.currency.symbol[1]) + NumberFormats(Order_Item.subcontent.orderGrandTotal)}
                                          <br/>
                                          {
                                            // Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[0] ? (
                                            //   Subscription_Item.subcontent.paymentConfirmationDate ? (
                                            //     <Badge variant="info">{GetText() ? "Memverifikasi" : "Verifying"}</Badge>
                                            //   ) : (
                                            //     <Badge variant="warning">{GetText() ? "Belum Dibayar" : "Unpaid"}</Badge>
                                            //   )
                                            // ) : Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[1] ? (
                                            //   <Badge variant="success">{GetText() ? "Lunas" : "Paid"}</Badge>
                                            // ) : Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[3] ? (
                                            //     <Badge variant="secondary">{GetText() ? "Dibatalkan" : "Cancel"}</Badge>
                                            // ) : (
                                            //   <Badge variant="secondary">{GetText() ? "Expired" : "Expired"}</Badge>
                                            // )
                                          }
                                        </td>
                                        <td>
                                          {Order_Item.subcontent.paymentMethod}
                                          <br/>
                                          {(localStorage.getItem("ulvl") === global.config.userLevel[0]) && <small>{parse(Order_Item.subcontent.paymentInfo)}</small>  }
                                          {
                                            Order_Item.subcontent.paymentMethod === 'Virtual Account' ? (
                                            <>
                                              <br/><small>{Order_Item.subcontent.paymentInfo.replace('<br/>', ' ')}</small>
                                            </>
                                            ) : <></>
                                          }
                                          
                                        </td>
                                        <td>
                                          {/* {Order_Item.subcontent.orderStatus} */}
                                          {
                                            Order_Item.subcontent.orderStatus === global.config.orderStatus[0] ? (
                                              <Badge pill bg="warning" text="dark">{GetText() ? "Belum Dibayar" : "Unpaid"}</Badge>
                                            ) : Order_Item.subcontent.orderStatus === global.config.orderStatus[1] ? (
                                              <Badge pill bg="info">{GetText() ? "Memverifikasi" : "Verifying"}</Badge>
                                            ) : Order_Item.subcontent.orderStatus === global.config.orderStatus[2] ? (
                                              <Badge pill bg="success">{GetText() ? "Lunas" : "Paid"}</Badge>
                                            ) : Order_Item.subcontent.orderStatus === global.config.orderStatus[4] ? (
                                              <Badge pill bg="danger">{GetText() ? "Expired" : "Expired"}</Badge>
                                            ) : (
                                              <Badge pill bg="secondary">{GetText() ? "Dibatalkan" : "Cancel"}</Badge>
                                            )
                                          }
                                        </td>
                                        <td >
                                          <a className="text-secondary" href={"/orders/" + order2Id}><FaFileLines className="mx-2" style={{height:"30px", cursor:"Pointer"}} /></a>
                                        </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          ) : ( 
                            <div>
                              <p className="mt-4">{GetText() ? "Belum ada pemesanan" : "No order"}</p>
                            </div>
                          )
                        }
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      : <Loading value={{id:'account-area', type: 'screen'}}/> 
      }
      </div>
      
    );
  }
}