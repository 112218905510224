import React, { Component } from "react";
import Moment from 'moment';
import Axios from 'axios';
import JsPdf from "jspdf";
import Html2Canvas from "html2canvas";
import Parser from "html-react-parser";
import ReactPlayer from "react-player";
import parse from 'html-react-parser';
import { GetText, ReplaceText, ShuffleArray } from '../config/helper';
import { Container, Tab, Nav, Modal, Form, Button } from "react-bootstrap";
import { FaRegFolder, FaCheck, FaArrowRight } from 'react-icons/fa6';
import '../config/global';
import ImgPlayButton from '../img/play-button.png';
import ImgPlayActive from '../img/play-active.png';
import Certificate from "../Certificate";
import Loading from '../inc/Loading';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[0]+global.config.authBearerKey;
const authKey3 = global.config.kindcode.training[8]+global.config.authBearerKey;
const authKey5 = global.config.kindcode.training[5]+global.config.authBearerKey;
const authKey6 = global.config.kindcode.training[9]+global.config.authBearerKey; // certificate

function getUserOrder(libraryId,lenLesson,lenExam,library) {
	return new Promise((resolve) => {
		var dataSend = { 
			docid: localStorage.getItem('doc_id'), 
			types: "enrolls", 
			libid: libraryId, 
			libtitle: library.title, 
			libcategory: library.category,
			libpicture: library.pictures
		}
		fetch(global.config.urlLink+"/traininguserview", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey2
			}, 
			body : JSON.stringify(dataSend)
		}).then(response => {
			if(response.status === 200) {
				return response.json()
			} else {
				console.log('Something happened wrong');
			}
		}).then(resultJson => {
			var member_info = resultJson.values[0].content[0].maincontent;
			var content = resultJson.values[0].scontent3;
			var certificate = "";
			var last = false;
			if (resultJson.values[0].scontent4.length > 0) {
				certificate = resultJson.values[0].scontent4[0].subcontent.fileUrl;
			}
			if (content.length > 0) {
				var inum=0;
				content.forEach((enroll) => {
					var subcontent = enroll.subcontent;
					inum++;
					if (subcontent.libraryId === libraryId) {
						last = true;
						var goToTab = 0;
						if (subcontent.lastLesson >= (lenLesson + lenExam)) {
							goToTab = subcontent.lastLesson;
						}	else {
							goToTab = subcontent.lastLesson + 1;
						}
						var enrollSend = {};
						enrollSend.memberInfo = member_info;
						enrollSend.enrollcontent = subcontent;
						enrollSend.enrollcontent.subdocid = enroll.subdocid;
						enrollSend.goToTab = goToTab;
						enrollSend.last = last;
						enrollSend.certificate = certificate;
						resolve(enrollSend);
						
					} else {
						if(inum === content.length) {
							var enrollSend2 = {};
							enrollSend2.memberInfo = member_info;
							enrollSend2.enrollcontent = {};
							enrollSend2.enrollcontent.subdocid = "";
							enrollSend2.goToTab = 1;
							enrollSend2.last = last;
							enrollSend2.certificate = certificate;
							resolve(enrollSend2);
						}
					}
				});
			} else {
				var enrollSend3 = {};
				enrollSend3.memberInfo = member_info;
				enrollSend3.enrollcontent = {};
				enrollSend3.enrollcontent.subdocid = "";
				enrollSend3.goToTab = 1;
				enrollSend3.last = last;
				enrollSend3.certificate = certificate;
				resolve(enrollSend3);
			}
		});

	});
}

async function getLessonsAttach(attachments,fg) {
	var subcontents2 = [];
	if (attachments.length > 0) {
		subcontents2 = await Promise.all(
			attachments.map(async (attachment) => {
				return attachment.subsubcontent;
			})
		)
	} 
	return subcontents2;
}
async function getLessons(lessons) {
	return new Promise((resolve) => {
		var isIntroduction = 0;
		var arrContents = [];
		if (lessons.length > 0) {
			var i=0;
			lessons.forEach(async lesson => {
				var subcontent = lesson.subcontent;
				subcontent.id = lesson.subdocid;
				subcontent.attachment = await getLessonsAttach(lesson.sub2content,"");
				if (subcontent.lessonNumber === '0') {
					isIntroduction = 1;
				}
				arrContents.push(subcontent);
				i++;
				if(i===lessons.length) {
					var lessonSend = {};
					lessonSend.lessoncontent = arrContents;
					lessonSend.isIntroduction = isIntroduction;
					resolve(lessonSend);
				}
			});                
		} else {
			var lessonSend = {};
			lessonSend.lessoncontent = arrContents;
			lessonSend.isIntroduction = isIntroduction;
			resolve(lessonSend);
		}
	});
}

function getExam(exams) {
	return new Promise((resolve) => {
		var examList = exams.filter((sel) =>  {
			return sel.subcontent.examStatus > 0
		  });
		var contents = [];
		if (examList.length > 0) {
			var a=0;
			examList.forEach(exam => {
			  	if (exam.subcontent.examStatus > 0) {
					var content = exam.subcontent;
					content.id = exam.subdocid;
					content.question = [];
					
					if (exam.sub2content.length > 0) {
						var questions = ShuffleArray(exam.sub2content);
						var contents2 = [];
						var b=0;
						questions.forEach(question => {
							if (b < content.numberQuestion) {
								contents2.push(question.subsubcontent);
							}
							b++;
							if(b === questions.length) { content.question = contents2; }
						});
						
					}
					contents.push(content);
			  	}
				a++;
				if(a===examList.length) {
					resolve(contents);
				}
			  
			});                
		} else { resolve(contents); }
	});
}
function getFeedback(feedbacks,memberid) {
	return new Promise((resolve) => {
		var sub3contents = [];
		if (feedbacks.length > 0) {
			var c=0;
			feedbacks.forEach(feedback => {
				if (feedback.subcontent.memberId === memberid) {
					var sub3content = feedback.subcontent;
					sub3content.id = feedback.subdocid;
					sub3contents.push(sub3content);
				}
				c++;
				if(c===feedbacks.length) { 
					if(sub3contents.length > 0) {
						resolve(sub3contents[sub3contents.length-1]);
					} else {
						resolve(sub3contents);
					}
				}
			  
			});                
		} else { resolve(sub3contents); }
	});
}
function getFeedbackList() {
	return new Promise((resolve) => {
		var dataText ='{"keyword":"","pageShow":"10"}';
    	var dataSend = JSON.parse(dataText);
		fetch(global.config.urlLink+"/feedbacklist/", {
		  method: 'POST',
		  headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey5
		  }, 
      	  body : JSON.stringify(dataSend)
		}).then(response => {
			if (response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong');
			}
		}).then(resultJson => {
			var resultData = [];
			if(resultJson.values.length > 0) {
				for (var i=0; i<resultJson.values.length; i++) {
					var content = resultJson.values[i].content;
					content.id = resultJson.values[i].docid;
					resultData.push(content);
					if(i===(resultJson.values.length-1)) {
						resolve(resultData);
					}
				}
			} else { resolve(resultData); }
      	});
	});
}
async function getAllDatas(id,lessons,exams,feedbacks,memberid,library) {
	
	  var userorderresult = getUserOrder(id,lessons.length,exams.length,library);
	  var lessonresult = getLessons(lessons);
	  var examresult = getExam(exams);
	  var feedbackresult = getFeedback(feedbacks,memberid);
	  var feedbacklistresult = getFeedbackList();
	  const [thing1,thing2,thing3,thing4,thing5] = await Promise.all([userorderresult,lessonresult,examresult,feedbackresult,feedbacklistresult]);
	  var arrReturnValue = {};
	  arrReturnValue.enrolldata = thing1;
	  arrReturnValue.lessondata = thing2;
	  arrReturnValue.examdata = thing3;
	  arrReturnValue.feedbackdata = thing4;
	  arrReturnValue.feedbacklistdata = thing5;
	  
	  return arrReturnValue;
}


export default class LibraryDetailMember extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member_id: localStorage.getItem('doc_id'),
			member_name: "",
			member_company: "",
			certificate_bg: "",
			id: "",
			title: "",
			category: "",
			excerpt: "",
			description: "",
			image: "",
			video: "",
			lesson: [],
			exam: [],
			feedback: [],
			feedbackList: [],
			libraryList: [],
			videoList: [],
			enroll_id: '',
			last_visit: Moment().format('D MMMM Y'),
			last_lesson: 0,
			completion: 0,
			open_exam: 0,
			open_feedback: false,
			open_pdf: false,
			// open_certificate: false,
			url_pdf: 0,
			correct: 0,
			incorrect: 0,
			score: 0,
			status: '',
			certificate: "",
			tabKey: '',
			currentTabKey: '',
			selectTabKey: '',
			playing: {},
			is_introduction: 0,
			random: 0,
			form_error_answer: [],
			form_error_feedbacks: [],
			isLoad : false
		}
		// this.addEnroll = this.addEnroll.bind(this);
		this.editEnroll = this.editEnroll.bind(this);
		this.openLesson = this.openLesson.bind(this);
		this.openExam = this.openExam.bind(this);
		this.closeExam = this.closeExam.bind(this);
		this.openFeedback = this.openFeedback.bind(this);
		this.closeFeedback = this.closeFeedback.bind(this);
		this.submitFeedback = this.submitFeedback.bind(this);
		this.sendFeedback = this.sendFeedback.bind(this);
		this.getResult = this.getResult.bind(this);
		this.openVideo = this.openVideo.bind(this);
		this.openAttachment = this.openAttachment.bind(this);
		this.openPdf = this.openPdf.bind(this);
		this.closePdf = this.closePdf.bind(this);
		this.videoProgress = this.videoProgress.bind(this);
		this.nextDisabled = this.nextDisabled.bind(this);
		this.addCertificate = this.addCertificate.bind(this);
		this.uploadCertificate = this.uploadCertificate.bind(this);
		this.selectTab = this.selectTab.bind(this);
		this.selectVideo = this.selectVideo.bind(this);
		this.scrollToTop = React.createRef();
		this.scrollToRef = React.createRef();
	}
	
	editEnroll(enrollId,lastLesson,completion,score,statusExam) {
		return new Promise((resolve) => {
			var dataSend = {
				trmember_id: this.state.member_id,
				enrolldoc_id: enrollId,
				last_lesson: lastLesson,
				completion: completion,
				exam_score: score,
				exam_status: statusExam
			};
			fetch(global.config.urlLink+"/trainingeditenroll", {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Authorization': authKey3
				}, 
				body : JSON.stringify(dataSend)
			})
			.then(response => {
				if(response.status === 200) {
					return response.json();
				} else {
					console.log('Something happened wrong.');
				}
			})
			.then(resultJson => {
				resolve(resultJson.values);
			});
		})
		
	}
	addCertificate = (libraryid,memberid,enrollid,certificateurl,librarytitle,score) => {
		return new Promise((resolve, reject) => {
		  var dataSend = {
				trmember_id: memberid,
				course_id: libraryid,
				course_title: librarytitle,
				course_type: 'library',
				score: score,
				file_url: certificateurl,
				form_id: 'form-addcertificate',
				historycourse_id: enrollid
		  };
		 
		  fetch(global.config.urlLink+"/trainingaddcertificate", {
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json',
			  'Access-Control-Allow-Origin': '*',
			  'Authorization': authKey6
			}, 
			body : JSON.stringify(dataSend)
		  }).then(response => {
			if (response.status === 200) {
			  return response.json();
			} else {
			  console.log('Something happened wrong.');
			}
		  }).then(resultJson2 => {
			if (resultJson2.status === "200") {
			  resolve(resultJson2.values);
			} 
		  });
		});
	}
	uploadCertificate() {
		return new Promise((resolve, reject) => {
			const certificate = document.getElementById('certificate');
			certificate.style.display = "block";
			Html2Canvas(certificate, {logging: true, letterRendering: 1, useCORS: true}).then(canvas => {
				const data = canvas.toDataURL('img/png');
				certificate.style.display = "none";
				const pdf = new JsPdf({
					orientation: "landscape",
					unit: "px",
					hotfixes: "px_scaling",
					format: [900, 506]
				});
				pdf.addImage(data, 'PNG', 0, 0, 900, 506);			
				var blob = pdf.output('blob');
				var name = this.state.member_id + this.state.id + '.pdf';
				const form = new FormData();
				form.append('file', blob, name);
				form.append('source', 'certificate');
				Axios.post(global.config.urlLink+ "/uploadcertificate",
					form, { 
						headers: { 
							'content-type': 'multipart/form-data',
							'Authorization': authKey6
						}
					}
				)
				.then(result => {
					if (result.data.values !== undefined) {
						resolve(result.data.values);
					} else {
						reject('Something happened wrong.');
					}
				});
			});
		});
	}
	handleAnswerSelect = (e) => {
		const name = e.target.name; 
		const value = e.target.value;
		var currdata = [];
		var errname = "";
		if(name.indexOf("feedback") > -1) { 
			currdata = this.state.form_error_feedbacks;
			errname = "form_error_feedbacks";
		} else { 
			currdata = this.state.form_error_answer;
			errname = "form_error_answer"; 
		}
		var new_form_err_answer = currdata.filter((list) =>  {
			  return list !== "err-"+name;
			});
		this.setState(prevState => {
				return {  
					...prevState[errname], [errname]: new_form_err_answer
				}
			})
		
		
	  }
	async getResult(event) {
		event.preventDefault();
		var answers = [];
		var formData = new FormData(event.currentTarget);
		for (let [key, value] of formData.entries()) {
			if (key in answers) {
				answers[key] += ", " + value;
			} else {
				answers[key] = value;
			}
		}


		var error_answer = [];
		if(answers.numquestion !== undefined) {
			for(var n=0; n< Number(answers.numquestion); n++) {
				if(answers['question-' + (n + 1)] === undefined) { error_answer.push("err-question-"+(n+1)); }
			}
		}
		if(error_answer.length > 0) {
			this.setState({
				form_error_answer: error_answer
			})
		} else {
			var correct = 0;
			var incorrect = 0;
			var score = 0;
			var total = 0;
			var status = '';

			this.state.exam.forEach((exam, i) => {
				exam.question.forEach((question, j) => {
					total++;
					if (answers['question-' + (j + 1)] === question.questionAnswer) {
						correct++;
					} else {
						incorrect++;
					}
				});
				score = Math.ceil(correct / total * 100);
				status = score >= exam.passingScore ? 'Passed' : 'Failed';
			});

			var count = this.state.lesson.length + this.state.exam.length;
			var percent = (this.state.lesson.length / count) * 100;
			var last_lesson = this.state.lesson.length;
			if (status === 'Passed') {
				percent = 100;
				last_lesson = count;
				var [result1] = await Promise.all([this.editEnroll(this.state.enroll_id,last_lesson,Number(percent.toFixed(0)),score,status)]);
				if(result1) {
					this.setState({
						open_exam: 0,
						completion: Number(percent.toFixed(0)),
						last_lesson: last_lesson,
						correct: correct,
						incorrect: incorrect,
						score: score,
						status: status,
						form_error_answer: []
					})
				}
			} else {
				this.setState({
					open_exam: 0,
					correct: correct,
					incorrect: incorrect,
					score: score,
					status: status,
					form_error_answer: []
				});
			}
			document.getElementById('result-table').style.display = 'table';
		}
	}

	selectTab(key) {
		var current = this.state.currentTabKey;
		var playing = this.state.playing;	
		
		this.setState({
			selectTabKey: key,
			currentTabKey: key,
			playing: playing
		});
	}

	selectVideo(lessonId, videoId) {
		var currentLesson = 'lesson-' + lessonId;
		var currentVideo = 'video-' + videoId;
		var playing = this.state.playing;
		playing[currentLesson][currentVideo] = true;
		this.setState({
			playing: playing
		});
	}
	openVideo(e) {
		var thumbnail = e.target;
		var video = e.target.previousElementSibling;
		thumbnail.style.display = 'none';
		video.style.display = 'block';
		video.firstChild.play();
	}

	async openAttachment(e,attch,i,j) {
		e.preventDefault();
		var thumbnail = e.target;
		thumbnail.style.display = 'none';
		var video = e.target.previousElementSibling;
		video.style.display = 'block';
	}
	
	async openLesson(number,used) {
		this.scrollToRef.current.scrollIntoView();
		if(used === "next") {
			this.setState({
				tabKey: 'lesson-'+(number + 1),
				selectTabKey: 'lesson-'+(number + 1),
				currentTabKey: 'lesson-'+(number + 1),
			});
		} else {
			var count = this.state.lesson.length + this.state.exam.length;
			var percent = (number / count) * 100;
			var completion_data = Number(percent.toFixed(0));
			
			this.editEnroll(this.state.enroll_id,number,completion_data,this.state.score,this.state.status)
			.then((resultid) => { 
				this.setState({
					enroll_id: resultid,
					last_lesson: number,
					completion: completion_data,
					tabKey: 'lesson-'+(number + 1),
					selectTabKey: 'lesson-'+(number + 1),
					currentTabKey: 'lesson-'+(number + 1),
				}, () => {
					this.selectTab('lesson-'+(number + 1));
				});
			});
		}
	}
	closeExam() {
		this.setState({
			open_exam: 0
		});
	}
	openExam() {
		this.setState({
			open_exam: 1
		});
	}
	openFeedback() {
		this.setState({
			open_feedback: 1
		});
	}

	closeFeedback() {
		this.setState({
			open_feedback: 0
		});
	}

	submitFeedback = (libraryid,memberid,answers) => {
		return new Promise((resolve, reject) => {
		  var data = {
			"member_id": memberid,
			"library_id": libraryid,
			"feedback_answer": answers,
			"form_id": "form-addfeedback"
		  };
		  fetch(global.config.urlLink+"/libraryfeedbackadd/", {
			method: 'POST',
			headers: {
			  'Accept': 'application/json',
			  'Content-Type': 'application/json',
			  'Access-Control-Allow-Origin': '*',
			  'Authorization': authKey
			},
			body: JSON.stringify(data)
		  }).then(response => {
			if (response.status === 200) {
			  return response.json();
			} else {
			  console.log('Something happened wrong.');
			}
		  }).then(resultJson => {
			if (resultJson.status === "200") {
			  resolve(resultJson.values);
			} 
		  });
		});
	  }
	async sendFeedback(event) {
		event.preventDefault();
		var formData = new FormData(event.currentTarget);
		var answers = [];
		var error_answer2 = [];
		var a=0;
		this.state.feedbackList
		  .filter(a => a.feedbackStatus === 1)
		  .sort((a, b) => a.feedbackNumber - b.feedbackNumber)										
		  .forEach((feedback, i) => {
			for (let [key, value] of formData.entries()) {
				if (key === 'feedback-' + feedback.feedbackNumber) {
					feedback.feedbackInput = key;
					feedback.feedbackAnswer = value;
					if(value !== "") { a++; }
				}
			}
			answers.push(feedback);
			if(feedback.feedbackAnswer === undefined) { error_answer2.push("err-feedback-"+feedback.feedbackNumber); }
		  });
		
		if(error_answer2.length > 0) {
			this.setState({
				form_error_feedbacks: error_answer2
			  })
		} else {
		  if(a>0) {
			document.getElementById('submit-feedback').setAttribute('disabled', 'true');
			var certificate_url = await this.uploadCertificate();
			if(certificate_url) {
				var [result1a, result2a] = await Promise.all([this.submitFeedback(this.state.id,this.state.member_id,answers), this.addCertificate(this.state.id,this.state.member_id,this.state.enroll_id,certificate_url,this.state.title,this.state.score)]);
				if(result2a) {
					document.getElementById('submit-feedback').setAttribute('disabled', 'false');
					this.setState({
					  open_feedback: 0,
					  certificate: certificate_url,
					  feedback: [{memberId: this.state.member_id}]
					})
				  }
			  }
		  }
		}  
	}

	videoProgress(module_id, title, params) {
		var played = params.played;
		var videoList = this.state.videoList;
		var totalDone = 0;
		for (var i = 0; i < videoList[module_id - 1].length; i++) {
			if (videoList[module_id - 1][i].title === title) {
				videoList[module_id - 1][i].played = played;
				if (played >= .75) {
					videoList[module_id - 1][i].done = true;
				}
			}
			if (videoList[module_id - 1][i].done) {
				totalDone++;
			}
		}

		this.setState({
			videoList: videoList
		});
		
		if ((document.getElementById('btn-next-' + module_id) !== null) && (videoList[module_id - 1].length === totalDone)) {
			document.getElementById('btn-next-' + module_id).classList.remove('disabled');
		}
	}

	nextDisabled(module_id) {
		if (document.getElementById('btn-next-' + module_id) !== null) {
			document.getElementById('btn-next-' + module_id).classList.add('disabled');
		}
	}

	openPdf(url) {
		this.setState({
			open_pdf: true,
			url_pdf: url
		});
	}

	closePdf() {
		this.setState({
			open_pdf: false
		});
	}

	answerAreaContent(question,j,questionType,types) {
		var returnanswer=[];
		(questionType=== "Essay")?
			returnanswer.push(<div className="form-check"><textarea className="form-control" name={types+"-" + (j + 1)} rows="3" required></textarea></div>)
		: 
			question.forEach((resquestion,key) => {
				
				(resquestion !== undefined && resquestion !== "" )? (
					returnanswer.push(<div className="form-check">
						<label>
							<input className="form-check-input" type={`${
									(questionType === "Multiple Choice") ? "radio" : "checkbox"
									}`}
								name={types+"-" + (j + 1)} value={`${
									(types === "question") ? (key+1) : resquestion
									}`} 
								onChange={this.handleAnswerSelect} 
							/>
							{Parser(ReplaceText(resquestion))}
						</label>
					</div>)	
				) : <></>
			})
		return (returnanswer);
	}
	componentDidMount() {
		
		var url = window.location.href;
		var id = url.substring(url.lastIndexOf('/') + 1);
		
		fetch(global.config.urlLink+"/libraryview/" + id, {
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Authorization': authKey
		}
		}).then(response => {
			if (response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong');
			}
		}).then(resultJson => {
			var content = resultJson.values[0].content[0].maincontent;
			if(content !== undefined) {
			
				if((localStorage.getItem("logged_in") === global.config.randomLoginKey) && (localStorage.getItem("status") === global.config.memberPlan[1])) { 
					var lessons = resultJson.values[0].scontent;
					var feedbacks = resultJson.values[0].scontent3;
					var exams = resultJson.values[0].scontent2;
					var datalibrary = {
						title: content.libraryTitle,
						category: content.categoryName,
						pictures: content.libraryPicture
					}
					getAllDatas(id,lessons,exams,feedbacks,this.state.member_id,datalibrary)
					.then((response) => {
						var enrollResult = response.enrolldata;
						var lessonResult = response.lessondata;
						var examResult = response.examdata;
						var feedbackResult = response.feedbackdata;
						var feedbackListResult = response.feedbacklistdata;
						var enrollIdz="", lastLessonz=0, completionz=0,scorez=0,statuz="",certificatez="",certificatez_bg="",fullNamez="",companyNamez="";
						var lastVisitz= Moment().format('D MMMM Y');
						if(enrollResult.enrollcontent.subdocid !== "") {
							enrollIdz = enrollResult.enrollcontent.subdocid;
							lastVisitz = Moment.unix(enrollResult.enrollcontent.lastVisit._seconds).format('D MMMM Y');
							lastLessonz = enrollResult.enrollcontent.lastLesson;
							completionz = enrollResult.enrollcontent.completion;
							scorez = enrollResult.enrollcontent.examScore? enrollResult.enrollcontent.examScore : 0;
							statuz = enrollResult.enrollcontent.examStatus ? enrollResult.enrollcontent.examStatus : "";
							certificatez = enrollResult.certificate;
							fullNamez = enrollResult.memberInfo.memberName;
							if(enrollResult.memberInfo.memberCompany !== undefined) { companyNamez = enrollResult.memberInfo.memberCompany; }
							if(enrollResult.memberInfo.certificateBg !== undefined) { certificatez_bg = enrollResult.memberInfo.certificateBg; }
							if(companyNamez === "aegis.com.bn" || companyNamez === "company.com") { certificatez_bg = process.env.PUBLIC_URL + "/img/bg-certificate-aegis2.png"; }
						}
						var videoList = [];
						var playing = {};
						if(lessonResult.lessoncontent.length) {
							lessonResult.lessoncontent.sort((a, b) => parseInt(a.lessonNumber) - parseInt(b.lessonNumber));
							for(var i = 0; i < lessonResult.lessoncontent.length; i++) {
								var videoItem = [];
								if(lessonResult.lessoncontent[i].attachment.length) {
									playing['lesson-' + (i + 1)] = {};
									for(var j = 0; j < lessonResult.lessoncontent[i].attachment.length; j++) {
										if(lessonResult.lessoncontent[i].attachment[j].fileType === 'video/mp4') {
											playing['lesson-' + (i + 1)]['video-' + (j + 1)] = true;
											videoItem.push({
												title : lessonResult.lessoncontent[i].attachment[j].fileTitle,
												played : 0,
												done: false
											})
										}
									}
								}
								videoList.push(videoItem);
							}
						}	
						this.setState({
							id: resultJson.values[0].docid,
							title: content.libraryTitle,
							category: content.categoryName,
							excerpt: ReplaceText(content.libraryShortDesc),
							description: ReplaceText(content.libraryLongDesc),
							image: content.libraryPicture,
							video: content.libraryVideo,

							member_name: fullNamez,
							member_company: companyNamez,
							enroll_id: enrollIdz,
							last_visit: lastVisitz,
							last_lesson: lastLessonz,
							completion: completionz,
							score: scorez,
							status: statuz,
							tabKey: 'lesson-' + enrollResult.goToTab,
							currentTabKey: 'lesson-' + enrollResult.goToTab,
							selectTabKey: 'lesson-' + enrollResult.goToTab,
							certificate: certificatez,
							certificate_bg: certificatez_bg,
							lesson: lessonResult.lessoncontent,
							is_introduction: lessonResult.isIntroduction,
							exam: examResult,
							feedback: feedbackResult,
							feedbackList: feedbackListResult,
							videoList: videoList,
							playing: playing,
							isLoad: true
						});
					});
				} else {
					this.setState({
						id: resultJson.values[0].docid,
						title: content.libraryTitle,
						category: content.categoryName,
						description: ReplaceText(content.libraryLongDesc),
						image: content.libraryPicture,
						link: '/library/' + id,
						lesson: resultJson.values[0].scontent,
						isLoad: true 
					});
				}
			} else {
				window.location.href="/error-204";
			}
		});
	
	}

	render() {
		return (
			<div>

			{ (this.state.isLoad === false)? 
					<Loading value={{id:'account-area', type: 'screen'}}/> 
			  : 
				((localStorage.getItem("logged_in") === global.config.randomLoginKey) && (localStorage.getItem("status") === global.config.memberPlan[1]))?
					<div className="library-enroll">
						<div className="bg-dark" style={{marginTop:"-48px"}}>
							
							<Container>
								<div className="row py-5">
								{(this.state.image !== "" && this.state.image !== undefined)?
									<div className="col-md-4 text-center">
										<img className="mxw mb-4 br-8" style={{maxHeight:'350px'}} src={this.state.image} alt={this.state.title}/>
									</div>
								: <></>
								}
									<div className={`${
										(this.state.image !== "" && this.state.image !== undefined) ? "col-md-8 " : "col-md-12 "
										} `}>
										<h3 className="text-white mb-4">{this.state.title}</h3>
										<p className="text-white">
											<span className="me-4">
												<FaRegFolder className="me-1"/>
												{this.state.category}
											</span>
										</p>
										<p className="text-white mb-5">{this.state.excerpt}</p>
										<ul className="list-group list-group-horizontal library-usage">
											<li className="list-group-item col">
												<h5 className="text-center">{GetText() ? "Terakhir Dilihat" : "Last Visited"}</h5>
												<p className="text-center">{this.state.last_visit}</p>
											</li>
											<li className="list-group-item col">
												<h5 className="text-center">{GetText() ? "Modul Terakhir" : "Last Module"}</h5>
												<p className="text-center">
													{
														this.state.last_lesson > this.state.lesson.length ? (
															GetText() ? "Ujian" : "Exam"
														) : (
															this.state.last_lesson > 0 ? (
																this.state.is_introduction === 1 ? (
																	this.state.last_lesson === 1 ? (
																		GetText() ? "Pengantar" : "Introduction"
																	) : (
																		(GetText() ? "Modul " : "Module ") + (this.state.last_lesson - 1)
																	)
																) : (
																	(GetText() ? "Modul " : "Module ") + this.state.last_lesson
																)
															) : (
																"-"
															)
														)
													}
												</p>
											</li>
											<li className="list-group-item col">
												<h5 className="text-center">{GetText() ? "Pencapaian" : "Progress"}</h5>
												<p className="text-center">{this.state.completion + "%"}</p>
											</li>
										</ul>
									</div>
								</div>
							</Container>
						</div>
						<div className="bg-light">
							<Container>
								{
									this.state.video ? (
										<div className="row py-5 align-items-center">
											<div className="col-md-6">
												<h3 className="mb-4">{GetText() ? 'Deskripsi' : 'Description'}</h3>
												<p>{Parser(this.state.description)}</p>
											</div>
											<div className="col-md-6">
												<div className="embed-wrapper">
													<div className="embed-container" /*style={{display: 'none'}}*/>
														<ReactPlayer
															className="embed-content"
															url={global.config.urlLink+ "/getstreaming/video/" + this.state.video.split('/').pop().replace('.mp4', '')}
															light={this.state.image}
															onContextMenu={(e)=> e.preventDefault()}
															width='100%'
															height='100%'
															controls
															playing
															config={{ 
																file: { 
																	attributes: {
																		controlsList: 'nodownload'
																	} 
																} 
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className="py-5">
											<h3 className="mb-4">{GetText() ? 'Deskripsi' : 'Description'}</h3>
											<p>{Parser(this.state.description)}</p>
										</div>
									)
								}
							</Container>
						</div>
						{	
							this.state.tabKey && this.state.lesson.length > 0 ? (
								<Container ref={this.scrollToRef}>
									<div className="row py-5">
										<Tab.Container defaultActiveKey={this.state.tabKey} 
											onSelect={this.selectTab}
											activeKey={this.state.selectTabKey}
										>
											<div className="col-md-3 p-4">
												<div className="sidebar-tab sticky-top">
													<h3 className="mb-4">{GetText() ? "Daftar Modul" : "Module List"}</h3>
													<Nav variant="pills" className="flex-column">
														{
															this.state.lesson.length > 0 ? (
																this.state.lesson
																.sort((a, b) => a.lessonNumber - b.lessonNumber)
																.map((lesson, i) => {
																	return (
																		<Nav.Item key={"option-" + (i + 1)}>
																			<Nav.Link eventKey={"lesson-" + (i + 1)} id={"button-" + (i + 1)} /*onClick={this.resetRandom}*/
																				//active={(this.state.selectTabKey !== "" && this.state.selectTabKey === "lesson-"+(i + 1))? "true" : ""}
																				>
																				{
																					lesson.lessonNumber === '0' ? (
																						GetText() ? "Pengantar" : "Introduction"
																					) : (
																						GetText() ? "Modul " + lesson.lessonNumber : "Module " + lesson.lessonNumber
																					)
																				}
																				{
																					this.state.last_lesson >= (i + 1) ? (
																						<FaCheck className="sidebar-checked"/>
																					) : ''
																				}
																			</Nav.Link>
																		</Nav.Item>
																	);
																})
															) : ''
														}
														{
															this.state.exam.length > 0 ? (
																this.state.exam
																.sort((a, b) => a.examNumber - b.examNumber)
																.map((exam, i) => {
																	return (
																		<Nav.Item key={"option-" + (this.state.lesson.length + 1)}>
																			<Nav.Link eventKey={"lesson-" + (this.state.lesson.length + 1)} id={"button-" + (this.state.lesson.length + 1)}>
																				{GetText() ? "Ujian" : "Exam"}
																				{
																					this.state.status === "Passed" ? (
																						<FaCheck className="sidebar-checked"/>
																					) : ''
																				}
																			</Nav.Link>
																		</Nav.Item>
																	);
																})
															) : ''
														}
													</Nav>
												</div>
											</div>
											<div className="col-md-9 bg-light p-4 br-8">
												<Tab.Content>
													{
														this.state.lesson.length > 0 ? (
															this.state.lesson
															.sort((a, b) => a.lessonNumber - b.lessonNumber)
															.map((lesson, i) => {
																return (
																	<Tab.Pane eventKey={"lesson-" + (i + 1)} key={"lesson-" + (i + 1)}>
																		<h3 className="mb-4">
																			{
																				lesson.lessonNumber === '0' ?
																				(
																					GetText() ? "Pengantar" : "Introduction"
																				) : (
																					GetText() ? "Modul " + lesson.lessonNumber : "Module " + lesson.lessonNumber
																				)
																			}
																		</h3>
																		<h5 className="mt-4">{lesson.lessonTitle}</h5>
																		<div className="mb-3">{Parser(ReplaceText(lesson.lessonDesc))}</div>
																		{
																			lesson.attachment.length > 0 ? (
																				lesson.attachment
																				.sort((a, b) => b.fileType - a.fileType)
																				.map((attachment, j) => {
																					return(
																					
																						<div key={"attachment" + j} >
																							{
																										(attachment.storageUrl !== undefined && attachment.storageUrl !== "" && this.state.member_company !== "aegis.com.bn")? 
																											attachment.fileType === 'video/mp4' ? (
																												<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																													<div className="embed-container">
																														<ReactPlayer
																															className="embed-content"
																															url={global.config.urlLink+ "/getstreaming/video/" + attachment.storageUrl.split('/').pop().replace('.mp4', '')}
																															light={attachment.fileThumbnail ? attachment.fileThumbnail : false}
																															onContextMenu={(e)=> e.preventDefault()}
																															width='100%'
																															height='100%'
																															controls
																															playing
																															// playing={this.state.playing['lesson-' + (i + 1)]['video-' + (j + 1)]}
																															config={{ 
																																file: { 
																																	attributes: {
																																		controlsList: 'nodownload'
																																	} 
																																} 
																															}}
																															onProgress={(e) => this.videoProgress(i + 1, attachment.fileTitle, e)}
																															onReady={(e) => this.nextDisabled(i + 1, e)}
																															// onPlay={(e) => this.selectVideo((i + 1), (j + 1))}
																														/>
																														{(attachment.fileTitle !== undefined && attachment.fileTitle !== "")? <p className="embed-title">{attachment.fileTitle}</p>: <></>}
																													</div>
																												</div>
																											) : (
																												<div className="embed-link mb-4" key={"attachment-" + (i + 1) + "-" + j} onClick={() => this.openPdf(global.config.urlLink + "/getstreaming/reading/" + attachment.storageUrl.split('/').pop().replace('.pdf', '') + "#toolbar=0")}>
																													<img src={process.env.PUBLIC_URL + '/img/file-pdf.png'} alt="Click to open"/>
																													<p>{attachment.fileTitle}</p>
																													<Button >Open File</Button>
																												</div>
																											)
																										: ((attachment.fileUrl !== undefined && attachment.fileUrl !== "")?
																												attachment.fileType === 'video/mp4' ? (
																													<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																														<div className="embed-container" style={{display: attachment.fileThumbnail ? 'none' : 'block'}}  id={"div_video_content-"+(i+1)+"-"+j}>
																															{((this.state.member_company === "aegis.com.bn" || this.state.member_company === "company.com") && (attachment.storageUrl !== undefined && attachment.storageUrl !== ""))? 
																															<><video src={attachment.storageUrl} className="embed-content" name={"video_content"+(i+1)+"-"+j} autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()} allowFullScreen></video></>
																															: <><iframe className="embed-content" src={attachment.fileUrl} key={this.state.random} frameBorder="0" title={attachment.fileName} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe></>
																															} 
																														</div>
																														<div className="embed-thumbnail" onClick={(e) => this.openAttachment(e, attachment,(i+1),j)} style={{display: attachment.fileThumbnail ? 'block' : 'none'}}>
																															<img className="image-thumbnail" src={attachment.fileThumbnail} alt="Video Thumbnail"/>
																															<img className="play-button" src={ImgPlayButton} alt="Play Button"/>
																															<img className="play-active" src={ImgPlayActive} alt="Play Button"/>
																														</div>
																													</div>
																												) : (
																													<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																														<div className="embed-container">
																															<iframe className="embed-content" src={attachment.fileUrl} frameBorder="0" title={attachment.fileName} allowFullScreen></iframe>
																														</div>
																													</div>
																												)	
																											: <></>		
																										)
																									
																							}
																						</div>
																						
																					)
																				})
																			) : ''
																		}
																		<div className="text-center" id="button-action-area-module">
																			{
																				this.state.last_lesson < (i + 1) ? (
																					<Button  onClick={() => this.openLesson((i + 1),"end")}>{GetText() ? "Tandai sebagai selesai" : "Complete this module"}</Button>
																				) : 
																				
																					((this.state.last_lesson === (this.state.lesson.length + this.state.exam.length)) && (this.state.last_lesson === (i + 1))) ? (
																						<div>
																							<p className="text-success mb-2">{GetText() ? "Semua modul telah selesai" : "All modules completed"}</p>
																							{
																								this.state.feedback.length !== 0 ? '' : (
																									<Button className="mt-2"  onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Button>
																								)
																							}
																							<p className="mt-4">
																								<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																							</p>
																						</div>
																					) : (
																						<div>
																							<p className="text-success mb-2">{GetText() ? "Modul telah selesai" : "Module completed"}</p>

																							{ 
																							  (this.state.status === "") ?
																								(this.state.lesson.length === (i + 1)) && (this.state.exam.length !== 0) ? 
																								
																									<Button className="mt-2" onClick={() => this.openLesson((i + 1),"next")}>{GetText() ? 'Lanjut ke ujian' : 'Continue to exam'}</Button>
																								
																								 : 
																								 	<Button className="mt-2" onClick={() => this.openLesson((i + 1),"next")}>{GetText() ? 'Lanjut ke modul berikutnya' : 'Continue to next module'}</Button>
																								
																							  : <></>
																							}
																						</div>
																					)
																						
																					
																				
																			}
																		</div>
																	</Tab.Pane>
																);
															})
														) : ''
													}
													<Tab.Pane eventKey={"lesson-" + (this.state.lesson.length + 1)}>
														<div className={`${this.state.status !== "" ? "d-none" : ""} exam-opening`}>
															{
																this.state.exam.length > 0 ? (
																	this.state.exam
																	.sort((a, b) => a.examNumber - b.examNumber)
																	.map((exam, i) => {
																		return (
																		exam.examStatus === 1 ? (
																			<div key={"exam-" + i}>
																				<h3 className="mb-4">{exam.examTitle}</h3>
																				<p className="mb-4">{exam.examDesc}</p>
																				<div id="exam-content-info" className={`${this.state.status !== "" ? "d-none" : this.state.open_exam === 1 ? "d-none" : "" }`}>
																					<table className="mb-4">
																						<tbody>
																							<tr>
																								<td style={{width:"120px"}}><strong>{GetText() ? "Jenis soal" : "Type of question"}</strong></td>
																								<td className="pl-2">{GetText() ? "Pilihan ganda" : "Multiple choice"}</td>
																							</tr>
																							<tr>
																								<td><strong>{GetText() ? "Jumlah soal" : "Number of question"}</strong></td>
																								<td className="pl-2">{exam.question.length}</td>
																							</tr>
																							<tr>
																								<td><strong>{GetText() ? "Skor minimal" : "Minimum score"}</strong></td>
																								<td className="pl-2">{exam.passingScore}</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																				<div id="exam-content-question" className={`${this.state.status !== "" ? "d-none" : this.state.open_exam === 1 ? "" : "d-none" }`}>
																					<Form onSubmit={this.getResult}>
																					<input type="hidden" value={exam.question.length} name="numquestion" />
																					{	
																						exam.question.length > 0 ? (
																							exam.question.map((question, j) => {
																								var arrQuestionText = [question.option1,question.option2,question.option3,question.option4,question.option5];
																								return (
																									<div className="mb-2" key={"question-" + (j + 1)}>
																										{
																											
																												<div className="bg-light p-2 br-8">
																													<strong>{(GetText() ? "Soal #" : "Question #") + (j + 1)}</strong>
																													<p>{Parser(ReplaceText(question.questionText))}</p>
																													<div>{this.answerAreaContent(arrQuestionText,j,question.questionType,"question")}</div>
																													{(this.state.form_error_answer.length>0 && this.state.form_error_answer.indexOf("err-question-"+(j+1)) > -1 )? 
																														<div className="mt-1"><span className="error-message-cms">{GetText() ? "jawaban perlu dipilih terlebih dahulu" : "answer must be filled in first"}</span></div>
																													 : <></>
																													}
																												</div>
																										}
																									</div>
																								)
																							})
																						) : ''
																					}
																					<div className="mt-4">
																						<Button variant="primary" type="submit" value="Save" id="submit">{GetText() ? "Kirim Jawaban" : "Submit Answers"}</Button>
																						<Button variant="outline-primary" className="ms-2" onClick={() => this.closeExam()}>{GetText() ? 'Batal' : 'Cancel'}</Button>
																					</div>
																				</Form>
																				</div>

																				
																				{
																					this.state.last_lesson === this.state.lesson.length ? (
																						(this.state.open_exam !== 1) && 
																						<Button onClick={() => this.openExam()}>{GetText() ? 'Lanjut ke soal' : 'Continue to questions'}</Button>
																					) : (
																						this.state.status === 'Passed' ? (
																							<div id="button-action-area-exam">
																								<p className="text-success">{GetText() ? 'Anda telah menyelesaikan semua modul' : 'You have completed all the modules'}</p>
																								{
																									(this.state.feedback.length !== 0)?
																										(this.state.certificate !== "")? 
																											<a href={this.state.certificate} className="btn btn-primary" target="_blank" rel="noreferrer" download disabled={this.state.feedback.length !== 0 ? '' : 'disabled'}>{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																											: <></>
																										: 
																										<>
																										<Button className="my-2" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Button>
																										<p className="text-danger mt-2">{GetText() ? 'Isi form feedback untuk mengunduh sertifikat' : 'Fill feedback form to download certificate'}</p>
																										</>

																								}
																								
																								<p className="mt-4">
																									<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																								</p>
																							
																							</div>
																						) : (
																							<p className="text-danger">{GetText() ? 'Anda harus menyelesaikan semua modul untuk dapat mengerjakan ujian' : 'You have to complete all the modules to do the exam'}</p>
																						)
																					)
																				}
																			</div>
																		) : ''
																		)
																	})
																) : ''
															}
														</div>
														
														<div className={`${this.state.status !== "" ? "" : "d-none"} text-center exam-result`}>
															<h3 className="mb-4">{GetText() ? "Hasil penilaian" : "Your result"}</h3>
															<table className="mx-auto" id="result-table">
																<thead>
																	<tr>
																		<th align="center">{GetText() ? "Benar" : "Correct"}</th>
																		<th align="center">{GetText() ? "Salah" : "Incorrect"}</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td className="correct"><span id="correct">{this.state.correct}</span></td>
																		<td className="incorrect"><span id="incorrect">{this.state.incorrect}</span></td>
																	</tr>
																</tbody>
															</table>
															<h5 className="mt-4">{GetText() ? "Skor total" : "Total score"}: <span id="score">{this.state.score}</span></h5>
															<p className="mt-2" id="result">
																{
																	this.state.status === 'Passed' ? (
																		GetText() ? "Selamat, Anda telah berhasil lulus ujian!" : "Congratulations on passing your exam!"
																	) : (
																		GetText() ? "Nilai Anda belum mencapai batas kelulusan" : "Your score is below the passing grade"
																	)
																}
															</p>
															<div className="text-center mt-4">
																
																{
																	this.state.status === 'Passed' ? (
																		<div>
																			{
																				(this.state.feedback.length !== 0)?
																					(this.state.certificate !== "")? 
																						<a href={this.state.certificate} className="btn btn-primary" target="_blank" rel="noreferrer" download disabled={this.state.feedback.length !== 0 ? '' : 'disabled'}>{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																					: <></>
																				: 
																				<>
																					<Button className="my-2" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Button>
																					{/* <Nav.Link className="btn btn-primary d-inline-block" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Nav.Link> */}
																					<p className="text-danger mt-2">{GetText() ? 'Isi form feedback untuk mengunduh sertifikat' : 'Fill feedback form to download certificate'}</p>
																				</>
																			}
																			
																			<p className="mt-4">
																				<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																			</p>
																			
																		</div>
																	) : (
																		<a href={"/library/" + this.state.id} className="btn btn-primary">{GetText() ? "Ulangi ujian" : "Retake exam"}</a>
																	)
																}
															
															</div>
														</div>
													</Tab.Pane>
												</Tab.Content>
											</div>
										</Tab.Container>
										<Modal className="feedback" size="lg" show={this.state.open_feedback} onHide={this.closeFeedback}>
											<Modal.Body className="p-4">
												<h5 className="mb-3">{GetText() ? 'Form Feedback' : 'Feedback Form'}</h5>
												<Form onSubmit={this.sendFeedback}>	
													{
														this.state.feedbackList.length !== 0 ? (
															this.state.feedbackList
															.filter(a => a.feedbackStatus === 1)
															.sort((a, b) => a.feedbackNumber - b.feedbackNumber)
															.map((feedback, i) => {
																var arrFeedbackText = [feedback.option1,feedback.option2,feedback.option3,feedback.option4,feedback.option5];
																return (
																	<div className="mb-3" key={"feedback-" + i}>
																		<p className="mb-2">{feedback.feedbackText}</p>
																		<div>{this.answerAreaContent(arrFeedbackText,i,feedback.feedbackType,"feedback")}</div>
																		{(this.state.form_error_feedbacks.length>0 && this.state.form_error_feedbacks.indexOf("err-feedback-"+(i+1)) > -1 )? 
																			<div className="mt-1"><span className="error-message-cms">{GetText() ? "jawaban perlu dipilih terlebih dahulu" : "answer must be filled in first"}</span></div>
																			: <></>
																		}
																	</div>
																)
															})
														) : ''
													}
													<div className="mt-4">
														<Button variant="primary" type="submit" value="Save" id="submit-feedback">{GetText() ? "Kirim" : "Submit"}</Button>
														<Button variant="outline-primary" className="ms-2" type="button" onClick={this.closeFeedback}>{GetText() ? 'Batal' : 'Cancel'}</Button>
														
													</div>
												</Form>
											</Modal.Body>
										</Modal>
										<Modal className="pdf" size="xl" show={this.state.open_pdf} onHide={this.closePdf}>
											<Modal.Header closeButton />
											<Modal.Body>
												<embed type="application/pdf" src={this.state.url_pdf} width="100%" height="100%"></embed>
												
											</Modal.Body>
										</Modal>
										
									</div>
								</Container>
							) : ''
					}
					<div className="text-center mx-auto">
						<Certificate title={this.state.title} name={this.state.member_name} type="library" background={this.state.certificate_bg}/>
					</div> 
					{/* <div id="loading-area" className="hidden loading"></div> */}
					</div>
			: <div>
				{/* <div><Error403 /></div> */}
				<div className="detail-library">
					<section>
					<Container>
						<div className="row align-items-start m-0">
						<div className="col-md-5 bg-light p-5 br-8">
							<div className="row">
							<div className="col">
								<h4>Library</h4>
							</div>
							<div className="col-auto text-end">
								<p className="text-red mt-1"><strong>{this.state.category}</strong></p>
							</div>
							</div>                
							<div className="text-center">
							<img className="mxw br-8" src={this.state.image} alt={this.state.title}/>
							<h5 className="my-4">{this.state.title}</h5>
							<Button className="btn-lg"  onClick={() => window.location.href='/subscribe' }>{GetText() ? 'Daftar' : 'Enroll'}</Button>

							{/* <a href={'/subscribe/'} className="btn btn-lg btn-primary mb-3">{GetText() ? "Daftar" : "Enroll"}</a> */}
							</div>
						</div>
						<div className="col-md-7 agenda p-5 br-8">
							<h4 className="mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
							<p className="mb-5">{parse(this.state.description)}</p>
							{
							this.state.lesson.length > 0 ? (
								<div>
								<h4 className="mb-4">{GetText() ? "Daftar Modul" : "Modules"}</h4>
								{
									this.state.lesson
									.sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber)
									.map((lesson, i) => {
									return (
										<div className="row" key={i}>
										<div className="col-md-3">
											<label className="label-rounded-2">{GetText() ? "Modul " + (i + 1) : "Module " + (i + 1)}</label>
										</div>
										<div className="col-md-9">
											<h5>{lesson.subcontent.lessonTitle}</h5>
											<p className="clamp-3">{parse(ReplaceText(lesson.subcontent.lessonDesc))}</p>
										</div>
										</div>
									)
									})
								}
								</div>
							) : ''
							}
						</div>
						</div>
					</Container>
					</section>
				</div>
				{/* <div id="loading-area" className="hidden loading"></div> */}
			  </div>
			}
			</div>
		);
	}
}
