import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Button } from 'react-bootstrap';
import { FaPlus, FaPen, FaTrash, FaToggleOn, FaToggleOff, FaAngleRight, FaRegFilePdf, FaRegFileVideo } from "react-icons/fa6";

// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import ToggleOffIcon from '@mui/icons-material/ToggleOff';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';
import DateTimeFormats from '../config/date-time-format';
import NAimage from '../img/pic-na.png';
import Popup from '../inc/PopUp';
import CTLibraryAttachEdit from './ct-library-pop-attach-edit';
import CTLibraryAttachForm from './ct-library-pop-attach-form';
import CTLibraryExamEdit from './ct-library-pop-exam-edit';
import CTLibraryQuestionImport from './ct-library-pop-exam-import';
import CTLibraryLessonForm from './ct-library-pop-lesson-form';
import CTLibraryQuestionForm from './ct-library-pop-question-form';


const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class CTLibraryDetailEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      library_docid: '',
      library_createby: '',
      library_createdate: '',
      library_title: '',
      library_short_desc: '',
      library_long_desc: '',
      library_language: '',
      library_picture: '',
      library_video: '',
      library_category: '',
      library_subcategory: '',
      library_status: '',
      library_lesson_list: [],
      library_exam: [],
      library_question_list: [],
      isOpen: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isOpen6: false,
      stPopTitle: '',
      stPopTitle2: '',
      stPopTitle3: '',
      stPopTitle4: '',
      stPopTitle5: '',
      stPopTitle6: '',
      otherContent: '',
      otherContent2: '',
      otherContent3: '',
      otherContent4: '',
      otherContent5: '',
      otherContent6: ''
    }
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.lessonContent = this.lessonContent.bind(this);
    this.deleteDataLesson = this.deleteDataLesson.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
        ...prevState.isOpen2, isOpen2: false,
        ...prevState.isOpen3, isOpen3: false,
        ...prevState.isOpen4, isOpen4: false,
        ...prevState.isOpen5, isOpen5: false,
        ...prevState.isOpen6, isOpen6: false
      }
    })
  }
  
  lessonContent = (dataLesson) => {
    var lessonsList = [];
    if(dataLesson.length > 0) {
      dataLesson.sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber);
      for(var ls=0; ls < dataLesson.length; ls++) {
          var retResult3 = dataLesson[ls];
          var attachmentList = [];
          var fileList = [];
          if(retResult3.sub2content.length > 0) {
            for(var ls2=0; ls2 < retResult3.sub2content.length; ls2++) {
              var thumbfile = ""; if(retResult3.sub2content[ls2].subsubcontent.fileThumbnail !== undefined) { thumbfile = retResult3.sub2content[ls2].subsubcontent.fileThumbnail; }
              fileList.push(retResult3.sub2content[ls2].subsubcontent.fileName);
              attachmentList.push(<li key={ls2}>
                <div className="row">
                    <div className="col-md-9">
                      <a href={retResult3.sub2content[ls2].subsubcontent.storageUrl} target="_blank" rel="noreferrer">
                        {retResult3.sub2content[ls2].subsubcontent.fileType === 'video/mp4' ? <FaRegFileVideo className="styleicons"/> : <FaRegFilePdf className="styleicons"/>} 
                        {(retResult3.sub2content[ls2].subsubcontent.fileTitle !== undefined && retResult3.sub2content[ls2].subsubcontent.fileTitle !== "")? retResult3.sub2content[ls2].subsubcontent.fileTitle : retResult3.sub2content[ls2].subsubcontent.fileName}
                      </a>
                    </div>
                    <div className="col-md-3 d-inline-flex justify-content-end">
                      {/* {(retResult3.sub2content[ls2].subsubcontent.fileType === "video/mp4") && <EditIcon className="styleicons" onClick={this.urleditAttachment.bind(this, this.state.library_docid,retResult3.subdocid, retResult3.sub2content[ls2].subsubdocid, 'Edit', thumbfile)}/> } */}
                      <FaPen className="styleicons" onClick={this.urleditAttachment.bind(this, this.state.library_docid,retResult3.subdocid, retResult3.sub2content[ls2].subsubdocid, 'Edit', retResult3.sub2content[ls2].subsubcontent)}/>
                      <FaTrash className="styleicons" onClick={this.urldeleteAttachment.bind(this, this.state.library_docid, retResult3.subdocid, retResult3.sub2content[ls2].subsubdocid, retResult3.sub2content[ls2].subsubcontent.fileName)}/>
                    </div>
                </div>
                
              </li>
              )
            }
          }
          var attachmentContent = [];
          // if(attachmentList.length>0) {
            attachmentContent.push(
              <div className="mt-2" >
                  <div className="d-flex mb-2">
                    <div className="col-md-9 d-flex align-items-center fw-bold">Attachment</div>
                    <div className="col-md-3 d-flex justify-content-end"><Button variant="light" size="sm" onClick={this.urlnewAttachment.bind(this, this.state.library_docid,retResult3.subdocid,'','Add New')}><FaPlus style={{color:"#666666", fontSize: "14pt", marginTop: "-3px"}} className="me-1" />Add</Button></div>
                  </div>
                  <div><ul>{attachmentList}</ul></div>
              </div>
            )
          // }
          var trcls = '';
          var lessonDesc = retResult3.subcontent.lessonDesc !== undefined ?  ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',retResult3.subcontent.lessonDesc)) : '';
          lessonsList.push(
            <tr key={ls+1} className={trcls}>
              <th scope="row">{retResult3.subcontent.lessonNumber}</th>
              <td>{retResult3.subcontent.lessonTitle}</td>
              <td>
                <div>{lessonDesc}</div>
                <div className="mt-2">{attachmentContent}</div>
              </td>
              <td>
                <FaPen className="styleicons" title="edit" onClick={this.urlnewLesson.bind(this, this.state.library_docid,retResult3.subdocid,'Edit')}/>
                <FaTrash className="styleicons" onClick={this.urldeleteLesson.bind(this, this.state.library_docid, retResult3.subdocid, fileList)}/> 
              </td>
            </tr>
          );
      } //  x8p24uj2gm6l.pdf
    } else {
        lessonsList.push(<tr key="0">
            <td></td>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
            <td></td>
          </tr>)
    }
    return lessonsList;
  }
  urlnewLesson(valId,subvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle, stPopTitle: action+" Module",
          ...prevState.otherContent, otherContent : '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","liblessonid":"'+subvalId+'"}'
      }
    })
  }
  deleteDataLesson(valId,subvalId) {
    var urlString = global.config.urlLink+"/librarylessondelete";
    // var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","numchild":1,"childlevel":1}';
    var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","form_id":"delete-library-lesson"}';
    var data = JSON.parse(datatext); 
    fetch(urlString, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === "200") {
          window.location.reload();
        } else {
          console.log('Something happened wrong delete');
        }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldeleteLesson(valId,subvalId,filesList) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this module?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      this.deleteDataLesson(valId,subvalId);
      /*
      if(filesList !== "") {
        var dataFile = JSON.parse('{"fileid":"'+filesList+'","form_type":"buckets"}');
        fetch(global.config.urlLink+"/deletefile", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(dataFile)
        }).then(res2 => {
            if(res2.status === 200) 
                return res2.json() 
        }).then( resJson2 => {
            if(resJson2.status === '200') { 
                this.deleteDataLesson(valId,subvalId);
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        this.deleteDataLesson(valId,subvalId);
      }
      */
        
    }
  }

  urlnewAttachment(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle2, stPopTitle2: action+" Attachment",
          ...prevState.otherContent2, otherContent2: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","libraryLessonId":"'+subvalId+'","libraryAttachId":"'+subsubvalId+'"}'
      
      }
    })
  }

  urleditAttachment(valId,subvalId,subsubvalId,action,libAttachData) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: true,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle3, stPopTitle3: action+" Attachment",
          ...prevState.otherContent3, otherContent3: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","libraryLessonId":"'+subvalId+'","libraryAttachId":"'+subsubvalId+'","libraryAttachData":'+JSON.stringify(libAttachData)+'}'
      
      }
    })
  }
  
  urldeleteAttachment(valId,subvalId,subsubvalId,fileName) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this attachment?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/libraryattachmentdelete";
      var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","subsubdocid":"'+subsubvalId+'","filename":"'+fileName+'","form_id":"delete-library-attach"}';

      var data = JSON.parse(datatext); 
      fetch(urlString, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            window.location.reload();
            /*
            var dataFile = JSON.parse('{"filename":"'+fileName+'","form_type":"buckets"}');
            fetch(global.config.urlLink+"/deletefile", {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey,
              }, body : JSON.stringify(dataFile)
            }).then(res2 => {
                if(res2.status === 200) 
                    return res2.json() 
            }).then( resJson2 => {
                if(resJson2.status === '200') { 
                    document.getElementById('loading-area').style.display = 'none'; 
                    window.location.reload();
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            });
            */
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log('Something happened wrong delete');
          }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      });
    }
  }

  urleditExam(valId,subvalId,action) {
    subvalId = subvalId !== undefined ? subvalId : "";
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: true,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle4, stPopTitle4: action+" Exam",
          ...prevState.otherContent4, otherContent4: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","examDesc":"'+this.state.library_exam.examDesc+'","examPassScore":"'+this.state.library_exam.passingScore+'","examNumQuestion":"'+this.state.library_exam.numberQuestion+'","examStatus":"'+this.state.library_exam.examStatus+'"}'
      }
    })
  }

  urlpublishExam(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block'; 
    // var datatext ='{"library_id":"'+this.state.library_docid+'","libexam_id":"'+valId+'","libexam_title":"'+this.state.library_exam.examTitle+'","libexam_number":0,"libexam_desc":"'+this.state.library_exam.examDesc+'","libexam_pass_score":"'+this.state.library_exam.passingScore+'","libexam_num_question":"'+this.state.library_exam.numberQuestion+'","libexam_status":'+nextstat+',"form_id":"form-editexam"}';
    var datatext ='{"library_id":"'+this.state.library_docid+'","libexam_id":"'+valId+'","libexam_status":'+nextstat+',"form_id":"form-editpublishexam"}';
    var data = JSON.parse(datatext);

    var urlFetch = global.config.urlLink+"/libraryexamedit";
    fetch(urlFetch, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(res => {
      if(res.status === 200) return res.json() 
    }).then( resJson => {
      document.getElementById('loading-area').style.display = 'none'; 
      if(resJson.status === '200') {
        // window.location.href=global.config.urlHost+'/cteditdetaillibrary/'+this.state.library_docid;
        window.location.reload();
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  urlimportExam(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: true,
          ...prevState.stPopTitle6, stPopTitle6: action+" Question",
          ...prevState.otherContent6, otherContent6: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+this.state.library_question_list.examDesc+'","examScore":"'+this.state.library_exam.passingScore+'","examQty":"'+this.state.library_exam.numberQuestion+'"}'
      
      }
    })
  }
  urlnewQuestion(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: true,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle5, stPopTitle5: action+" Question",
          // ...prevState.otherContent5, otherContent5: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+this.state.library_question_list.examDesc+'","examScore":"'+this.state.library_exam.passingScore+'","examQty":"'+this.state.library_exam.numberQuestion+'"}'
          ...prevState.otherContent5, otherContent5: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"","questionText":"","questionNumber":"","questionType":"","questionAnswer":"","questionOption1":"","questionOption2":"","questionOption3":"","questionOption4":"","questionOption5":""}'
      
      }
    })
  }

  urleditQuestion(valId,subvalId,subsubval,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: true,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle5, stPopTitle5: action+" Question",
          ...prevState.otherContent5, otherContent5: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+subsubval.subsubdocid+'","questionText":"'+subsubval.subsubcontent.questionText+'","questionNumber":"'+subsubval.subsubcontent.questionNumber+'","questionType":"'+subsubval.subsubcontent.questionType+'","questionAnswer":"'+subsubval.subsubcontent.questionAnswer+'","questionOption1":"'+subsubval.subsubcontent.option1+'","questionOption2":"'+subsubval.subsubcontent.option2+'","questionOption3":"'+subsubval.subsubcontent.option3+'","questionOption4":"'+subsubval.subsubcontent.option4+'","questionOption5":"'+subsubval.subsubcontent.option5+'"}'
      
      }
    })
  }

  loadContent = (docid) => {
    return new Promise((resolve) => {
      fetch(global.config.urlLink+"/libraryview/"+docid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        resolve(resJson);
      })
    })
  }
  async loadData(currpage,typelink,docid) {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1] = await Promise.all([this.loadContent(docid)]);
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {

            var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="",cDate="",lStatus="";
            var lExam ='',lQuestion ='';
            if(result1.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = result1.values[0].content[0].maincontent.libraryTitle; }
            if(result1.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                lShortDesc = ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryShortDesc);
                lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
            }
            if(result1.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                lLongDesc = ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryLongDesc);
                lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
            }
            if(result1.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                lLanguage = result1.values[0].content[0].maincontent.libraryLanguage;
            }
            if(result1.values[0].content[0].maincontent.categoryId !== undefined) { 
                lCategory = result1.values[0].content[0].maincontent.categoryName;
            }
            if(result1.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                lSubCategory = result1.values[0].content[0].maincontent.subcategoryName;
            }
            if(result1.values[0].content[0].createdate !== undefined) {
              cDate = DateTimeFormats(result1.values[0].content[0].createdate._seconds,"datetime-short");
            }
            if(result1.values[0].content[0].maincontent.status !== undefined) { 
              lStatus = result1.values[0].content[0].maincontent.status;
            }
            if(result1.values[0].content[0].maincontent.libraryPicture !== undefined && result1.values[0].content[0].maincontent.libraryPicture !== "") { 
              lPicture = result1.values[0].content[0].maincontent.libraryPicture;
            } else { lPicture = NAimage; }
            if(result1.values[0].content[0].maincontent.libraryVideo !== undefined && result1.values[0].content[0].maincontent.libraryVideo !== "") { 
              lVideo = result1.values[0].content[0].maincontent.libraryVideo;
            }
            if(result1.values[0].scontent2.length > 0) {
              lExam = result1.values[0].scontent2[0].subcontent;
              lExam.docid = result1.values[0].scontent2[0].subdocid;
              if(result1.values[0].scontent2[0].sub2content.length > 0) {
                lQuestion = result1.values[0].scontent2[0].sub2content;
              }
            }
            
            this.setState({
                activelink: currpage,
                stTitle: 'Edit Library',
                library_docid: result1.values[0].docid,
                library_createby: result1.values[0].content[0].maincontent.createByName,
                library_createdate: cDate,
                library_title: lTitle,
                library_short_desc: lShortDesc,
                library_long_desc: lLongDesc,
                library_language: lLanguage,
                library_picture: lPicture,
                library_video: lVideo,
                library_category: lCategory,
                library_subcategory: lSubCategory,
                library_status: lStatus,
                library_lesson_list: result1.values[0].scontent,
                library_exam: lExam,
                library_question_list: lQuestion,
                isOpen: false,
                isOpen2: false,
                isOpen3: false,
                isOpen4: false,
                isOpen5: false,
                isOpen6: false,
                stPopTitle: '',
                stPopTitle2: '',
                stPopTitle3: '',
                stPopTitle4: '',
                stPopTitle5: '',
                stPopTitle6: '',
                otherContent: '',
                otherContent2: '',
                otherContent3: '',
                otherContent4: '',
                otherContent5: '',
                otherContent6: ''
            }) 

          } else {
            window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    // console.log("tabKey:"+tabKey);
    // console.log("tabKey2:"+tabKey2);
    // console.log("docid:"+docId);
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Library" : " Library"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">created by : {this.state.library_createby} <span className="ms-4">on : {this.state.library_createdate}</span></span>
                        <span className="text-muted">status : {this.state.library_status}</span>
                      </div>
                      {/* ==== start - detail library =============== */}
                      <div className="row mt-3 mb-4" id="detail-info-area">
                          <div className="row mt-3">
                            <div className="col-md-10 justify-content-middle fw-bold">Detail Information</div>
                            <div className="col-md-2 d-flex justify-content-end">
                                <div className="linkText"><a href={global.config.urlCms+"/"+this.state.activelink+"/edit/"+this.state.library_docid}><FaPen className="me-1" style={{fontSize:"14pt"}} /><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                            </div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Title</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_title}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Category</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_category} <FaAngleRight className="styleicons" /> {this.state.library_subcategory}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Language</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_language}</div>
                          </div>
                          <div className="row mt-4">
                            <div><h6>Short Description :</h6></div>
                            <div>{parse(this.state.library_short_desc)}</div>
                          </div>
                          <div className="row mt-4">
                            <div><h6>Introduction :</h6></div>
                            <div>{parse(this.state.library_long_desc)}</div>
                          </div>
                          {(this.state.library_picture !== "" && this.state.library_picture !== undefined)?
                          <div className="row mt-3">
                            <div><h6>Picture :</h6></div>
                            <div><img width="150" src={this.state.library_picture} alt=""/></div>
                          </div>
                          : '' }
                          {(this.state.library_video !== "" && this.state.library_video !== undefined)?
                          <div className="row mt-3">
                            <div>
                              <h6>Video Intro :</h6>  
                              <iframe src={this.state.library_video} title={this.state.library_title} do-not-allow="autoplay"></iframe><br/>
                              <a href={this.state.library_video} target="_blank" rel="noreferrer">{this.state.library_video}</a> 
                            </div>
                          </div>
                          : '' }
                      </div>
                      {/* ==== end - detail library =============== */}
                      {/* ==== start - library elemen =============== */}
                      <div className="row mt-3">
                        <div className="accordion" id="accordionTab">
                         {/* === #1  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-1">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                <span className="me-1 lh-base">Modules</span>
                              </button>
                            </h2>
                            <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                    <div id="lessons-area">
                                      <div className="row">
                                          <div className="col-6"></div>
                                          <div className="col-6 d-flex justify-content-end">
                                              <Button variant="dark" size="sm" onClick={this.urlnewLesson.bind(this, this.state.library_docid,'','Add New')}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />add new module</Button>
                                          </div>
                                      </div>
                                      <div className="mt-3">
                                        <table className="table">
                                          <thead className="table-light">
                                              <tr key="0">
                                              <th scope="col text-wrap">#</th>
                                              <th scope="col text-wrap">Subject</th>
                                              <th scope="col text-wrap">Description</th>
                                              <th scope="col text-wrap" style={{width:"100px"}}>Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                             {this.lessonContent(this.state.library_lesson_list)}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>  
                              </div>
                            </div>
                          </div>
                          {/* === #1  */}
                          {/* === #2  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-2">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                <span className="me-1 lh-base">Exam</span>
                              </button>
                            </h2>
                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                    <div id="exam-area">
                                      <div className="row">
                                          <div className="col-6"></div>
                                          <div className="col-6 d-flex justify-content-end">
                                          {
                                              this.state.library_exam.docid ? (
                                                this.state.library_exam.examStatus === 1 ? (
                                                  <div className="me-4"><a onClick={this.urlpublishExam.bind(this, this.state.library_exam.docid, 0)} style={{cursor:"pointer"}}><FaToggleOn className="styleicons" /> {/*<span className="linkText" style={{marginLeft:"-20px", marginRight:"10px"}}>ON </span>*/}</a></div>
                                                ) : (
                                                  <div className="me-4"><a onClick={this.urlpublishExam.bind(this, this.state.library_exam.docid, 1)} style={{cursor:"pointer"}}><FaToggleOff className="styleicons" style={{color:"#999999"}} />{/*<span className="linkText" style={{marginLeft:"-20px", marginRight:"10px"}}> OFF</span>*/}</a></div>
                                                )
                                              ) : ''
                                            }
                                            <div className="linkText"><a onClick={this.urleditExam.bind(this, this.state.library_docid,this.state.library_exam.docid,'Edit')} style={{cursor:"pointer"}}><FaPen className="me-1" style={{fontSize:"14pt"}} /><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                                          </div>
                                      </div>
                                      <div className="mt-3 d-flex">
                                          <div className="col-md-3">Description</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.examDesc}</div>
                                      </div>
                                      <div className="mt-2 d-flex">
                                          <div className="col-md-3">Passing Score</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.passingScore}</div>
                                      </div>
                                      <div className="mt-2 d-flex">
                                          <div className="col-md-3">Num. of question</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.numberQuestion}</div>
                                      </div>
                                  {
                                    this.state.library_exam.docid? 
                                      <>
                                      <div className="mt-4 row">
                                        <div className="col-md-6 fw-bold align-items-center">Question List</div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                          <Button variant="dark" size="sm" onClick={this.urlimportExam.bind(this, this.state.library_docid,this.state.library_exam.docid,'','Import ')} className="me-2">import question</Button>
                                          <Button variant="dark" size="sm" onClick={this.urlnewQuestion.bind(this, this.state.library_docid,this.state.library_exam.docid,'','Add New')}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />add new question</Button>
                                        </div>
                                      </div>
                                      <div className="mt-2">
                                        <table className="table">
                                          <thead className="table-light">
                                              <tr key="0">
                                              <th scope="col text-wrap">#</th>
                                              <th scope="col text-wrap">Question</th>
                                              <th scope="col text-wrap">Type</th>
                                              <th scope="col text-wrap">Answer</th>
                                              <th scope="col text-wrap" style={{width:"100px"}}>Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                           {
                                              this.state.library_question_list.length > 0 ? (    
                                                this.state.library_question_list
                                                .sort((a,b) => a.subsubcontent.questionNumber - b.subsubcontent.questionNumber)
                                                .map((question, i) => {
                                                  return (
                                                    <tr key={"question-" + i}>
                                                      <td>{question.subsubcontent.questionNumber}</td>
                                                      <td>
                                                        {ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',question.subsubcontent.questionText))}
                                                        <ol className="list-alpha">
                                                          {question.subsubcontent.option1 ? <li>{question.subsubcontent.option1}</li> : ''}
                                                          {question.subsubcontent.option2 ? <li>{question.subsubcontent.option2}</li> : ''}
                                                          {question.subsubcontent.option3 ? <li>{question.subsubcontent.option3}</li> : ''}
                                                          {question.subsubcontent.option4 ? <li>{question.subsubcontent.option4}</li> : ''}
                                                          {question.subsubcontent.option5 ? <li>{question.subsubcontent.option5}</li> : ''}
                                                        </ol>
                                                      </td>
                                                      <td>{question.subsubcontent.questionType}</td>
                                                      <td>{question.subsubcontent.questionAnswer}</td>
                                                      <td>            
                                                        <a style={{cursor:"pointer"}} onClick={this.urleditQuestion.bind(this, this.state.library_docid,this.state.library_exam.docid,question,'Edit')}><FaPen className="styleicons" title="edit"/></a>
                                                        {/*<FaTrash className="styleicons"/> */}
                                                      </td>
                                                    </tr>
                                                  )
                                                })
                                              ) : <tr key="0">
                                                    <td></td>
                                                    <td className="d-flex p-3">- no data -</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                      </>
                                    : <></>
                                  }
                                    </div>  
                              </div>
                            </div>
                          </div>
                          {/* === #2  */}
                          
                        </div>

                      </div>
                      {/* ==== end - library elemen =============== */}
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            <div>
                {this.state.isOpen && <Popup
                  content={<><CTLibraryLessonForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen2 && <Popup
                  content={<><CTLibraryAttachForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle2}
                  others={this.state.otherContent2}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen3 && <Popup
                  content={<><CTLibraryAttachEdit/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle3}
                  others={this.state.otherContent3}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen4 && <Popup
                  content={<><CTLibraryExamEdit/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle4}
                  others={this.state.otherContent4}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}
                {this.state.isOpen5 && <Popup
                  content={<><CTLibraryQuestionForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle5}
                  others={this.state.otherContent5}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}  
               {this.state.isOpen6 && <Popup
                  content={<><CTLibraryQuestionImport/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle6}
                  others={this.state.otherContent6}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                />}                  
            </div>
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
