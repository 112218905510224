import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import parse from 'html-react-parser';
import { GetText } from './config/helper';

const authKey = global.config.kindcode.training[20]+global.config.authBearerKey;

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: GetText() ? "Indonesian" : "English",
      category: GetText() ? global.config.faqCategory.IDN : global.config.faqCategory.ENG,
      help: [],
      tabKey: '',
    }
    this.setTab = this.setTab.bind(this);
  }
  setTab() {
    var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);
    if(tabKey==="help") { tabKey = ""; }
    return tabKey ? tabKey : this.state.category[0].value.toLowerCase();
  }
  contentTab(param) {
    
    var contentList = [];
    if(this.state.category.length > 0) {
        for(var p in this.state.category) {
          var contentData = this.state.help.filter((list) =>  {
            return list.content.faqCategory === this.state.category[p].value;
          });
          contentList.push(
            <Tab.Pane eventKey={this.state.category[p].value.toLowerCase()}>

              <div>
                <div className="accordion" id="accordionTab">
                  { 
                    contentData.map((helpContents, i) => {
                      return(
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={"heading-" + i}>
                            <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + i} aria-expanded="false" aria-controls={"collapse-" + i}>
                              <span className="me-1 lh-base">{helpContents.content.faqQuestion}</span>
                            </button>
                          </h2>
                          <div id={"collapse-" + i} className="accordion-collapse collapse" aria-labelledby={"heading-" + i} data-bs-parent="#accordionTab">
                            <div className="accordion-body">
                            {parse(helpContents.content.faqAnswer)}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>  
              </div>
            </Tab.Pane>
            )
        }
    }
    return contentList;
    
  }
  contentNavLeft(param) {
    var categoryList = [];
    if(this.state.category.length > 0) {
        for(var r in this.state.category) {
          categoryList.push(<Nav.Item><Nav.Link eventKey={this.state.category[r].value.toLowerCase()}>{this.state.category[r].label}</Nav.Link></Nav.Item>)
        }
    }
    return categoryList;
  }
  componentDidMount() {
      var datatext ='{"keyword":"","pageShow":"","lang":"'+this.state.language+'","form_id":"pubonly"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/faqlist", {
          method: 'POST',
          //mode: 'no-cors', 
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
          var resultList = resJson.values.sort((a, b) => a.content.faqNumber - b.content.faqNumber);
          this.setState({
            help: resultList,
            tabKey: this.state.category[0].value.toLowerCase()
           })
      }).catch(err => {
          
      });
  }
  render() {
    return (
      <div className="help">
        <Container>
          <h1 className="mb-4">{GetText() ? "Bantuan" : "Help"}</h1>
          <div>
        {this.state.tabKey !== "" && 
          <Tab.Container defaultActiveKey={this.setTab}>
            <Row className="mb-5">
              <Col sm={3}>
                  <Nav variant="pills" className="flex-column pills-scroll mt-4 mb-5 mb-md-0">
                    {this.contentNavLeft(this.state.language)}
                  </Nav>
              </Col>
              <Col sm={1}></Col>
              <Col sm={8} className="p-4">
                <div >
                {
                this.state.help.length > 0? 
                  <Tab.Content>
                    {this.contentTab(this.state.language)}
                  </Tab.Content>
                   : <></>
                }
                </div>
              </Col>
            </Row>
          </Tab.Container>
        }
          </div>
          
        </Container>
      </div>
    );
  }
}