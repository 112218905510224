import React, { Component } from "react";
import { GetText } from '../config/helper';
import { Nav } from 'react-bootstrap';

const arrMenu = [
	{link: "", nameEn: "Dashboard", nameId: "Dashboard" },
	{link: "ctcategory", nameEn: "Category", nameId: "Kategori" },
	{link: "ctlibrary", nameEn: "Library", nameId: "Library" },
	{link: "ctcourse", nameEn: "Workshop", nameId: "Workshop" },
	{link: "cttutor", nameEn: "Trainers", nameId: "Pelatih" },
	{link: "ctfaqcourse", nameEn: "FAQ in Workshop", nameId: "FAQ di Workshop" },
	{link: "ctusertraining", nameEn: "Users", nameId: "Pengguna" },
	{link: "ctcompanytraining", nameEn: "Companies", nameId: "Perusahaan" },
	{link: "ctfeedback", nameEn: "Feedback", nameId: "Umpan Balik" },
	{link: "ctvoucher", nameEn: "Voucher", nameId: "Voucher" },
	{link: "ctcontactus", nameEn: "Contact Us", nameId: "Hubungi Kami" },
	{link: "ctfaq", nameEn: "FAQ in Novaio", nameId: "FAQ di Novaio" },
];

export default class MenuLeftCMS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activetab: "",
		}
	}
	componentDidMount() {
		var content = this.props.value;
		console.log(content);
		this.setState({
			activetab: content.tabkey
		});
	}

	openLink(destination) {
		window.location.href='/c/'+destination;
	}
	
	render() {
		
		return (
			<div>
				<div className="mt-4"><h3 className="mb-4 mx-3">{GetText() ? "Pengaturan" : "Settings"}</h3></div>
				<div>
					<Nav variant="pills" className="flex-column pills-scroll mt-5 mb-5 mb-md-0" defaultActiveKey={this.state.activetab}>
					{ 
						arrMenu.map((contentMenu, i) => {
							return(
								<Nav.Item>
									<Nav.Link 
										eventKey={contentMenu.link} 
									 	onClick={() => this.openLink(contentMenu.link)}
										 active={(this.state.activetab === contentMenu.link)? "true" : ""}
									>
										{GetText() ? contentMenu.nameId : contentMenu.nameEn}
									</Nav.Link>
								</Nav.Item>
							)
						})
					}
						
						
					</Nav>
				</div>
				
			</div>
		);
	}
}
