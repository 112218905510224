import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaRightLong, FaCircleCheck } from 'react-icons/fa6';
import { GetText } from './config/helper';
import ReplaceAllContent from "./config/replace-all";
import { FormErrors } from './inc/error-form';

const authKey = global.config.kindcode.training[19]+global.config.authBearerKey; 

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_id: localStorage.getItem('doc_id'),
      member_email: '',
      contactId: '',
      contactEmail: "",
      contactMessage: "",
      contactName: "",
      contactPhone: "",
      formErrors: {
        contactName: "",
        contactEmail: "",
        contactMessage: "",
        contactPhone: "",
      },
      contactsnameValid: false,
      contactsemailValid: false,
      contactsmessageValid: false,
      contactsphoneValid: false,
      formValid: false,
      input_disabled: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let contactsnameValid = this.state.contactsnameValid;
    let contactsemailValid = this.state.contactsemailValid;
    let contactsphoneValid = this.state.contactsphoneValid;
    let contactsmessageValid = this.state.contactsmessageValid;
    switch (fieldName) {
      case "contactName":
        contactsnameValid = value.trim().length > 0;
        fieldValidationErrors.contactName = contactsnameValid ? "" : GetText() ?  "Nama wajib diisi" : "Name needs to be filled";
        break;
      case "contactEmail":
        contactsemailValid = value.trim().length > 0;
        fieldValidationErrors.contactEmail = 
          contactsemailValid ? 
            value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)? "" : GetText() ?  "Email tidak valid" : "Email is invalid"
          : GetText() ?  "Email wajib diisi" : "Email needs to be filled";
        break;
      case "contactPhone":
        contactsphoneValid = value.trim().length > 0;
        fieldValidationErrors.contactPhone = contactsphoneValid? "" : GetText() ?  "Nomor telepon wajib diisi" : "Phone number needs to be filled";
        break;
      case "contactMessage":
        contactsmessageValid = value.trim().length > 0;
        fieldValidationErrors.contactMessage = contactsmessageValid? "" : GetText() ?  "Pesan wajib diisi" : "Message needs to be filled";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        contactsnameValid: contactsnameValid,
        contactsemailValid: contactsemailValid,
        contactsphoneValid: contactsphoneValid,
        contactsmessageValid: contactsmessageValid,
      },
      this.validateForm
    );

  }
  validateForm() {
    this.setState({
      formValid: this.state.contactsmessageValid && this.state.contactsnameValid && this.state.contactsphoneValid && this.state.contactsemailValid,
    });
  }

  
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.contactName !== "" && this.state.contactEmail !== "" && this.state.contactMessage !== "") {
      document.getElementById('submit').innerText = 'Loading...';
      document.getElementById('submit').setAttribute('disabled', 'true');
      this.setState({
        input_disabled: true
      });
      var urlFetch, datatext;
      var contactName, contactEmail,  contactPhone, contactMessage;
      if(this.textInputContactName.value === '') { contactName = this.state.contactName; } else { contactName = this.textInputContactName.value; }
      if(this.textInputContactEmail.value === '') { contactEmail = this.state.contactEmail; } else { contactEmail = this.textInputContactEmail.value; }
      if(this.textInputContactPhone.value === '') { contactPhone = this.state.contactPhone; } else { contactPhone = this.textInputContactPhone.value; }
      if(this.textInputContactMessage.value === '') { contactMessage = this.state.contactMessage; } else { contactMessage = this.textInputContactMessage.value; }
      contactMessage = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',ReplaceAllContent("'","`",contactMessage)));

      urlFetch = global.config.urlLink+"/contactusadd";
      datatext ='{"contact_name":"'+contactName+'","contact_email":"'+contactEmail+'","contact_phone":"'+contactPhone+'","contact_message":"'+contactMessage+'","form_id":"form-add"}';
      var data = JSON.parse(datatext);
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          document.getElementById('submit').innerText = (GetText() ? "Submit" : "Submit");
          document.getElementById('submit').removeAttribute('disabled');
              
          if(resJson.status === '200') {
              var docId = resJson.values;
              this.setState({
                contactId: resJson.values,
                input_disabled: false
              });
          } else {
              this.setState({
                input_disabled: false
              });
              console.log('Something happened wrong');
          }
  
      }).catch(err => { 
          this.setState({
            input_disabled: false
          });
          document.getElementById('submit').innerText = (GetText() ? "Submit" : "Submit");
          document.getElementById('submit').removeAttribute('disabled');
          
          // document.getElementById('loading-area').style.display = 'none'; 
      });

    } else {
        var arrfieldName = ['contactName','contactEmail','contactMessage'];
        var arrvalue = [this.textInputContactName.value,this.textInputContactEmail.value,this.textInputContactMessage.value];
        for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }

  componentDidMount() {
    this.setState({
      contactId: "",
      contactEmail: "",
      contactMessage: "",
      contactName: "",
      contactPhone: "",
      input_disabled: false
    }) 
  }
  render() {
    return (
      <div className="contactus">
        <Container>
          <h1 className="mb-4">{GetText() ? "Hubungi Kami" : "Contact Us"}</h1>
          <div className="row">
            <div className="col-md-4">
              <br />
              <p className="text-description-contactus">
                {GetText()
                  ? "Punya pertanyaan atau butuh bantuan? Hubungi tim kami dan kami akan dengan senang hati membantu Anda dengan pertanyaan atau dukungan apa pun yang Anda butuhkan."
                  : "Have questions or need assistance? Reach out to our team and we'll be happy to help you with any inquiries or support you need."}
              </p>
              <div className="separator"><div className="icon"><FaRightLong/></div></div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-6 ">
              {
                this.state.contactId !== "" ? (
                    <div className="bg-body rounded shadow p-4 mb-5  w-100 d-flex justify-content-center"> 
                        <div className="row">
                          <div className="col-lg-2 d-flex align-items-center justify-content-center">
                            <FaCircleCheck color="green" fontSize="60px" className="mb-3"/>
                          </div>
                          <div className="col-lg-10">
                            <div  className="mt-1 ms-2">{GetText()? "Terima kasih, kami telah menerima pesan Anda." : "Thank you, we have received your message."}</div>
                            <div  className="mt-2 ms-2">{GetText()? "Tim Customer Care Novaio Academy akan segera menghubungi dan membalas pesan Anda pada hari dan jam kerja" : "Customer care Novaio Academy will contact and reply your message at working hours"} </div>
                            <div  className="ms-2">{GetText()? "( Senin - Jumat, dari pukul 09.00 s/d 16.00 )" : "( Monday to Friday at 9 am `till 4 pm )"} </div>
                          </div>
                        </div>
                  </div>  
                ) : (
                <form onSubmit={this.handleSubmit}>
                    <div className="mt-3">
                        <label htmlFor="contactName">{GetText()? "Nama" : "Name"}</label>
                        <div className="mt-1">
                            <input type="text" className="form-control" name="contactName"
                                placeholder={GetText()? "masukkan nama anda" : "input your name"}
                                value={this.state.contactName}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputContactName = input}
                                disabled={this.state.input_disabled}
                                autoFocus
                                />
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.contactName}/>  }</div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="contactEmail">Email</label>
                        <div className="mt-1">
                            <input type="text" className="form-control" name="contactEmail"
                                placeholder={GetText()? "masukkan email anda" : "input your email"}
                                value={this.state.contactEmail}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputContactEmail = input}
                                disabled={this.state.input_disabled}
                                />
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.contactEmail}/>  }</div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <label htmlFor="contactPhone">{GetText()? "Nomor Telepon" : "Phone Number"}</label>
                        <div className="mt-1">
                            <input type="text" className="form-control" name="contactPhone"
                                placeholder={GetText()? "masukkan nomor telepon anda" : "input your phone number"}
                                value={this.state.contactPhone}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputContactPhone = input}
                                disabled={this.state.input_disabled}
                                />
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.contactPhone}/>  }</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <label htmlFor="contactMessage">{GetText()? "Pesan" : "Message"}</label>
                        <div className="mt-1">
                            <textarea name="contactMessage" className="form-control" 
                                    rows={5} 
                                    placeholder={GetText()? "masukkan pesan anda" : "input your message"}
                                    value={this.state.contactMessage} 
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputContactMessage= input}
                                    disabled={this.state.input_disabled}
                                    maxlength="350"/>
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.contactMessage}/>  }</div>
                            
                        </div>
                    </div>
                   
                    <div className="mt-4 mb-3">
                        <Button variant="primary" id="submit" type="submit" style={{ width: "100%", marginTop: "20px" }}>Submit</Button>
                    </div>
                </form>
                )
              }
            </div>
          </div>
        </Container>
      </div>
    );
  }
}