import React, { Component } from "react";
import { Container } from "react-bootstrap";
import parse from 'html-react-parser';
import { FaFileCircleCheck, FaFileCircleMinus} from "react-icons/fa6";

import { GetText } from "../config/helper";
import ReplaceAllContent from "../config/replace-all";
import DateTimeFormats from '../config/date-time-format';
import "../config/global";
import MenuLeftCMS from "./ct-fragmentsidemenu";
import CTNA from "./ct-fragmentna";

const authKey = global.config.kindcode.training[19]+global.config.authBearerKey;

export default class CTContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink: "none",
      pageShow: "10",
      keyword: "",
      data: [],
      dataOri: [],
      selectStatus: 0
    };
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  handlePublish(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block';
    var datatext ='{"contact_status":"'+nextstat+'","contact_id":"'+valId+'","form_id":"form-publish"}';
    var urlFetch = global.config.urlLink+"/contactusedit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            window.location.href=global.config.urlCms+"/"+this.state.activelink;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  handleSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "select_statfu") { 
        var result = this.state.dataOri;
        if(value !== "") {
          result = result.filter((list) =>  {
            return list.content.flagFU === Number(value);
          });
        }
        this.setState( prevState => {
            return {  
                ...prevState.selectStatus, selectStatus: value,
                ...prevState.data, data: result
            }
        })
    } 
  }

  loadContent = (keyword,pageshow) => {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pageshow+'"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/contactuslist", {
          method: 'POST',
          //mode: 'no-cors', 
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
          if(resJson.status === '200') {
            resolve(resJson.values);
          } else {
            resolve({"status": "500"});  
          }
          
      }).catch(err => {
          console.log(err);
          resolve({"status": "500"});
          
      });
    });
  }

  async loadData () {
    var [result] = await Promise.all([this.loadContent(this.state.keyword,this.state.pageShow)]);
    this.setState({
            data: result,
            dataOri: result
        })
  }

  componentDidMount() {
    var url = window.location.href;
    var arrUrl = url.split("/");
    var tabKey = arrUrl[arrUrl.length - 1];
    if (tabKey === "") {
      tabKey = arrUrl[arrUrl.length - 2];
    }
    this.setState({
      activelink: tabKey,
    });
    this.loadData();
  }

contentList() {
  var messageList = [];
  if(this.state.data.length > 0) {
    this.state.data.sort((a, b) => a.content.createDate._seconds < b.content.createDate._seconds);
    for(var ls=0; ls < this.state.data.length; ls++) {
        var retResult = this.state.data[ls];
        // var editURL = global.config.urlCms+"/"+this.state.activelink+"/edit/"+retResult.docid;
        // var viewURL = global.config.urlCms+"/"+this.state.activelink+"/view/"+retResult.docid;
        var iconFU = <FaFileCircleMinus className="styleicons" onClick={this.handlePublish.bind(this, retResult.docid, 1)}/>;
        var statFU = "Need FU";
        if(retResult.content.flagFU !== undefined && retResult.content.flagFU === 1) { 
          iconFU =  <FaFileCircleCheck className="styleicons" style={{color:"#999999"}}  onClick={this.handlePublish.bind(this, retResult.docid, retResult.content, 0)}/> 
          statFU = "Done FU";
        }

        messageList.push(<tr key={retResult.docid}>
            <th scope="row-field">{ls+1}</th>
            <td>{DateTimeFormats(retResult.content.createDate._seconds,"datetime-short")}</td>
            <td>
              <span className="fw-bold">{retResult.content.contactName}</span>
              {(retResult.content.contactEmail !== undefined && retResult.content.contactEmail !== "")? (<><br/><span className="text-muted">Email : {retResult.content.contactEmail}</span></>) : <></>}
              {(retResult.content.contactPhone !== undefined && retResult.content.contactPhone !== "")? (<><br/><span className="text-muted">Phone Number : {retResult.content.contactPhone}</span></>) : <></>}
            </td>
            <td>{parse(ReplaceAllContent(',','[;]',retResult.content.contactMessage))}</td>
            <td>{statFU}</td>
            <td>
              {/* <a href={editURL}><FaPen className="styleicons" title="edit" /></a>
              <a href={viewURL}><FaFileLines className="styleicons" title="view" /></a> */}
              {iconFU}
            </td>
          </tr>)
    }
  } else {
    messageList.push(<tr key="1">
        <th scope="row"></th>
        <td colspan={2} className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>)
  }
  
  return(messageList)
  
}  
render() {
  return (
    <div className="setting">
     <Container>
      {
        (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
        (<>
          <div className="row py-5 mb-5">
            {/* === start - body */}
              <div className="col-md-3 bg-light">
                { 
                  this.state.activelink !== "none" && 
                    <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                
                }
              </div>
              <div className="col-md-9 p-4">
                <div className="row">
                  <div>
                    <h3>{GetText() ? "Hubungi Kami" : "Contact Us"}</h3>
                    <span className="text-muted">{GetText() ? "Daftar Pesan dari User" : "List of Message from Users"}</span>
                  </div>
                  <div className="mt-3">
                      <div className="row show">
                        <div className="col-6">
                                <select name="select_statfu" className="form-control"
                                      value={this.state.selectStatus} 
                                      onChange={this.handleSelect}
                                      ref={(input) => this.textInputStatus = input}
                                  >
                                  <option value="">-- All  --</option>
                                  <option value="0">Need FU</option>
                                  <option value="1">Done FU</option>
                              </select>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            
                        </div>
                    </div>
                    <div className="mt-3">
                        <table className="table">
                          <thead className="table-dark">
                            <tr key="0">
                            <th width="50" className="text-center" scope="col">#</th>
                            <th width="110" scope="col">Submit Date</th>
                            <th width="250" scope="col">Contact Info</th>
                            <th scope="col">Message</th>
                            <th width="80" scope="col">Status</th>
                            <th width="50" scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.contentList()}
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            {/* === end - body */}
          </div>
          
          </>
        )
      : 
        <div><CTNA/></div>
      }
      <div id="loading-area" className="d-none loading"></div> 
      </Container>
    </div>
  );
}
}
