import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Button } from 'react-bootstrap';
import { FaPlus, FaTrash, FaPen, FaAngleRight, FaRegFilePdf } from "react-icons/fa6";
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';
import DateTimeFormats from '../config/date-time-format';
import NumberFormats from '../config/number-format';
import NAimage from '../img/pic-na.png';
import Popup from '../inc/PopUp';
import CTCourseAgendaForm from './ct-course-pop-agenda-form';
import CTCourseMateriForm from './ct-course-pop-materi-form';


const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;

export default class CTCourseDetailEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      course_docid: '',
      course_createby: '',
      course_createdate: '',
      course_title: '',
      course_type: '',
      course_venue: '',
      course_venue_address: '',
      course_venue_maps: '',
      course_meet_link: '',
      course_short_desc: '',
      course_long_desc: '',
      course_language: '',
      course_currency: '',
      course_price: '',
      course_date: '',
      course_date2: '',
      course_start_time: '',
      course_end_time: '',
      course_picture: '',
      course_video: '',
      course_recording: {},
      course_document: {},
      course_category: '',
      course_subcategory: '',
      course_status: '',
      course_tutor: '',
      course_agenda_list: [],
      course_checkin_list: [],
      isOpen: false,
      isOpen2: false,
      stPopTitle: '',
      stPopTitle2: '',
      otherContent: '',
      otherContent2: '',
    }
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.agendaContent = this.agendaContent.bind(this);
  }
  handleBack = (currpage) => {
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
        ...prevState.isOpen2, isOpen2: false,
      }
    })
  }
  urleditMateri(valId,action) {
    var sendContent = {
      "courseId": valId,
      "courseTitle": this.state.course_title,
      "courseDate": this.state.course_date2,
      "courseRecording": this.state.course_recording,
      "courseDocument": this.state.course_document,
      "activelink": this.state.activelink
    }
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.stPopTitle2, stPopTitle2: action+" Materi Workshop",
          ...prevState.otherContent2, otherContent2 : JSON.stringify(sendContent)
      }
    })
  }
  urlnewAgenda(valId,subvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.stPopTitle, stPopTitle: action+" Agenda",
          ...prevState.otherContent, otherContent : '{"courseId":"'+valId+'","courseTitle":"'+this.state.course_title+'","courseDate":"'+this.state.course_date2+'","crsagendaid":"'+subvalId+'"}'
      }
    })
  }

  urldeleteAgenda(valId,subvalId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/courseagendadelete";
      var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","form_id":"delete-course-agenda"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
          } else {
              console.log('Something happened wrong delete');
          }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      });
    }
  }

  agendaContent = (dataAgenda) => {
    var agendaList = [];
    if(dataAgenda.length > 0) {
      dataAgenda.sort((a, b) => a.subcontent.agendaTime._seconds - b.subcontent.agendaTime._seconds);
      for(var ls=0; ls < dataAgenda.length; ls++) {
        var retResult3 = dataAgenda[ls];
        var trcls = '';
        var time = DateTimeFormats(retResult3.subcontent.agendaTime._seconds,"time-24");
        agendaList.push(
          <tr key={ls+1} className={trcls}>
            <th scope="row">{time}</th>
            <td>{retResult3.subcontent.agendaDuration + " minutes"}</td>
            <td>
              <span className="fw-bold">Subject:</span><br/>
              {retResult3.subcontent.agendaTitle}<br/><br/>
              <span className="fw-bold">Description:</span><br/>
              {parse(retResult3.subcontent.agendaDesc)}<br/><br/>
              
            </td>
            <td>
              <div className="d-flex">
                <FaPen className="styleicons" title="edit" onClick={this.urlnewAgenda.bind(this, this.state.course_docid,retResult3.subdocid,'Edit')}/>
                <FaTrash className="styleicons" onClick={this.urldeleteAgenda.bind(this, this.state.course_docid, retResult3.subdocid)}/> 
              </div>
            </td>
          </tr>
        );
      }
    } else {
      agendaList.push(<tr key="0">
        <td></td>
        <td className="d-flex p-3">- no data -</td>
        <td></td>
        <td></td>
      </tr>)
    }
    return agendaList;
  }
  
  loadContent = (docid) => {
    return new Promise((resolve) => {
      fetch(global.config.urlLink+"/courseview/"+docid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        resolve(resJson);
      })
    })
  }
  async loadData(currpage,typelink,docid) {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1] = await Promise.all([this.loadContent(docid)]);
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {

            var lTitle="",lDate="",lType="",lVenue="", lVenueAddress="", lVenueMaps="", lMeetLink="",lShortDesc="",lLongDesc="",lLanguage="",lCategory="",lSubCategory="",lTutor="",lPriceCurrency="",lPrice="",crsDate="",crsStartTime="",crsEndTime="",lPicture="",lVideo="",lRecording={},lDocument={},cDate="",lStatus="";

            var resultContent = result1.values[0].content[0].maincontent;
            if(resultContent.courseTitle !== undefined) {  lTitle = resultContent.courseTitle; }
            if(resultContent.courseType !== undefined) { lType = resultContent.courseType; }
            if(resultContent.courseMeetLink !== undefined) { lMeetLink = resultContent.courseMeetLink; }
            if(resultContent.courseLanguage !== undefined) { lLanguage = resultContent.courseLanguage; }
            if(resultContent.categoryId !== undefined) { lCategory = resultContent.categoryName; }
            if(resultContent.subcategoryId !== undefined) { lSubCategory = resultContent.subcategoryName; }
            if(resultContent.courseVenueMaps !== undefined) { lVenueMaps = resultContent.courseVenueMaps; }

            if(resultContent.courseShortDesc !== undefined) {
                lShortDesc = ReplaceAllContent('[;]',',',resultContent.courseShortDesc);
            }
            if(resultContent.courseLongDesc !== undefined) { 
                lLongDesc = ReplaceAllContent('[;]',',',resultContent.courseLongDesc);
            }
            if(resultContent.courseVenue !== undefined) {
              lVenue = ReplaceAllContent('[;]',',',resultContent.courseVenue);
            }
            if(resultContent.courseVenueAddress !== undefined) { 
              lVenueAddress = ReplaceAllContent('[;]',',',resultContent.courseVenueAddress);
            }
            if(resultContent.tutorId !== undefined) { 
                var tutorNames = resultContent.tutorName.split(',');
                lTutor = tutorNames.join(', ');
            }
            if(result1.values[0].content[0].createdate !== undefined) {
              cDate = DateTimeFormats(result1.values[0].content[0].createdate._seconds,"datetime-short");
            }
            if(resultContent.coursePicture !== undefined && resultContent.coursePicture !== "") { 
              lPicture = resultContent.coursePicture;
            } else { lPicture = NAimage; }
            if(resultContent.courseVideo !== undefined && resultContent.courseVideo !== "") { 
              lVideo = resultContent.courseVideo;
            }
            if(resultContent.courseRecording !== undefined && resultContent.courseRecording !== "") { 
              lRecording = resultContent.courseRecording;
            }
            if(resultContent.courseDocument !== undefined && resultContent.courseDocument !== "") { 
              lDocument = resultContent.courseDocument;
            }
            if(resultContent.courseCurrency !== undefined && resultContent.courseCurrency !== "") { 
              lPriceCurrency = resultContent.courseCurrency;
            }
            if(resultContent.coursePrice !== undefined && resultContent.coursePrice !== "") { 
              lPrice = NumberFormats(resultContent.coursePrice);
            }
            if(resultContent.courseDate !== undefined && resultContent.courseDate !== "") { 
              lDate = resultContent.courseDate._seconds*1000;
              crsDate = DateTimeFormats(resultContent.courseDate._seconds,"date-long");
            }
            if(resultContent.courseStartTime !== undefined && resultContent.courseStartTime !== "") { 
              crsStartTime = DateTimeFormats(resultContent.courseStartTime._seconds,"time-24");
            }
            if(resultContent.courseEndTime !== undefined && resultContent.courseEndTime !== "") { 
              crsEndTime = DateTimeFormats(resultContent.courseEndTime._seconds,"time-24");
            }
            if(result1.values[0].content[0].maincontent.status !== undefined) { 
              lStatus = result1.values[0].content[0].maincontent.status;
            }
            this.setState({
                activelink: currpage,
                stTitle: 'Edit Workshop',
                course_docid: result1.values[0].docid,
                course_createby: result1.values[0].content[0].maincontent.createByName,
                course_createdate: cDate,
                course_title: lTitle,
                course_type: lType,
                course_venue: lVenue,
                course_venue_address: lVenueAddress,
                course_venue_maps: lVenueMaps,
                course_meet_link: lMeetLink,
                course_short_desc: lShortDesc,
                course_long_desc: lLongDesc,
                course_language: lLanguage,
                course_currency: lPriceCurrency,
                course_price: lPrice,
                course_date: crsDate,
                course_date2: lDate,
                course_start_time: crsStartTime,
                course_end_time: crsEndTime,
                course_picture: lPicture,
                course_video: lVideo,
                course_recording: lRecording,
                course_document: lDocument,
                course_category: lCategory,
                course_subcategory: lSubCategory,
                course_tutor: lTutor,
                course_agenda_list: result1.values[0].scontent,
                course_checkin_list: result1.values[0].scontent2,
                course_status: lStatus,
                isOpen: false,
                isOpen2: false,
                stPopTitle: '',
                stPopTitle2: '',
                otherContent: '',
                otherContent2: '',
            })

          } else {
            window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Workshop" : " Workshop"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">created by : {this.state.course_createby} <span className="ms-4">on : {this.state.course_createdate}</span></span>
                        <span className="text-muted">status : {this.state.course_status}</span>
                      </div>
                      {/* ==== start - detail course =============== */}
                      <div className="row mt-3 mb-4" id="detail-info-area">
                          <div className="row mt-3">
                            <div className="col-md-8 justify-content-middle fw-bold">Detail Information</div>
                            <div className="col-md-4 d-flex justify-content-end">
                                <div className="linkText me-4"><a onClick={this.urleditMateri.bind(this, this.state.course_docid,'Edit ')}><FaPen className="me-1" style={{fontSize:"14pt"}} /><span style={{marginTop:"3px"}} className="linkText">Edit Materi Workshop &raquo;</span></a></div>
                                {this.state.course_checkin_list.length < 1 && <div className="linkText"><a href={global.config.urlCms+"/"+this.state.activelink+"/edit/"+this.state.course_docid}><FaPen className="me-1" style={{fontSize:"14pt", color: "#666666"}} /><span style={{marginTop:"3px"}} className="linkText">Edit Info &raquo;</span></a></div> }
                            </div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Title</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.course_title}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Category</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.course_category} <FaAngleRight className="styleicons" /> {this.state.course_subcategory}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Price</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.course_currency} {this.state.course_price}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Language</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.course_language}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Tutor</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.course_tutor}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Schedule</h6></div>
                              <div className="col-md-9">
                                <span className="me-1">:</span>{this.state.course_date}<br/>
                                <span className="me-1">&nbsp;</span>Start at : {this.state.course_start_time} <br/>
                                <span className="me-1">&nbsp;</span>End at : {this.state.course_end_time} <br/>
                              </div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Location</h6></div>
                              <div className="col-md-9">
                                <span className="me-1">:</span>{this.state.course_type}<br/>
                                {(this.state.course_venue !== undefined && this.state.course_venue !== "") && 
                                  <p className="ms-2 mt-2">
                                    Venue Address : <br/>
                                    {parse(this.state.course_venue_address) }<br/>
                                    Venue Location : {this.state.course_venue}
                                    {this.state.course_venue_maps !== "" && <><br/><Button onClick={() => window.open(this.state.course_venue_maps,"_blank")} variant="secondary" size="sm" style={{marginTop:"5px"}}>view maps &raquo;</Button> </>}
                                  </p>
                                }
                                {(this.state.course_meet_link !== undefined && this.state.course_meet_link !== "") && 
                                  <p className="ms-2 mt-2">Google Meet Link : <a href={this.state.course_meet_link} target="_blank" rel="noreferrer">{this.state.course_meet_link}</a></p>
                                }
                                
                              </div>
                          </div>
                          
                          <div className="row mt-4">
                            <div><h6>Short Description :</h6></div>
                            <div>{parse(this.state.course_short_desc)}</div>
                          </div>
                          <div className="row mt-4">
                            <div><h6>Full Description :</h6></div>
                            <div>{parse(this.state.course_long_desc)}</div>
                          </div>
                          
                          {(this.state.course_picture !== "" && this.state.course_picture !== undefined)?
                          <div className="row mt-4">
                            <div><h6>Picture :</h6></div>
                            <div><img width="150" src={this.state.course_picture} alt=""/></div>
                          </div>
                          : '' }
                          {(this.state.course_video !== "" && this.state.course_video !== undefined)?
                          <div className="row mt-4">
                            <div>
                              <h6>Video Intro :</h6>  
                              <iframe src={this.state.course_video} title={this.state.course_title} do-not-allow="autoplay"></iframe><br/>
                              <a href={this.state.course_video} target="_blank" rel="noreferrer">{this.state.course_video}</a> 
                            </div>
                          </div>
                          : '' }
                          {(this.state.course_recording !== "" && this.state.course_recording !== undefined && Object.keys(this.state.course_recording).length > 0)?
                          <div className="row mt-4">
                            <div>
                              <h6>Video Recording :</h6>  
                              <iframe src={this.state.course_recording.fileUrl} title={this.state.course_title} allow="autoplay"></iframe><br/>
                              <a href={this.state.course_recording.fileUrl} target="_blank" rel="noreferrer">{this.state.course_recording.filename}</a> 
                            </div>
                          </div>
                          : '' }
                          {(this.state.course_document !== "" && this.state.course_document !== undefined && Object.keys(this.state.course_document).length > 0)?
                          <div className="row mt-4">
                              <div className="col-md-3"><h6>Materi Workshop</h6></div>
                              <div className="col-md-9">: <a href={this.state.course_document.fileUrl} target="_blank" rel="noreferrer"><FaRegFilePdf className="styleicons"/> {this.state.course_document.filename}</a></div>
                          </div>
                          : '' }
                      </div>
                      {/* ==== end - detail course =============== */}
                      {/* ==== start - course elemen =============== */}
                      <div className="row mt-3">
                        <div className="accordion" id="accordionTab">
                         {/* === #1  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-1">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                <span className="me-1 lh-base">Agenda</span>
                              </button>
                            </h2>
                            <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                    <div id="agenda-area">
                                      <div className="row">
                                          <div className="col-6"></div>
                                          <div className="col-6 d-flex justify-content-end">
                                              <Button variant="dark" size="sm" onClick={this.urlnewAgenda.bind(this, this.state.course_docid,'','Add New')}><FaPlus style={{color:"#FFFFFF", size: "20px"}} className="me-2" />add new aagenda</Button>
                                          </div>
                                      </div>
                                      <div className="mt-3">
                                        <table className="table">
                                          <thead className="table-light">
                                              <tr key="0">
                                                <th scope="col text-wrap" width="80">Time</th>
                                                <th scope="col text-wrap" width="120">Duration</th>
                                                <th scope="col text-wrap">Content</th>
                                                <th scope="col text-wrap" width="80">Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                             {this.agendaContent(this.state.course_agenda_list)}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>  
                              </div>
                            </div>
                          </div>
                          {/* === #1  */}
                         
                        </div>

                      </div>
                      {/* ==== end - course elemen =============== */}
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            <div>
            { 
                this.state.isOpen && <Popup
                content={<><CTCourseAgendaForm/></>}
                id={this.state.course_id} 
                title={this.state.stPopTitle}
                others={this.state.otherContent}
                styles={"70%"}
                handleClose={this.togglePopClose.bind(this)}
                />
              }    
              {
                this.state.isOpen2 && <Popup
                content={<><CTCourseMateriForm/></>}
                id={this.state.course_id} 
                title={this.state.stPopTitle2}
                others={this.state.otherContent2}
                styles={"70%"}
                handleClose={this.togglePopClose.bind(this)}
                />
              }                
            </div>
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
