import React, { Component } from 'react';
import axios from 'axios';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class CTUserTrainingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      defTabIndex: "",
      usageFor: '',
      stTitle: '',
      user_docid: '',
      full_name: '',
      email: '',
      phone: '',
      language: '',
      picture_url: '',
      expiry_date: new Date(),
      file_url: '',
      selectedFileDocument: null,
      
      formErrors: { full_name: '', email: '', phone: '', language: '', picture_url: '', expiry_date: '', file_url: '', selectedFileDocument: '' },
      formValid: false,
      fullNameValid: '',
      emailValid: '',
      phoneValid: '',
      languageValid: '',
      pictureUrlValid: '',
      expiryDateValid: '',
      fileUrlValid: '',
      selectedFileDocumentValid: '',
      
    }
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmitBulk = this.handleSubmitBulk.bind(this);
    this.checkBackground = this.checkBackground.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  handleSelectTab(idx) {
    var used = "bulk";
    if(idx === 1) { used = "single"; }
    else if(idx===2) { used = "picker"; }
    this.setState( prevState => {
      return {  
        ...prevState.usageFor, usageFor: used
      }
    })
  }
  
  handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value
    }, () => { 
      this.validateField(name, value);
    });
  }

  handleInputSelect = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState.language, language: value,
      }
    }, () => { 
      this.validateField(name, value);
    });
  }

  handleInputDate = date => {
    this.setState(prevState => {
      return {  
        ...prevState.expiry_date, expiry_date: date
      }
    }, () => { 
      this.validateField('expiry_date', date);
    }); 
  }

  handleLinkClick() {    
    var url = 'https://docs.google.com/spreadsheets/d/1dTBasBfLAEF4tFE4X_xGZsDyLoQydIw7cwAJebJeZ2M/edit?usp=sharing';
    window.open(url, '_blank', 'noreferrer');
  }
  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fullNameValid = this.state.fullNameValid;
    let emailValid = this.state.emailValid;
    let phoneValid = this.state.phoneValid;
    let languageValid = this.state.languageValid; 
    let pictureUrlValid = this.state.pictureUrlValid; 
    let expiryDateValid = this.state.expiryDateValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;

    switch(fieldName) {
      case 'full_name': 
        fullNameValid = value.trim().length > 0;
        fieldValidationErrors.full_name = fullNameValid ? '' : 'Full name need to be filled';
        break;
      case 'email': 
        emailValid = value.trim().length > 0;
        fieldValidationErrors.email = emailValid ? '' : 'Email need to be filled';
        break;
      case 'expiry_date': 
        expiryDateValid = value !== null;
        fieldValidationErrors.expiry_date = expiryDateValid ? '' : 'Expiry Date need to be filled';
        break;
      default:
        break;
    }
    
    this.setState({
      formErrors: fieldValidationErrors,
      fullNameValid: fullNameValid,
      emailValid: emailValid,
      phoneValid: phoneValid,
      languageValid: languageValid,
      pictureUrlValid: pictureUrlValid,
      expiryDateValid: expiryDateValid,
      selectedFileDocumentValid: selectedFileDocumentValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.fullNameValid && this.state.emailValid && this.state.phoneValid && this.state.languageValid && this.state.pictureUrlValid && this.state.expiryDateValid && this.state.selectedFileDocumentValid});
  }

  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
      var inputType = e.target.files[0].type;
      var inputValue = e.target.files[0];
      var name = ''; 
      var validname = '';
      let fieldValidationErrors = this.state.formErrors;
      let selectedFileValid = false;
      if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
        selectedFileValid = true;
      } else {
        selectedFileValid = false;
      }
      name =  'selectedFileDocument';
      validname = 'selectedFileDocumentValid';
      fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
      this.setState({
        [name]: inputValue,
        formErrors: fieldValidationErrors,
        [validname] : selectedFileValid
      }, this.validateForm);
    }
  }

  async checkBackground() {
    try {
      var selectedFileValue = this.state.selectedFileDocument;
      if(selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) { 
        const data = new FormData();
        data.append('file', selectedFileValue);
        data.append('source', 'background');
        const response = await axios.post(global.config.urlLink+"/uploadfile", 
        data, { 
          headers: { 
            'content-type': 'multipart/form-data',
            'Authorization': authKey
          }
        });
        return response.data.values.linkfile1;
      }
      return '';
    }
    catch (error) {
      console.log(error);
      return '';
    }
  }

  async handleSubmitBulk(e) {
    e.preventDefault();
    if(this.state.file_url !== '') {
      document.getElementById('loading-area').style.display = 'block'; 
      var certificateBg = await this.checkBackground();
      var fileUrl;
      if(this.textInputFileUrl.value === '') { fileUrl = this.state.file_url; } else { fileUrl = this.textInputFileUrl.value; }
      
      var arrLinkPath = fileUrl.replace("https://","").split('/');
      var fileid = fileUrl;
      if(arrLinkPath.length > 0) {
          fileid = arrLinkPath[3];
      }
      var rangez = "Data!A2:J";
      var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","certificatebg":"'+certificateBg+'","form_id":"userstraining"}';
      var data = JSON.parse(datatext); 
      // var urlFetch = global.config.urlLink+"/gettmdata";
      var urlFetch = global.config.urlLink+"/addbulk";
      
      fetch(urlFetch, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
        },
        body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
          return res.json() 
      }).then( resJson => {
        if(resJson.status === '200') {
          var resultmessage = "";
          if(resJson.values[0] !== undefined) {
            console.log(resJson.values);
            if(resJson.values.length > 0) {
              resJson.values.forEach(list => {
                if(resultmessage !== "") { resultmessage += "<br/>"; }
                resultmessage += "- "+list;
              });
            }
          }
          document.getElementById("result-bulk-error").style.display = "block";
          document.getElementById("result-bulk-content").innerHTML = resultmessage;
          document.getElementById('loading-area').style.display = 'none'; 
          // window.location.href=global.ctconfig.urlHost+'/ctusertraining/';
        } else {
          console.log(resJson);
        }
      });
    }
  }

  async handleSubmit1(e) {
    e.preventDefault();
    if((this.state.full_name !== '') && (this.state.email !== '') && (this.state.expiry_date !== '')) {
      document.getElementById('loading-area').style.display = 'block';
      var fullName,email,phone,language,pictureUrl,expiryDate;      
      var certificateBg = await this.checkBackground();
      if(this.textInputFullName.value === '') { fullName = this.state.full_name; } else { fullName = this.textInputFullName.value; }
      if(this.textInputEmail.value === '') { email = this.state.email; } else { email = this.textInputEmail.value; }
      if(this.textInputPhone.value === '') { phone = this.state.phone; } else { phone = this.textInputPhone.value; }
      if(this.textInputLanguage.value === '') { language = this.state.language; } else { language = this.textInputLanguage.value; }
      if(this.textInputPictureUrl.value === '') { pictureUrl = this.state.picture_url; } else { pictureUrl = this.textInputPictureUrl.value; }
      expiryDate = this.state.expiry_date;
      
      var datatext ='{"user_fullname": "'+fullName+'","user_email": "'+email+'","user_phone": "'+phone+'","user_language": "'+language+'","user_picture": "'+pictureUrl+'","certificate_bg":"'+certificateBg+'","expiry_date": "'+expiryDate+'"}';
      var datasend = JSON.parse(datatext);

      fetch(global.config.urlLink+"/ceklogin", {
        method: "POST",
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*",
          'Authorization': authKey
        },
        body: JSON.stringify(datasend),
      })
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          console.log("Something happened wrong");
        }
      })
      .then((resultJson) => {
        if(resultJson.status === '200') {
          window.location.href=global.config.urlCms+'/'+this.state.activelink;
        } else {
          console.log(resultJson);
        }
      });        
    } else {
      console.log(this.textInputFullName.value);
      var arrfieldName = ['full_name','email','phone','language','picture_url','expiry_date'];
      var arrvalue = [this.textInputFullName.value,this.textInputEmail.value,this.textInputPhone.value,this.textInputLanguage.value,this.textInputPictureUrl.value,this.state.course_date];
      for(var ck in arrfieldName) {
        this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  

  

  contentTabs(param) {
    if (param === "bulk") {
      return(
        <div>
          <div className="mt-3">
            <form onSubmit={this.handleSubmitBulk}>
              <div className="row mt-3">
                <label className="col-md-3">To input your list of users</label>
                <div className="col-md-9">
                  <Button variant="dark" size="sm" onClick={() => this.handleLinkClick()}>Open Template</Button>        
                </div>
              </div>
              <div className="row mt-3">
                <label className="col-md-3" htmlFor="file_url">File Url</label>
                <div className="col-md-9">
                  <input type="text" className="form-control" name="file_url"
                    placeholder={this.state.file_url}
                    value={this.state.file_url}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputFileUrl = input}/>
                  {/* <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.file_url}/>}
                  </div> */}
                </div>
              </div>
              <div className="row mt-3">
                <label className="col-md-3" htmlFor="file_url">Certificate Background</label>
                <div className="col-md-9">
                  <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}></input> 
                  <div className="mt-1">
                    {
                      <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>
                    }
                  </div>
                  <div className="mt-1 fieldComments">&bull; Please upload file in jpg, jpeg, png format.</div>
                </div>
              </div>
              <div className="row mt-4 mb-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
              </div>
            </form>
            <div id="result-bulk-error" style={{display:"none"}}>
                <h6><strong>Already Available</strong></h6><br/>
                <div id="result-bulk-content"></div>
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div>
          
          <div className="mt-3">
            
            <form onSubmit={this.handleSubmit1}>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="full_name">Name</label>
                  <div className="col-md-9">
                      <input type="text" className="form-control" name="full_name"
                              placeholder={this.state.full_name}
                              value={this.state.full_name}
                              onChange={this.handleUserInput}  
                              ref={(input) => this.textInputFullName = input}/>
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.full_name}/>  }</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="email">Email</label>
                  <div className="col-md-9">
                      <input type="text" className="form-control" name="email"
                              placeholder={this.state.email}
                              value={this.state.email}
                              onChange={this.handleUserInput}  
                              ref={(input) => this.textInputEmail = input}/>
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.email}/>  }</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="email">Phone</label>
                  <div className="col-md-9">
                      <input type="text" className="form-control" name="phone"
                              placeholder={this.state.phone}
                              value={this.state.phone}
                              onChange={this.handleUserInput}  
                              ref={(input) => this.textInputPhone = input}/>
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.phone}/>  }</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="language">Language</label>
                  <div className="col-md-9">
                      <select name="language" className="form-control" 
                        value={this.state.language} 
                        onChange={this.handleInputSelect}
                        ref={(input) => this.textInputLanguage = input}
                      >
                        <option value="">-- please select --</option>
                        <option>English</option>
                        <option>Indonesian</option>
                      </select>
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.language}/>  }</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="picture_url">Picture URL</label>
                  <div className="col-md-9">
                      <input type="text" className="form-control" name="picture_url"
                        placeholder={this.state.picture_url}
                        value={this.state.picture_url}
                        onChange={this.handleUserInput}  
                        ref={(input) => this.textInputPictureUrl = input}/>
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.picture_url}/>  }</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="picture_url">Certificate Background</label>
                  <div className="col-md-9">
                      <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}></input> 
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                      <div className="mt-2 fieldComments" >&bull; Please upload file in jpg, jpeg, png format.</div>
                  </div>
              </div>
              <div className="row mt-3">
                  <label className="col-md-3" htmlFor="expiry_date">Expiry Date</label>
                  <div className="col-md-9">
                  <DatePicker
                        onChange={this.handleInputDate}
                        value={this.state.expiry_date}
                        selected={this.state.expiry_date}
                        dateFormat="MMMM d, yyyy"
                        //style={{border:'none'}}
                        // customStyles={{dateInput:{borderWidth: 0}}}
                        className="form-control"
                        />
                     
                      <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.expiry_date}/>  }</div>
                  </div>
              </div>
              <div className="row mt-4 mb-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          defTabIndex: "form-1",
          usageFor: "bulk",
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.setState({
        activelink: tabKey,
        stTitle: GetText() ? "Tambah Pengguna" : "Add Users",
        defTabIndex: "form-1",
        usageFor: "bulk",
    }) 
      // this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3>{GetText() ? "Pengguna" : "Users"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className=" mt-3">
                      {
                        this.state.defTabIndex !== "" && 
                          <Tabs className="nav-scroll" defaultActiveKey={this.state.defTabIndex} variant="tabs" id="tab">
                            <Tab className="pt-4" eventKey="form-1" title="Bulk Upload" key="1">
                              <div>
                              {this.contentTabs("bulk")}
                              </div>
                            </Tab>
                            <Tab className="pt-4" eventKey="form-2" title="Single Upload" key="2">
                              {this.contentTabs("single")}
                            </Tab>

                          </Tabs>
                        }
                      </div>

                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
