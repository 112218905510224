import React, { Component } from 'react';
import parse from 'html-react-parser';
import { Container, Button, Tabs, Tab } from 'react-bootstrap';
import { FaFileLines, FaAngleRight, FaRegFilePdf, FaRegFileVideo } from "react-icons/fa6";

// import DescriptionIcon from '@mui/icons-material/Description';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';
import DateTimeFormats from '../config/date-time-format';
import NAimage from '../img/pic-na.png';
import Popup from '../inc/PopUp';
import CTFeedbackAnswer from './ct-feedback-answer-pop';

const authKey = global.config.kindcode.training[1]+global.config.authBearerKey;

export default class CTLibraryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      library_docid: '',
      library_createby: '',
      library_createdate: '',
      library_title: '',
      library_short_desc: '',
      library_long_desc: '',
      library_language: '',
      library_picture: '',
      library_video: '',
      library_category: '',
      library_subcategory: '',
      library_status: '',
      library_lesson_list: [],
      library_exam: [],
      library_question_list: [],
      library_feedback_list: [],
      library_answer_list: [],
      isOpen: false,
      otherContent: "",
    }
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.feedbackContent = this.feedbackContent.bind(this);
    this.answerContent = this.answerContent.bind(this);
    this.lessonContent = this.lessonContent.bind(this);
  }
  handleBack = (currpage) => {
    // this.props.history.goBack();
    window.location.href=global.config.urlCms+"/"+currpage;
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }
  urldetailFeedback(valId, memberName, memberEmail, submitDate, answerList) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: '{"docid":"'+this.state.library_docid+'","content_title":"'+this.state.library_title+'","feedback_docid":"'+valId+'","member_name":"'+memberName+'","member_email":"'+memberEmail+'","submit_date":"'+submitDate+'","answer_list":'+JSON.stringify(answerList)+'}'
      }
    })
  }
  feedbackContent = (data) => {
    var feedbackList = [];
    if (data.length > 0) {
      data
      .sort((a,b) => b.subcontent.submitDate._seconds - a.subcontent.submitDate._seconds)
      .forEach((feedback, i) => {
        feedbackList.push(
          <tr key={"feedback-" + i}>
            <td>{i + 1}</td>
            <td>{DateTimeFormats(feedback.subcontent.submitDate._seconds,"date-long")}</td>
            <td>{feedback.subcontent.memberName}</td>
            <td>{feedback.subcontent.memberEmail}</td>
            <td>
                <FaFileLines className="styleicons" onClick={this.urldetailFeedback.bind(this,feedback.subdocid, feedback.subcontent.memberName, feedback.subcontent.memberEmail, feedback.subcontent.submitDate._seconds, feedback.sub2content)} />
                {/* <a href="#!"><FaFileLines className="iconGrey mgR20" onClick={this.urldetailFeedback.bind(this, feedback.subdocid, feedback.subcontent.memberName, feedback.subcontent.memberEmail, feedback.subcontent.submitDate._seconds, feedback.sub2content)} /></a> */}
            </td>
          </tr>
        );
      });
      return feedbackList;
    }
  }
  lessonContent = (dataLesson) => {
    var lessonsList = [];
    if(dataLesson.length > 0) {
      dataLesson.sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber);
      for(var ls=0; ls < dataLesson.length; ls++) {
          var retResult3 = dataLesson[ls];
          var attachmentList = [];
          if(retResult3.sub2content.length > 0) {
            for(var ls2=0; ls2 < retResult3.sub2content.length; ls2++) {
              attachmentList.push(<li key={ls2}>
                <div><a href={retResult3.sub2content[ls2].subsubcontent.fileUrl} target="_blank" rel="noreferrer">
                  {retResult3.sub2content[ls2].subsubcontent.fileType === 'video/mp4' ? <FaRegFileVideo className="styleicons"/> : <FaRegFilePdf className="styleicons"/>} 
                  {(retResult3.sub2content[ls2].subsubcontent.fileTitle !== undefined && retResult3.sub2content[ls2].subsubcontent.fileTitle !== "")? retResult3.sub2content[ls2].subsubcontent.fileTitle : retResult3.sub2content[ls2].subsubcontent.fileName}
                </a></div>
              </li>
              )
            }
          }
          var attachmentContent = [];
          if(attachmentList.length>0) {
            attachmentContent.push(
              <div className="mt-2 d-flex" >
                  <span className="fw-bold">Attachment</span><br/>
                  <ul>{attachmentList}</ul>
              </div>
            )
          }
          var trcls = '';
          var lessonDesc = retResult3.subcontent.lessonDesc !== undefined ?  ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',retResult3.subcontent.lessonDesc)) : '';
          lessonsList.push(
            <tr key={ls+1} className={trcls}>
              <th scope="row">{retResult3.subcontent.lessonNumber}</th>
              <td>{retResult3.subcontent.lessonTitle}</td>
              <td>
                <div>{lessonDesc}</div>
                <div className="mt-2 d-flex">{attachmentContent}</div>
              </td>
            </tr>
          );
      }
    } else {
        lessonsList.push(<tr key="0">
            <td></td>
            <td className="d-flex p-3">- no data -</td>
            <td></td>
          </tr>)
    }
    return lessonsList;
  }
  answerContent = (dataAnswer,feedbackCount) => {
    var answerList = [];
    if (dataAnswer.length > 0) {
      dataAnswer
      .forEach((answer, i) => {
        answerList.push(
          <tr key={"answer-" + i}>
            <td>{i}</td>
            <td>
              {answer.question}
              <br/>
              {
                answer.type === 'Multiple Choice' ? (
                  <ul className="mgT10">
                    {
                      answer.answer.map((item, j) => {
                        return (
                          <li key={"text-" + j}>{item.text} ({Math.round(item.counter / feedbackCount * 100, 0)}%)</li>
                        )
                      })
                    }
                  </ul>
                ) : (
                  <div>
                    {
                      answer.answer.map((item, j) => {
                        if (j < 5) { 
                          return (
                            <blockquote key={"text-" + j}>{item.text}</blockquote>
                          )
                        } else { return ''; }
                      })
                    }
                  </div>
                )
              }
            </td>
          </tr>
        )
      });
    }
    return answerList;
  }

  loadContent = (docid) => {
    return new Promise((resolve) => {
      fetch(global.config.urlLink+"/libraryview/"+docid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        resolve(resJson);
      })
    })
  }
  async loadData(currpage,typelink,docid) {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1] = await Promise.all([this.loadContent(docid)]);
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {

            var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="",cDate="",lStatus="";
            var lExam ='',lQuestion ='',lAnswer=[];
            if(result1.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = result1.values[0].content[0].maincontent.libraryTitle; }
            if(result1.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                lShortDesc = ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryShortDesc);
                lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
            }
            if(result1.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                lLongDesc = ReplaceAllContent('[;]',',',result1.values[0].content[0].maincontent.libraryLongDesc);
                lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
            }
            if(result1.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                lLanguage = result1.values[0].content[0].maincontent.libraryLanguage;
            }
            if(result1.values[0].content[0].maincontent.categoryId !== undefined) { 
                lCategory = result1.values[0].content[0].maincontent.categoryName;
            }
            if(result1.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                lSubCategory = result1.values[0].content[0].maincontent.subcategoryName;
            }
            if(result1.values[0].content[0].createdate !== undefined) {
              cDate = DateTimeFormats(result1.values[0].content[0].createdate._seconds,"datetime-short");
            }
            if(result1.values[0].content[0].maincontent.status !== undefined) { 
              lStatus = result1.values[0].content[0].maincontent.status;
            }
            if(result1.values[0].content[0].maincontent.libraryPicture !== undefined && result1.values[0].content[0].maincontent.libraryPicture !== "") { 
              lPicture = result1.values[0].content[0].maincontent.libraryPicture;
            } else { lPicture = NAimage; }
            if(result1.values[0].content[0].maincontent.libraryVideo !== undefined && result1.values[0].content[0].maincontent.libraryVideo !== "") { 
              lVideo = result1.values[0].content[0].maincontent.libraryVideo;
            }
            if(result1.values[0].scontent2.length > 0) {
              lExam = result1.values[0].scontent2[0].subcontent;
              lExam.docid = result1.values[0].scontent2[0].subdocid;
              if(result1.values[0].scontent2[0].sub2content.length > 0) {
                lQuestion = result1.values[0].scontent2[0].sub2content;
              }
            }
            if(result1.values[0].scontent3.length > 0) {
              result1.values[0].scontent3
              .sort((a,b) => b.subcontent.submitDate._seconds - a.subcontent.submitDate._seconds)
              .forEach((feedback, i) => {
                feedback.sub2content
                .sort((a, b) => a.subsubcontent.feedbackNumber - b.subsubcontent.feedbackNumber)
                .forEach((subcontent, j) => {
                  var number = subcontent.subsubcontent.feedbackNumber;
                  var answer = subcontent.subsubcontent.feedbackAnswer;
                  if (number in lAnswer) {
                    var check = 0;
                    lAnswer[number].answer.forEach((item, k) => {
                      if (item.text === answer) {
                        check = 1;
                        item.counter++;
                      }
                    });
                    if (check === 0) {
                      lAnswer[number].answer.push({
                        'text': answer,
                        'counter': 1
                      });
                    }
                  } else {
                    lAnswer[number] = {
                      'question': subcontent.subsubcontent.feedbackText,
                      'type': subcontent.subsubcontent.feedbackType,
                      'answer': [
                        {
                          'text': answer,
                          'counter': 1
                        }
                      ]
                    } 
                  }        
                });
              });
            }
            this.setState({
                activelink: currpage,
                stTitle: 'Detail Library',
                library_docid: result1.values[0].docid,
                library_createby: result1.values[0].content[0].maincontent.createByName,
                library_createdate: cDate,
                library_title: lTitle,
                library_short_desc: lShortDesc,
                library_long_desc: lLongDesc,
                library_language: lLanguage,
                library_picture: lPicture,
                library_video: lVideo,
                library_category: lCategory,
                library_subcategory: lSubCategory,
                library_status: lStatus,
                library_lesson_list: result1.values[0].scontent,
                library_exam: lExam,
                library_question_list: lQuestion,
                library_feedback_list: result1.values[0].scontent3,
                library_answer_list: lAnswer
            }) 

          } else {
            window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : ""
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Library" : " Library"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6">
                            <h5>{this.state.stTitle} </h5>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row">
                        <span className="text-muted">created by : {this.state.library_createby} <span className="ms-4">on : {this.state.library_createdate}</span></span>
                        <span className="text-muted">status : {this.state.library_status}</span>
                      </div>
                      {/* ==== start - detail library =============== */}
                      <div className="row mt-3">
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Title</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_title}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Category</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_category} <FaAngleRight className="styleicons" /> {this.state.library_subcategory}</div>
                          </div>
                          <div className="row mt-3">
                              <div className="col-md-3"><h6>Language</h6></div>
                              <div className="col-md-9"><span className="me-1">:</span>{this.state.library_language}</div>
                          </div>
                          <div className="row mt-4">
                            <div><h6>Short Description :</h6></div>
                            <div>{parse(this.state.library_short_desc)}</div>
                          </div>
                          <div className="row mt-4">
                            <div><h6>Introduction :</h6></div>
                            <div>{parse(this.state.library_long_desc)}</div>
                          </div>
                          {(this.state.library_picture !== "" && this.state.library_picture !== undefined)?
                          <div className="row mt-3">
                            <div><h6>Picture :</h6></div>
                            <div><img width="150" src={this.state.library_picture} alt=""/></div>
                          </div>
                          : '' }
                          {(this.state.library_video !== "" && this.state.library_video !== undefined)?
                          <div className="row mt-3">
                            <div>
                              <h6>Video Intro :</h6>  
                              <iframe src={this.state.library_video} title={this.state.library_title} do-not-allow="autoplay"></iframe><br/>
                              <a href={this.state.library_video} target="_blank" rel="noreferrer">{this.state.library_video}</a> 
                            </div>
                          </div>
                          : '' }
                      </div>
                      {/* ==== end - detail library =============== */}
                      {/* ==== start - library elemen =============== */}
                      <div className="row mt-3">
                        <div className="accordion" id="accordionTab">
                         {/* === #1  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-1">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                <span className="me-1 lh-base">Modules</span>
                              </button>
                            </h2>
                            <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                    <div id="lessons-area">
                                      <div className="mt-3">
                                      {this.state.library_lesson_list.length > 0? 
                                        <table className="table">
                                          <thead className="table-light">
                                              <tr key="0">
                                              <th scope="col text-wrap">#</th>
                                              <th scope="col text-wrap">Subject</th>
                                              <th scope="col text-wrap">Description</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                             {this.lessonContent(this.state.library_lesson_list)}
                                          </tbody>
                                        </table>
                                      : <div className="text-center w-100">- no data -</div>
                                    }
                                      </div>
                                    </div>  
                              </div>
                            </div>
                          </div>
                          {/* === #1  */}
                          {/* === #2  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-2">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                <span className="me-1 lh-base">Exam</span>
                              </button>
                            </h2>
                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                    <div id="exam-area">
                                      <div className="mt-3 d-flex">
                                          <div className="col-md-3">Description</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.examDesc}</div>
                                      </div>
                                      <div className="mt-2 d-flex">
                                          <div className="col-md-3">Passing Score</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.passingScore}</div>
                                      </div>
                                      <div className="mt-2 d-flex">
                                          <div className="col-md-3">Num. of question</div>
                                          <div className="col-md-9"><span className="me-2">:</span>{this.state.library_exam.numberQuestion}</div>
                                      </div>
                                      <div className="mt-4">
                                        <div className="fw-bold">Question List</div>
                                      </div>
                                      <div className="mt-2">
                                      {this.state.library_question_list.length > 0 ? 
                                        <table className="table">
                                          <thead className="table-light">
                                              <tr key="0">
                                              <th scope="col text-wrap">#</th>
                                              <th scope="col text-wrap">Question</th>
                                              <th scope="col text-wrap">Type</th>
                                              <th scope="col text-wrap">Answer</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                           {
                                                this.state.library_question_list
                                                .sort((a,b) => a.subsubcontent.questionNumber - b.subsubcontent.questionNumber)
                                                .map((question, i) => {
                                                  return (
                                                    <tr key={"question-" + i}>
                                                      <td>{question.subsubcontent.questionNumber}</td>
                                                      <td>
                                                        {ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',question.subsubcontent.questionText))}
                                                        <ol className="list-alpha">
                                                          {question.subsubcontent.option1 ? <li>{question.subsubcontent.option1}</li> : ''}
                                                          {question.subsubcontent.option2 ? <li>{question.subsubcontent.option2}</li> : ''}
                                                          {question.subsubcontent.option3 ? <li>{question.subsubcontent.option3}</li> : ''}
                                                          {question.subsubcontent.option4 ? <li>{question.subsubcontent.option4}</li> : ''}
                                                          {question.subsubcontent.option5 ? <li>{question.subsubcontent.option5}</li> : ''}
                                                        </ol>
                                                      </td>
                                                      <td>{question.subsubcontent.questionType}</td>
                                                      <td>{question.subsubcontent.questionAnswer}</td>
                                                    </tr>
                                                  )
                                                })
                                            }
                                          </tbody>
                                        </table>
                                        : <div className="text-center w-100">- no data -</div>
                                    }
                                      </div>
                                    </div>  
                              </div>
                            </div>
                          </div>
                          {/* === #2  */}
                          {/* === #3  */}
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="heading-3">
                              <button className="accordion-button collapsed shadow-none bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                <span className="me-1 lh-base">Feedback</span>
                              </button>
                            </h2>
                            <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionTab">
                              <div className="accordion-body">
                                  <div id="feedback-area">
                                    <div className="d-flex">
                                      <div className="col-md-12 st4Title">Total <strong>{this.state.library_feedback_list.length}</strong> responses</div>
                                    </div>
                                    {this.state.library_feedback_list.length > 0? 
                                    <div className="mt-2">  
                                      <Tabs className=" nav-scroll" defaultActiveKey="feedback-summary" id="tab" variant="underline">
                                        <Tab className="pt-4" eventKey="feedback-summary" title="Summary">
                                              <table className="table table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                      <th scope="col text-wrap">#</th>
                                                      <th scope="col text-wrap">Questions &amp; Answers</th>
                                                    </tr>
                                                </thead>                              
                                                <tbody>
                                                  {this.answerContent(this.state.library_answer_list,this.state.library_feedback_list.length)}
                                                </tbody>
                                              </table>  
                                        </Tab>
                                        <Tab className="pt-4" eventKey="feedback-list" title="List">
                                              <table className="table">
                                                <thead className="table-light">
                                                    <tr>
                                                      <th scope="col">#</th>
                                                      <th scope="col">Date</th>
                                                      <th scope="col">User</th>
                                                      <th scope="col">Email</th>
                                                      <th scope="col" style={{width:"100px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {this.feedbackContent(this.state.library_feedback_list)}
                                                </tbody>
                                              </table>
                                        </Tab>
                                      </Tabs>  
                                    </div>
                                    : <></>
                                    }
                                  </div> 
                                   
                              </div>
                            </div>
                          </div>
                          {/* === #3  */}
                        </div>

                      </div>
                      {/* ==== end - library elemen =============== */}
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            {
                    this.state.isOpen && <Popup 
                      content={<CTFeedbackAnswer/>}
                      id={this.state.memberdoc_id} 
                      title={"Feedback Form"}
                      others={this.state.otherContent}
                      styles={"70%"}
                      handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
                    />
                  }
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
