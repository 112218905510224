import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FaTrash, FaRegFilePdf } from "react-icons/fa6";
import { FormErrors } from '../inc/error-form';


const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;

export default class CTCourseMateriForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        activelink: '',
        course_id: '',
        course_title: '',
        course_date: '',
        course_recording: {},
        course_document: {},
        selectedFileDocument: '',
        selectedFileDocument2: '',
        formErrors: { selectedFileDocument: '', selectedFileDocument2: '' },
        selectedFileDocumentValid: false,
        selectedFileDocument2Valid: false,
        formValid: false,
        input_disabled: true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    switch(fieldName) {
        case 'selectedFileDocument': 
            if(value !== "") {
                selectedFileDocumentValid = value.type.indexOf('video') > -1;
                fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'please upload video only';
            } else {
                selectedFileDocumentValid = value !== "";
                fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'recording need to be filled';
            }
            break;
        case 'selectedFileDocument2': 
            if(value !== "") {
                selectedFileDocument2Valid = value.type.indexOf('pdf') > -1;
                fieldValidationErrors.selectedFileDocument2 = selectedFileDocument2Valid ? '' : 'please upload pdf only';
            } else {
                selectedFileDocument2Valid = value !== "";
                fieldValidationErrors.selectedFileDocument2 = selectedFileDocument2Valid ? '' : 'materi workshop need to be filled';
            }
            break;
            
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
            selectedFileDocumentValid: selectedFileDocumentValid,
            selectedFileDocument2Valid: selectedFileDocument2Valid,
        }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: 
        this.state.selectedFileDocumentValid && this.state.selectedFileDocument2Valid 
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        // if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1) { //add image
        //     selectedFileValid = true;
        // } else {
        //     selectedFileValid = false;
        // }
        if(e.target.id === "fileDocument2") {
            if(inputType.indexOf('pdf') > -1) { 
                selectedFileValid = true;
            } 
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload pdf only';
        } else {
            if(inputType.indexOf('video') > -1) { 
                selectedFileValid = true;
            }
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload video only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  onDeleteFileHandler = (e) => {
    var name = '';
    if(e.target.id === "deleteRecording") {
        name = 'course_recording';
    } else if(e.target.id === "deleteDocument") {
        name = 'course_document';
    }    
    this.setState({
        [name]: {}
    });
  }

  submitData(datavideo,datapdf) {
    var data = {
        "course_docid": this.state.course_id,
        "course_document": datapdf,
        "course_recording": datavideo,
        "form_id": "form-edit-attach-materi"
    }
    var urlFetch = global.config.urlLink+"/courseedit";
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/detailedit/"+docId;
        } else {
            console.log('Something happened wrong');
        }

        document.getElementById('loading-area').style.display = 'none'; 
        document.getElementById('submit-button').removeAttribute("disabled");
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none';
        document.getElementById('submit-button').removeAttribute("disabled"); 
    });
    
  }
  handleSubmit(e) {
    e.preventDefault();
    var arrfieldName = [], arrvalue = [];
    var selectedFileValue = this.state.selectedFileDocument;
    var selectedFileValue2 = this.state.selectedFileDocument2;

    if(selectedFileValue === '' && selectedFileValue2 === '' && Object.keys(this.state.course_document).length === 0 && Object.keys(this.state.course_recording).length === 0) {
        // arrfieldName.push('selectedFileDocument2');
        // arrvalue.push(selectedFileValue2);
    } else {
        if(selectedFileValue !== '' && selectedFileValue !== null && selectedFileValue !== undefined) {
            if(selectedFileValue.type.indexOf('video') < 0) { 
                arrfieldName.push('selectedFileDocument');
                arrvalue.push(selectedFileValue);
            }
        }
        if(selectedFileValue2 !== '' && selectedFileValue2 !== null && selectedFileValue2 !== undefined) {
            if(selectedFileValue2.type.indexOf('pdf') < 0) { 
                arrfieldName.push('selectedFileDocument2');
                arrvalue.push(selectedFileValue2);
            }
        }
    }
    if(arrfieldName.length > 0) {
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    } else {
            document.getElementById('loading-area').style.display = 'block'; 
            document.getElementById('submit-button').setAttribute('disabled', true);
            var videorecording=this.state.course_recording;
            var pdfmateri=this.state.course_document;
                        
            if(selectedFileValue === "" && selectedFileValue2 === "") {
                this.submitData(videorecording,pdfmateri);
            } else {
                const data = new FormData() 
                if(selectedFileValue !== "" && selectedFileValue2 !== "") { 
                    data.append('file', selectedFileValue);
                    data.append('file2', selectedFileValue2); 
                } else if(selectedFileValue !== "") {
                    data.append('file', selectedFileValue); 
                } else if(selectedFileValue2 !== "") {
                    data.append('file', selectedFileValue2); 
                }
                data.append('docid', this.state.course_id);
                data.append('source', 'course-attach');
                data.append('index',0);
                axios.post(global.config.urlLink+"/uploadfiledoc", 
                data, { 
                    headers: { 
                        'content-type': 'multipart/form-data',
                        'Authorization': authKey
                    }
                }).then(res => { 
                    if(res.data.status === "200") {
                        if(res.data.values[0].content.fileUrl !== undefined) {
                            if(selectedFileValue !== "") { videorecording =  res.data.values[0].content; } 
                            else { pdfmateri =  res.data.values[0].content; }
                        }
                        if(res.data.values[0].content2.fileUrl !== undefined) {
                            pdfmateri =  res.data.values[0].content2; 
                        
                        }
                        this.submitData(videorecording,pdfmateri);
                    } else {
                        document.getElementById('loading-area').style.display = 'none'; 
                        document.getElementById('submit-button').removeAttribute("disabled");
                    }
                }).catch(err => { 
                    document.getElementById('loading-area').style.display = 'none'; 
                    document.getElementById('submit-button').removeAttribute("disabled");
                })
            }
            // } else {
            //     document.getElementById('loading-area').style.display = 'none'; 
            //     this.submitData(this,"","");
            // }
        
    
    }
    
  }

  componentDidMount() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    this.setState({
        activelink: othersContent.activelink, 
        course_id: othersContent.courseId,
        course_title: othersContent.courseTitle,
        course_date: othersContent.courseDate,
        course_recording: othersContent.courseRecording,
        course_document: othersContent.courseDocument,
        formErrors: { selectedFileDocument: '', selectedFileDocument2: '' },
        selectedFileDocumentValid: false,
        selectedFileDocument2Valid: false,
        formValid: false,
        input_disabled: true
    }); 
  }

  render() {
    return (
        <div className="container mb-4">
          <div id="lesson-detail-form">
            <form onSubmit={this.handleSubmit}>
              <div>
                  <input type="hidden" id="course_id" name="course_id" value={this.state.course_id} />
              </div>
              <div className="mt-3"><h5>{this.state.course_title}</h5></div>
              
              <div className="mt-4">
                
                <div className="row mt-3">
                    <div className="col-md-3">Materi Workshop</div>
                    <div className="col-md-9 frg15e">
                    {(this.state.course_document !== "" && this.state.course_document !== undefined && Object.keys(this.state.course_document).length > 0)?
                        <div className="d-flex mb-3">
                            <a href={this.state.course_document.fileUrl} target="_blank" rel="noreferrer"><FaRegFilePdf className="styleicons"/> {this.state.course_document.filename}</a>
                            <a className="linkText" onClick={this.onDeleteFileHandler} id="deleteDocument"><FaTrash className="ms-3" /> Delete Document &raquo;</a>
                        </div>
                    : ''
                    }
                        <div>
                            <input id="fileDocument2" type="file" onChange={this.onChangeFileHandler} />
                            <div className="mt-2 fieldComments">&bull; Please upload file in pdf format.</div>
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                        </div>
                    </div>
                </div>
                
                <div className="row mt-4">
                    <div className="col-md-3">Video Recording</div>
                    <div className="col-md-9 frg15e">
                    {(this.state.course_recording !== "" && this.state.course_recording !== undefined  && Object.keys(this.state.course_recording).length > 0)?
                        <div className="d-flex mb-3">
                            <iframe src={this.state.course_recording.fileUrl} title={this.state.course_title} allow="autoplay"></iframe>
                            <a className="linkText" onClick={this.onDeleteFileHandler} id="deleteRecording"><FaTrash /> Delete Recording &raquo;</a>
                        </div>
                    : ''
                    }
                        <div>
                            <input id="fileDocument" type="file" onChange={this.onChangeFileHandler} />
                            <div className="mt-2 fieldComments">&bull; Please upload file in mp4 format.</div>
                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                        </div>
                    </div>
                </div>

                

                  <div className="row mt-4 mb-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-9"><Button id="submit-button" variant="secondary" type="submit" >Submit</Button></div>
                  </div>
                   
              </div>
            </form>
          </div>
          <div id="loading-area" className="d-none loading"></div> 
        </div>
    );
  }
}
