import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from '../inc/ckImageUploader';
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import ReplaceAllContent from '../config/replace-all';
import Error404 from '../Error404';

const authKey = global.config.kindcode.training[20]+global.config.authBearerKey;

class CTFaqForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            activelink:"none",
            tab_language: '',
            faq_id: '',
            faq_category: '',
            faq_number: '',
            faq_question: '',
            faq_answer: '',
            faq_language: '',
            faq_status: '',
            formErrors: { faq_category: '', faq_number: '', faq_question: '', faq_answer: '', faq_language: '', faq_status: ''},
            faqcategoryValid: false,
            faqnumberValid: false,
            faqquestionValid: false,
            faqanswerValid: false,
            faqlanguageValid: false,
            faqstatusValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    handleBack = (currpage) => {
        window.location.href=global.config.urlCms+"/"+currpage;
    }
    handleUserCKEditorInput = (e) => {
        const name = "faq_answer";
        const value = e.getData();
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const inputname = e.target.name; 
        const value = e.target.value;
        this.setState(prevState => {
            return {  
                ...prevState[inputname], [inputname]: value
            }
        }, () => { 
            this.validateField(inputname, value)
        });
        
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let faqcategoryValid = this.state.faqcategoryValid;
        let faqnumberValid = this.state.faqnumberValid;
        let faqquestionValid = this.state.faqquestionValid;
        let faqanswerValid = this.state.faqanswerValid;
        let faqlanguageValid = this.state.faqlanguageValid;
        let faqstatusValid = this.state.faqstatusValid;
        
        switch(fieldName) {
            case 'faq_category': 
                faqcategoryValid = value.trim().length > 0;
                fieldValidationErrors.faq_category = faqcategoryValid ? '' : 'category need to be filled';
                break;
            case 'faq_number': 
                faqnumberValid = value.trim().length > 0;
                fieldValidationErrors.faq_number = faqnumberValid ? '' : 'order of to need to be filled';
                break;
            case 'faq_question': 
                faqquestionValid = value.trim().length > 0;
                fieldValidationErrors.faq_question = faqquestionValid ? '' : 'question need to be filled';
                break;
            case 'faq_answer': 
                faqanswerValid = value.trim().length > 0;
                fieldValidationErrors.faq_answer = faqanswerValid ? '' : 'answer need to be filled';
                break;
            case 'faq_language': 
                faqlanguageValid = value.trim().length > 0;
                fieldValidationErrors.faq_language = faqlanguageValid ? '' : 'language need to be filled';
                break;
            case 'faq_status': 
                faqstatusValid = value.trim().length > 0;
                fieldValidationErrors.faq_status = faqstatusValid ? '' : 'status need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        faqcategoryValid: faqcategoryValid,
                        faqnumberValid: faqnumberValid,
                        faqanswerValid: faqanswerValid,
                        faqquestionValid: faqquestionValid,
                        faqlanguageValid: faqlanguageValid,
                        faqstatusValid: faqstatusValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.faqcategoryValid && this.state.faqnumberValid && this.state.faqanswerValid && this.state.faqquestionValid && this.state.faqlanguageValid && this.state.faqstatusValid });
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.faq_category !== '' &&  this.state.faq_number !== '' && this.state.faq_question !== '' && this.state.faq_answer !== '' && this.state.faq_language !== '' && this.state.faq_status !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var faqCategory, faqNumber, faqAnswer, faqQuestion, faqLanguage, faqStatus;
            if(this.textInputFaqCategory.value === '') { faqCategory = this.state.faq_category; } else { faqCategory = this.textInputFaqCategory.value; }
            if(this.textInputFaqNumber.value === '') { faqNumber = this.state.faq_number; } else { faqNumber = this.textInputFaqNumber.value; }
            // if(this.textInputFaqAnswer.value === '') { faqAnswer = this.state.faq_answer; } else { faqAnswer = this.textInputFaqAnswer.value; }
            if(this.textInputFaqQuestion.value === '') { faqQuestion = this.state.faq_question; } else { faqQuestion = this.textInputFaqQuestion.value; }
            if(this.textInputFaqLanguage.value === '') { faqLanguage = this.state.faq_language; } else { faqLanguage = this.textInputFaqLanguage.value; }
            if(this.textInputFaqStatus.value === '') { faqStatus = this.state.faq_status; } else { faqStatus = this.textInputFaqStatus.value; }
            faqAnswer = ReplaceAllContent('"',"'",this.state.faq_answer);
            // faqStatus = faqStatus === 'Active' ? 1 : 0;
            var datatext = '{';
            datatext += '"faq_docid":"'+this.state.faq_id+'","faq_category":"'+faqCategory+'","faq_number":"'+faqNumber+'","faq_answer":"'+faqAnswer+'","faq_question":"'+faqQuestion+'","faq_language":"'+faqLanguage+'","faq_status":"'+faqStatus+'"';
            var urlFetch = global.config.urlLink;
            if(this.state.faq_id !== '') { 
                datatext += ',"form_id":"form-edit"';
                urlFetch = urlFetch+"/faqedit"; 
            } else { 
                datatext += ',"form_id":"form-add"';
                urlFetch = urlFetch+"/faqadd"; 
            }
            datatext += '}';
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    var param = faqLanguage.toLowerCase(); if(faqLanguage === "Indonesian") { param = "indonesia"; } 
                     window.location.href=global.config.urlCms+'/'+this.state.activelink+'/'+param;
                    // window.location.href=global.ctconfig.urlHost+'/ctcategory'+this.state.usage_for;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['faq_category', 'faq_number', 'faq_question', 'faq_answer', 'faq_language', 'faq_status'];
            var arrvalue = [this.textInputFaqCategory.value, this.textInputFaqNumber.value, this.textInputFaqQuestion.value, this.state.faq_answer, this.textInputFaqLanguage.value, this.textInputFaqStatus.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    loadData(currpage,typelink,docid) {
        if(typelink === 'edit' && docid !== "") {
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/faqview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    var resultContent = resJson.values[0].content[0].maincontent;
                    this.setState({
                        activelink: currpage,
                        stTitle: 'Edit FAQ ',
                        faq_id: resJson.values[0].docid,
                        faq_category: resultContent.faqCategory,
                        faq_number: resultContent.faqNumber,
                        faq_question: resultContent.faqQuestion,
                        faq_answer: resultContent.faqAnswer,
                        faq_language: resultContent.faqLanguage,
                        faq_status: resultContent.faqStatus,
                        formErrors: { faq_category: '', faq_number: '', faq_question: '', faq_answer: '', faq_language: '', faq_status: ''},
                        faqcategoryValid: false,
                        faqnumberValid: false,
                        faqquestionValid: false,
                        faqanswerValid: false,
                        faqlanguageValid: false,
                        faqstatusValid: false,
                        formValid: false,
                        input_disabled: true
                    }); 
                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            var faqLanguage = "";
            if(docid === "eng") { faqLanguage = "English";  }
            else if(docid === "idn") { faqLanguage = "Indonesian"; }
          
            this.setState( prevState => {
                return {
                  ...prevState.activelink, activelink: currpage,
                  ...prevState.stTitle, stTitle: GetText() ? "Tambah FAQ" : "Add FAQ",
                  ...prevState.faq_language, faq_language: faqLanguage,
                  ...prevState.tab_language, tab_language: faqLanguage,
                  ...prevState.faq_status, faq_status: 1,
                  
                }
            })
        }
    }

    componentDidMount() {
        var urlpath = window.location.pathname;
        if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
          urlpath = urlpath.substring(0,urlpath.length-1);
        }
        var arrUrl = urlpath.split("/");
        
        var tabKey="", tabKey2="", docId="";
        if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
          tabKey = arrUrl[arrUrl.length-2];
          tabKey2 = arrUrl[arrUrl.length-1];
        } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
            tabKey = arrUrl[arrUrl.length-3];
            tabKey2 = arrUrl[arrUrl.length-2];
            docId = arrUrl[arrUrl.length-1];
        }
        if(tabKey === "") {
           
          this.setState({
              activelink: "pnf",
              stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : "",
            });
        } else {
          this.loadData(tabKey,tabKey2,docId);
        }
        
      }
    render() {
        var optLanguage = [];
        if(this.state.tab_language === "English") {
            optLanguage.push(<option value="English" selected>English</option>)
        } else if(this.state.tab_language === "Indonesian") {
            optLanguage.push(<option value="Indonesian" selected>Indonesian</option>)
        } else {
            optLanguage.push(<option value="">-- please select --</option>)
            optLanguage.push(<option value="English">English</option>)
            optLanguage.push(<option value="Indonesian">Indonesian</option>)
        }

        var selcategoryList = [];
        var selcategoryData = [];
        if(this.state.tab_language === "Indonesian") {
            selcategoryData = global.config.faqCategory.IDN;
        } else {
            selcategoryData = global.config.faqCategory.ENG;
        }
        if(selcategoryData.length > 0) {
            for(var u in selcategoryData) {
                selcategoryList.push(<option value={selcategoryData[u].value} key={u}>{selcategoryData[u].label}</option>)
            }
        }
        return (
            <div id="setting">
                <Container>
                    { (this.state.activelink === "pnf")? 
                    <Error404 />
                    : 
                    (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
                    (<>
                        <div className="row py-5 mb-5">
                        {/* === start - body */}
                            <div className="col-md-3 bg-light">
                            { 
                                (this.state.activelink !== "none") && 
                                <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                            
                            }
                            </div>
                            <div className="col-md-9 p-4">
                            <div className="row">
                                <div>
                                    <h3 className="mb-4">{GetText() ? "FAQ di Novaio" : "FAQ in Novaio"}</h3>
                                </div>
                                <div className="mt-3">
                                <div className="row show">
                                    <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink,this.state.faq_id)}>&#9664; back</Button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <div >
                                            <input type="hidden" required name="faq_id" value={this.state.faq_id} ref={(input) => this.textInputFAQId = input} />
                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_category">Category</label>
                                                <div className="col-md-9">
                                                    <select name="faq_category" className="form-control" 
                                                        value={this.state.faq_category} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputFaqCategory = input}
                                                    >
                                                    <option value="" key="">-- please select --</option>
                                                    {selcategoryList}
                                                    </select>
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_category}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_number">Order of to</label>
                                                <div className="col-md-9">
                                                    <input type="number" className="form-control" name="faq_number"
                                                            placeholder={this.state.faq_number}
                                                            value={this.state.faq_number}
                                                            onChange={this.handleUserInput}  
                                                            ref={(input) => this.textInputFaqNumber = input}
                                                            min="1"/> 
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_number}/>  }</div>
                                                </div>
                                            </div>
                                            
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_question">Question</label>
                                                <div className="col-md-9">
                                                    <textarea name="faq_question" className="form-control" 
                                                            rows={5} cols={30} 
                                                            value={this.state.faq_question} 
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputFaqQuestion = input}
                                                        />
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_question}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label htmlFor="faq_answer">Answer</label>
                                                <div>
                                                    <CKEditor
                                                            editor  = { ClassicEditor }
                                                            name    = "faq_answer"
                                                            data    = {this.state.faq_answer}
                                                            config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                                            onReady = { editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onChange={ ( event, editor ) => {
                                                                this.handleUserCKEditorInput(editor);
                                                                const data = editor.getData();
                                                                
                                                                //this.textInputContentLongDesc = data;
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onBlur={ ( event, editor ) => {
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                            onFocus={ ( event, editor ) => {
                                                                editor.ui.view.editable.element.style.minHeight = "300px";
                                                            } }
                                                        />
                                                    <div className="mt-1">
                                                        { <FormErrors formErrors={this.state.formErrors.faq_answer}/>  }
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_language">Language</label>
                                                <div className="col-md-9">
                                                    <select name="faq_language" className="form-control" 
                                                        value={this.state.faq_language} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputFaqLanguage = input}
                                                    >
                                                    {optLanguage}
                                                    </select>
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_language}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <label className="col-md-3" htmlFor="faq_status">Status</label>
                                                <div className="col-md-9">
                                                    <select name="faq_status" className="form-control" 
                                                        value={this.state.faq_status} 
                                                        onChange={this.handleUserSelect}
                                                        ref={(input) => this.textInputFaqStatus = input}
                                                    >
                                                        <option value="" key="">-- please select --</option>
                                                        <option value="1" key="status_active">Active</option>
                                                        <option value="0" key="status_inactive">Inactive</option>
                                                    </select>
                                                    <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.faq_status}/>  }</div>
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-3">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                            </div>
                                        </form>
                                </div>
                                </div>
                            </div>
                            </div>
                        {/* === end - body */}
                        </div>
                        
                        </>
                    )
                    : 
                    <div><CTNA/></div>
                    }
                    <div id="loading-area" className="d-none loading"></div> 
                    </Container>
            </div>
          );
        }
     }
      
export default CTFaqForm;
