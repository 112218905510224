import React, { Component } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaTrash } from "react-icons/fa6";
import MenuLeftCMS from './ct-fragmentsidemenu';
import { GetText } from '../config/helper';
import '../config/global';
import CTNA from './ct-fragmentna';
import { FormErrors } from '../inc/error-form';
import Error404 from '../Error404';
import ReplaceAllContent from '../config/replace-all';

const authKey = global.config.kindcode.training[2]+global.config.authBearerKey;
const authKey2 = global.config.kindcode.training[3]+global.config.authBearerKey;
const authKey3 = global.config.kindcode.training[4]+global.config.authBearerKey;
const authKey4 = global.config.kindcode.training[0]+global.config.authBearerKey;

export default class CTCourseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activelink:"none",
      stTitle: '',
      tab_language: '',
      course_docid: '',
      course_title: '',
      course_type: '',
      course_venue: '',
      course_venue_address: '',
      course_venue_maps: '',
      course_meet_link: '',
      course_short_desc: '',
      course_long_desc: '',
      course_language: '',
      course_picture: '',
      course_video: '',
      course_category: '',
      course_subcategory: '',
      course_tutor: [],
      course_currency: 'USD',
      course_price: '',
      course_date: new Date(),
      course_start_time_hour: 8,
      course_start_time_minute: 0,
      course_end_time_hour: 17,
      course_end_time_minute: 0,
      categoryList: [],
      subcategoryList: [],
      tutorList: [],
      selectedFileDocument: null,
      selectedFileDocument2: null,
      formErrors: {course_title: '', course_type: '', course_venue: '', course_venue_address: '', course_venue_maps : '', course_meet_link: '', course_short_desc: '', course_long_desc: '', course_language: '', course_category: '', course_subcategory: '', course_tutor: '', course_currency: '', course_price: '', course_date: '', course_start_time: '', course_end_time: '', selectedFileDocument:'', selectedFileDocument2:''},
      coursetitleValid: false,
      coursetypeValid: false,
      coursevenueValid: false,
      coursevenueaddressValid: false,
      coursevenuemapsValid: false,
      coursemeetlinkValid: false,
      courseshortdescValid: false,
      courselongdescValid: false,
      courselanguageValid: false,
      coursecategoryValid: false,
      coursesubcategoryValid: false,
      coursetutorValid: false,
      coursecurrencyValid: false,
      coursepriceValid: false,
      coursedateValid: false,
      coursestarttimehourValid: false,
      coursestarttimeminuteValid: false,
      courseendtimehourValid: false,
      courseendtimeminuteValid: false,
      selectedFileDocumentValid: false,
      selectedFileDocument2Valid: false,
      formValid: false,
      input_disabled: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
    this.loadSubcategory = this.loadSubcategory.bind(this);
    this.loadCategory = this.loadCategory.bind(this);
    this.loadTutor = this.loadTutor.bind(this);
    this.loadContent = this.loadContent.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  handleBack = (currpage,id) => {
    if(id !== "") {
      window.location.href=global.config.urlCms+"/"+currpage+"/detailedit/"+id;
    } else {
      window.location.href=global.config.urlCms+"/"+currpage;
    }
    
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserSelect = (e) => {
    const inputname = e.target.name; 
    const value = e.target.value;
    if(inputname === 'course_category') {
      var arrValues = value.split("$$");
      this.loadSubcategory(arrValues[0]);
      this.setState( prevState => {
          return {  
              ...prevState.course_category, course_category: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else {
      this.setState(prevState => {
          return {  
              ...prevState[inputname], [inputname]: value
          }
      }, () => { 
          this.validateField(inputname, value)
      });
    }
  }
  handleUserMultiSelect = (e) => {
    const inputname = e.target.name;
    var options = e.target.options;
    var values = [];
    
    for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
            values.push(options[i].value);
        }
    }
    this.setState({
        course_tutor: values
    }, () => { 
        this.validateField(inputname, values)
    })
  }
  handleEventDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.course_date, course_date: date
        }
        }, () => { this.validateField('course_date', date)
    }) 
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let coursetitleValid = this.state.coursetitleValid;
    let coursetypeValid = this.state.coursetypeValid;
    let coursevenueValid = this.state.coursevenueValid;
    let coursevenueaddressValid = this.state.coursevenueaddressValid;
    let coursevenuemapsValid = this.state.coursevenuemapsValid;
    let coursemeetlinkValid = this.state.coursemeetlinkValid;
    let courseshortdescValid = this.state.courseshortdescValid;
    let courselongdescValid = this.state.courselongdescValid;
    let courselanguageValid = this.state.courselanguageValid;
    let coursecategoryValid = this.state.coursecategoryValid;
    let coursesubcategoryValid = this.state.coursesubcategoryValid;
    let coursetutorValid = this.state.coursetutorValid;
    let coursepriceValid = this.state.coursepriceValid;
    let coursedateValid = this.state.coursedateValid;
    let coursestarttimehourValid = this.state.coursestarttimehourValid;
    let coursestarttimeminuteValid = this.state.coursestarttimeminuteValid;
    let courseendtimehourValid = this.state.courseendtimehourValid;
    let courseendtimeminuteValid = this.state.courseendtimeminuteValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    switch(fieldName) {
        case 'course_title': 
            coursetitleValid = value.trim().length > 0;
            fieldValidationErrors.course_title = coursetitleValid ? '' : 'title need to be filled';
            break;
        case 'course_type': 
            coursetypeValid = value.trim().length > 0;
            fieldValidationErrors.course_type = coursetypeValid ? '' : 'type need to be filled';
            break;
        case 'course_venue': 
            if(this.state.course_type === "Offline") { 
                coursevenueValid = value.trim().length > 0;
                fieldValidationErrors.course_venue = coursevenueValid ? '' : 'venue location need to be filled';
            } else { fieldValidationErrors.course_venue = ''; }
            break;
        case 'course_venue_address': 
            if(this.state.course_type === "Offline") { 
                coursevenueaddressValid = value.trim().length > 0;
                fieldValidationErrors.course_venue_address = coursevenueaddressValid ? '' : 'venue address need to be filled';
            } else { fieldValidationErrors.course_venue_address = ''; }
            break;
        case 'course_venue_maps': 
            if(this.state.course_type === "Offline") { 
                coursevenuemapsValid = value.trim().length > 0;
                fieldValidationErrors.course_venue_maps = coursevenuemapsValid ? '' : 'venue maps need to be filled';
            } else { fieldValidationErrors.course_venue_maps = ''; }
            break;
        case 'course_meet_link': 
            if(this.state.course_type === "Online") { 
                coursemeetlinkValid = value.trim().length > 0;
                fieldValidationErrors.course_meet_link = coursemeetlinkValid ? '' : 'meet link need to be filled';
            } else { fieldValidationErrors.course_meet_link = ''; } 
            break;
        case 'course_short_desc': 
            courseshortdescValid = value.trim().length > 0;
            fieldValidationErrors.course_short_desc = courseshortdescValid ? '' : 'short description need to be filled';
            break;
        case 'course_long_desc': 
            courselongdescValid = value.trim().length > 0;
            fieldValidationErrors.course_long_desc = courselongdescValid ? '' : 'full description need to be filled';
            break;
        case 'course_language': 
            courselanguageValid = value.trim().length > 0;
            fieldValidationErrors.course_language = courselanguageValid ? '' : 'language need to be filled';
            break;
        case 'course_category': 
            coursecategoryValid = value.trim().length > 0;
            fieldValidationErrors.course_category = coursecategoryValid ? '' : 'category need to be filled';
            break;
        case 'course_subcategory': 
            coursesubcategoryValid = value.trim().length > 0;
            fieldValidationErrors.course_subcategory = coursesubcategoryValid ? '' : 'sub category need to be filled';
            break;
        case 'course_tutor': 
            coursetutorValid = value.length > 0;
            fieldValidationErrors.course_tutor = coursetutorValid ? '' : 'tutor need to be filled';
            break;
        case 'course_price': 
            coursepriceValid = value.trim().length > 0;
            fieldValidationErrors.course_price = coursepriceValid ? '' : 'price need to be filled';
            break;
        case 'course_date': 
            coursedateValid = value !== null;
            fieldValidationErrors.course_date = coursedateValid ? '' : 'event date need to be filled';
            break;
        case 'course_start_time_hour': 
        case 'course_start_time_minute': 
            coursestarttimehourValid = value.trim().length > 0;
            coursestarttimeminuteValid = value.trim().length > 0;
            fieldValidationErrors.course_start_time = (coursestarttimehourValid || coursestarttimeminuteValid) ? '' : 'start time event need to be filled';
            break;
        case 'course_end_time_hour': 
        case 'course_end_time_minute': 
            courseendtimehourValid = value.trim().length > 0;
            courseendtimeminuteValid = value.trim().length > 0;
            fieldValidationErrors.course_end_time = (courseendtimehourValid || courseendtimeminuteValid) ? '' : 'end time event need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
            coursetitleValid: coursetitleValid,
            coursetypeValid: coursetypeValid,
            coursevenueValid: coursevenueValid,
            coursevenueaddressValid: coursevenueaddressValid,
            coursevenuemapsValid: coursevenuemapsValid,
            coursemeetlinkValid: coursemeetlinkValid,
            courseshortdescValid: courseshortdescValid,
            courselongdescValid: courselongdescValid,
            courselanguageValid: courselanguageValid,
            coursecategoryValid: coursecategoryValid,
            coursesubcategoryValid: coursesubcategoryValid,
            coursetutorValid: coursetutorValid,
            coursepriceValid: coursepriceValid,
            coursedateValid: coursedateValid,
            coursestarttimehourValid: coursestarttimehourValid,
            coursestarttimeminuteValid: coursestarttimeminuteValid,
            courseendtimehourValid: courseendtimehourValid,
            courseendtimeminuteValid: courseendtimeminuteValid,
            selectedFileDocumentValid: selectedFileDocumentValid,
            selectedFileDocument2Valid: selectedFileDocument2Valid,
        }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: 
        this.state.coursetitleValid && this.state.coursetypeValid && this.state.coursevenueValid && this.state.coursevenueaddressValid && this.state.coursemeetlinkValid && this.state.courseshortdescValid && this.state.courselongdescValid && 
        this.state.courselanguageValid && this.state.coursecategoryValid && this.state.coursesubcategoryValid && this.state.coursetutorValid && 
        this.state.coursepriceValid && this.state.coursedateValid && 
        this.state.coursestarttimehourValid && this.state.coursestarttimeminuteValid &&
        this.state.courseendtimehourValid && this.state.courseendtimeminuteValid &&
        this.state.selectedFileDocumentValid && this.state.selectedFileDocument2Valid 
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  onDeleteFileHandler = (e) => {
    var name = '';
    if(e.target.id === "deletePicture") {
        name = 'course_picture';
    } else if(e.target.id === "deleteVideo") {
        name = 'course_video';
    }    
    this.setState({
        [name]: ''
    });
  }
  submitData(datasend,imgurl,vidurl) {
    var urlFetch, datatext;
    var courseTitle,courseType,courseVenue,courseVenueAddress,courseVenueMaps,courseMeetLink,courseShortDesc,courseLongDesc,courseLanguage,coursePicture,courseVideo,courseCategory,courseSubCategory,courseCategoryId,courseCategoryName,courseSubCategoryId,courseSubCategoryName,courseTutorId,courseTutorName;
    var courseCurrency,coursePrice,courseDate,courseStartTimeHour,courseStartTimeMinute,courseEndTimeHour,courseEndTimeMinute;
    var arrCategory = [], arrSubCategory = [], arrTutor = [], courseTutorIds = [], courseTutorNames = [];
    if(imgurl !== '' && imgurl !== undefined) { coursePicture = imgurl; } else { coursePicture = this.state.course_picture; }
    if(vidurl !== '' && vidurl !== undefined) { courseVideo = vidurl; } else { courseVideo = this.state.course_video; }
    if(this.textInputCourseTitle.value === '') { courseTitle = this.state.course_title; } else { courseTitle = this.textInputCourseTitle.value; }
    if(this.textInputCourseType.value === '') { courseType = this.state.course_type; } else { courseType = this.textInputCourseType.value; }
    if(this.textInputCourseVenue.value === '') { courseVenue = this.state.course_venue; } else { courseVenue = this.textInputCourseVenue.value; }
    if(this.textInputCourseVenueAddress.value === '') { courseVenueAddress = this.state.course_venue_address; } else { courseVenueAddress = this.textInputCourseVenueAddress.value; }
    if(this.textInputCourseVenueMaps.value === '') { courseVenueMaps = this.state.course_venue_maps; } else { courseVenueMaps = this.textInputCourseVenueMaps.value; }
    if(this.textInputCourseMeetLink.value === '') { courseMeetLink = this.state.course_meet_link; } else { courseMeetLink = this.textInputCourseMeetLink.value; }
    if(this.textInputCourseShortDesc.value === '') { courseShortDesc = this.state.course_short_desc; } else { courseShortDesc = this.textInputCourseShortDesc.value; }
    if(this.textInputCourseLongDesc.value === '') { courseLongDesc = this.state.course_long_desc; } else { courseLongDesc = this.textInputCourseLongDesc.value; }
    if(this.textInputCourseLanguage.value === '') { courseLanguage = this.state.course_language; } else { courseLanguage = this.textInputCourseLanguage.value; }
    if(this.textInputCourseCategory.value === '') { courseCategory = this.state.course_category; } else { courseCategory = this.textInputCourseCategory.value; }
    if(this.textInputCourseSubcategory.value === '') { courseSubCategory = this.state.course_subcategory; } else { courseSubCategory = this.textInputCourseSubcategory.value; }
    if(this.textInputCourseCurrency.value === '') { courseCurrency = this.state.course_currency; } else { courseCurrency = this.textInputCourseCurrency.value; }
    if(this.textInputCoursePrice.value === '') { coursePrice = this.state.course_price; } else { coursePrice = this.textInputCoursePrice.value; }
    if(this.textInputCourseStartTimeHour.value === '') { courseStartTimeHour = this.state.course_start_time_hour; } else { courseStartTimeHour = this.textInputCourseStartTimeHour.value; }
    if(this.textInputCourseStartTimeMinute.value === '') { courseStartTimeMinute = this.state.course_start_time_minute; } else { courseStartTimeMinute = this.textInputCourseStartTimeMinute.value; }
    if(this.textInputCourseEndTimeHour.value === '') { courseEndTimeHour = this.state.course_end_time_hour; } else { courseEndTimeHour = this.textInputCourseEndTimeHour.value; }
    if(this.textInputCourseEndTimeMinute.value === '') { courseEndTimeMinute = this.state.course_end_time_minute; } else { courseEndTimeMinute = this.textInputCourseEndTimeMinute.value; }
    courseDate = this.state.course_date;
    courseShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',courseShortDesc));
    courseLongDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.course_long_desc));
    courseVenueAddress = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',courseVenueAddress));
    arrCategory = courseCategory.split("$$");
    if(arrCategory.length > 0) {
        courseCategoryId=arrCategory[0];
        courseCategoryName=arrCategory[1];
    }
    arrSubCategory = courseSubCategory.split("$$"); 
    if(arrSubCategory.length > 0) {
        courseSubCategoryId=arrSubCategory[0];
        courseSubCategoryName=arrSubCategory[1];
    }
    arrTutor = this.state.course_tutor;
    if(arrTutor.length > 0) {
        arrTutor.forEach((tutors) => {
            var tutor = tutors.split("$$");
            courseTutorIds.push(tutor[0]);
            courseTutorNames.push(tutor[1]);
        });
    }
    courseTutorId = courseTutorIds.join(',');
    courseTutorName = courseTutorNames.join(',');

    if(this.state.course_docid !== null && this.state.course_docid !== ''  && this.state.course_docid !== undefined) { 
        datatext ='{"course_title":"'+courseTitle+'","course_meet_link":"'+courseMeetLink+'","course_type":"'+courseType+'","course_venue":"'+courseVenue+'","course_venue_address":"'+courseVenueAddress+'","course_venue_maps":"'+courseVenueMaps+'","course_short_desc":"'+courseShortDesc+'","course_long_desc":"'+courseLongDesc+'","course_language":"'+courseLanguage+'","course_currency":"'+courseCurrency+'","course_price":"'+coursePrice+'","course_date":"'+courseDate+'","course_start_time_h":"'+courseStartTimeHour+'","course_start_time_m":"'+courseStartTimeMinute+'","course_end_time_h":"'+courseEndTimeHour+'","course_end_time_m":"'+courseEndTimeMinute+'","course_picture":"'+coursePicture+'","course_video":"'+courseVideo+'","course_category_id":"'+courseCategoryId+'","course_category_name":"'+courseCategoryName+'","course_subcategory_id":"'+courseSubCategoryId+'","course_subcategory_name":"'+courseSubCategoryName+'","course_tutor_id":"'+courseTutorId+'","course_tutor_name":"'+courseTutorName+'","course_docid":"'+this.state.course_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/courseedit";
    } else {
        datatext ='{"course_title":"'+courseTitle+'","course_meet_link":"'+courseMeetLink+'","course_type":"'+courseType+'","course_venue":"'+courseVenue+'","course_venue_address":"'+courseVenueAddress+'","course_venue_maps":"'+courseVenueMaps+'","course_short_desc":"'+courseShortDesc+'","course_long_desc":"'+courseLongDesc+'","course_language":"'+courseLanguage+'","course_currency":"'+courseCurrency+'","course_price":"'+coursePrice+'","course_date":"'+courseDate+'","course_start_time_h":"'+courseStartTimeHour+'","course_start_time_m":"'+courseStartTimeMinute+'","course_end_time_h":"'+courseEndTimeHour+'","course_end_time_m":"'+courseEndTimeMinute+'","course_picture":"'+coursePicture+'","course_video":"'+courseVideo+'","course_category_id":"'+courseCategoryId+'","course_category_name":"'+courseCategoryName+'","course_subcategory_id":"'+courseSubCategoryId+'","course_subcategory_name":"'+courseSubCategoryName+'","course_tutor_id":"'+courseTutorId+'","course_tutor_name":"'+courseTutorName+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/courseadd";
    }
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href=global.config.urlCms+"/"+this.state.activelink+"/detailedit/"+docId;
        } else {
            console.log('Something happened wrong');
        }

        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    
    if(this.state.course_title !== '' && this.state.course_type !== '' && this.state.course_short_desc !== '' && this.state.course_long_desc !== '' && this.state.course_language !== '' && this.state.course_category !== '' && this.state.course_subcategory !== '' && this.state.course_tutor !== ''
        && this.state.course_date !== '' && this.state.course_price !== '' && this.state.course_start_time_hour !== '' && this.state.course_start_time_minute !== '' && this.state.course_end_time_hour !== '' && this.state.course_end_time_minute !== ''
        ) {
        var arrErrField = [], arrErrValue = [];
        if(this.state.course_type !== '') {
            if(this.state.course_type === "Offline") { 
                if(this.state.course_venue === '') { arrErrField.push('course_venue'); arrErrValue.push(this.textInputCourseVenue.value); }
                if(this.state.course_venue_address === '') { arrErrField.push('course_venue_address'); arrErrValue.push(this.textInputCourseVenueAddress.value); }
                if(this.state.course_venue_maps === '') { arrErrField.push('course_venue_maps'); arrErrValue.push(this.textInputCourseVenueMaps.value); }
            } else if(this.state.course_type === "Online") { 
                if(this.state.course_meet_link === '') { arrErrField.push('course_meet_link'); arrErrValue.push(this.textInputCourseMeetLink.value); }
            }

        }
        if(arrErrField.length > 0) {
            for(var ck2 in arrErrField) {
                this.validateField(arrErrField[ck2], arrErrValue[ck2]);
            }    
        } else {

            document.getElementById('loading-area').style.display = 'block'; 
            var selectedFileValue = this.state.selectedFileDocument;
            var selectedFileValue2 = this.state.selectedFileDocument2;
            if((selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) 
            || (selectedFileValue2 !== null && ((selectedFileValue2.type.indexOf('pdf') > -1 || selectedFileValue2.type.indexOf('video') > -1|| selectedFileValue2.type.indexOf('image') > -1))) 
            ) { 
                const data = new FormData() 
                data.append('file', selectedFileValue);
                data.append('file2', selectedFileValue2);
                data.append('docid', this.state.course_docid);
                data.append('source', 'course');
                axios.post(global.config.urlLink+"/uploadfile", 
                data, { 
                    headers: { 
                        'content-type': 'multipart/form-data',
                        'Authorization': authKey
                    }
                }).then(res => { 
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(res.data.status === "200") {
                        this.submitData(this,res.data.values.linkfile1,res.data.values.linkfile2,res.data.values.linkfile3);
                    } else {
                        document.getElementById('loading-area').style.display = 'none'; 
                    }
                }).catch(err => { 
                    document.getElementById('loading-area').style.display = 'none'; 
                })
            } else {
                document.getElementById('loading-area').style.display = 'none'; 
                this.submitData(this,"","");
            }
        }
    
    } else {
        var arrfieldName = ['course_title','course_short_desc','course_long_desc','course_language','course_category','course_subcategory','course_tutor','course_price','course_date','course_start_time_hour','course_start_time_minute','course_end_time_hour','course_end_time_minute','course_type','selectedFileDocument'];
        var arrvalue = [this.textInputCourseTitle.value,this.textInputCourseShortDesc.value,this.textInputCourseLongDesc.value,this.textInputCourseLanguage.value,this.textInputCourseCategory.value,this.textInputCourseSubcategory.value,this.textInputCourseTutor.value,this.textInputCoursePrice.value,this.state.course_date,this.textInputCourseStartTimeHour.value,this.textInputCourseStartTimeMinute.value,this.textInputCourseEndTimeHour.value,this.textInputCourseEndTimeMinute.value,this.textInputCourseType.value,this.state.selectedFileDocument];
        if(this.textInputCourseType.value !== '') {
            if(this.textInputCourseType.value === "Offline") {
                arrfieldName.push('course_venue'); arrvalue.push(this.textInputCourseVenue.value);
                arrfieldName.push('course_venue_address'); arrvalue.push(this.textInputCourseVenueAddress.value);
                arrfieldName.push('course_venue_maps'); arrvalue.push(this.textInputCourseVenueMaps.value);
            } else {
                arrfieldName.push('course_meet_link'); arrvalue.push(this.textInputCourseMeetLink.value);
            }
        }
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  loadCart(docid) {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"","pageShow":"","form_id":"cart-list"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/traininguserlist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey4,
          }, body : JSON.stringify(data)
      }).then(response2 => {
          if(response2.status === 200) {
              return response2.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson2 => {
        if(resJson2.values.length>0) {
            var retCartItems=[];
            var j=0;
            var returndata = resJson2.values
                .filter(cartItem => cartItem.subcontentlist !== undefined)
                .map((cartItems) => {
                    cartItems.subcontentlist.forEach((cartItemDetail) => {
                        retCartItems.push(cartItemDetail);
                    })
                    return(retCartItems);
                })
            var returndata2 = retCartItems
                .filter(courseItem => courseItem.subcontent.courseId === docid)
            resolve(returndata2);
        } else {
            resolve([]);
        }
      });      
    })
  }
  loadSubcategory(catid) {
    fetch(global.config.urlLink+"/categoryview/course/"+catid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') { 
            this.setState( prevState => {
                return {  
                    ...prevState.subcategoryList, subcategoryList: resJson.values[0].scontent, 
                }
            }) 
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => err);
  }
  loadCategory() {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"","pageShow":"","usageFor":"course"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/categorylist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey2,
          }, body : JSON.stringify(data)
      }).then(response2 => {
          if(response2.status === 200) {
              return response2.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson2 => {
        resolve(resJson2);
      });
    })
  }
  loadTutor() {
    return new Promise((resolve) => {
      var datatext ='{"keyword":"","pageShow":"","usageFor":"course"}';
      var data = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/tutorlist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey3,
          }, body : JSON.stringify(data)
      }).then(response2 => {
          if(response2.status === 200) {
              return response2.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson2 => {
        resolve(resJson2);
      });
    })
  }
  loadContent(docid) {
    return new Promise((resolve) => {
      fetch(global.config.urlLink+"/courseview/"+docid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(response2 => {
          if(response2.status === 200) {
              return response2.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson2 => {
        resolve(resJson2);
      });
    });
  }
  async loadData(currpage,typelink,docid) {
      if(typelink === 'edit' && docid !== "") {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result1, result2, result3, result4] = await Promise.all([this.loadContent(docid), this.loadCategory(), this.loadTutor(), this.loadCart(docid)]);
        
        if(result1 !== undefined && result1.status === '200') { 
          document.getElementById('loading-area').style.display = 'none'; 
          if(result1.values[0].content[0].maincontent !== undefined) {
            var resultContent = result1.values[0].content[0].maincontent;
            var lTitle="",lType="",lVenue="",lVenueAddress="",lVenueMaps="",lMeetLink="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="",lPriceCurrency="",lPrice="",lTutor=[];
            var crsDate = new Date();
            var crsTimeSHour = 8;
            var crsTimeSMinute = 0;
            var crsTimeEHour = 17;
            var crsTimeEMinute = 0;
            
            if(resultContent.courseTitle !== undefined) { lTitle = resultContent.courseTitle; }
            if(resultContent.courseType !== undefined) { lType = resultContent.courseType; }
            if(resultContent.courseVenue !== undefined) { lVenue = resultContent.courseVenue; }
            if(resultContent.courseVenueMaps !== undefined) { lVenueMaps = resultContent.courseVenueMaps; }
            if(resultContent.courseMeetLink !== undefined) { lMeetLink = resultContent.courseMeetLink; }
            if(resultContent.courseShortDesc !== undefined) {
            lShortDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.courseShortDesc));
            }
            if(resultContent.courseLongDesc !== undefined) { 
                lLongDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.courseLongDesc));
            }
            if(resultContent.courseVenueAddress !== undefined) { 
                lVenueAddress = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.courseVenueAddress));
            }
            if(resultContent.courseLanguage !== undefined) { 
                lLanguage = resultContent.courseLanguage;
            }
            if(resultContent.categoryId !== undefined) { 
                lCategory = resultContent.categoryId+"$$"+resultContent.categoryName;
                this.loadSubcategory(resultContent.categoryId);
            }
            if(resultContent.subcategoryId !== undefined) { 
                lSubCategory = resultContent.subcategoryId+"$$"+resultContent.subcategoryName;
            }
            if(resultContent.coursePicture !== undefined) { 
                lPicture = resultContent.coursePicture;
            }
            if(resultContent.courseVideo !== undefined && resultContent.courseVideo !== "") { 
                lVideo = resultContent.courseVideo;
            }
            if(resultContent.courseCurrency !== undefined) { 
                lPriceCurrency = resultContent.courseCurrency;
            }
            if(resultContent.coursePrice !== undefined) { 
                lPrice = resultContent.coursePrice;
            }
            if(resultContent.courseDate !== undefined) { 
                crsDate = new Date(resultContent.courseDate._seconds*1000); 
            }
            if(resultContent.courseStartTime !== undefined) { 
                var crsStartTime = new Date(resultContent.courseStartTime._seconds*1000);
                crsTimeSHour = crsStartTime.getHours();
                crsTimeSMinute = crsStartTime.getMinutes();
            }  
            if(resultContent.courseEndTime !== undefined) { 
                var crsEndTime = new Date(resultContent.courseEndTime._seconds*1000);
                crsTimeEHour = crsEndTime.getHours();
                crsTimeEMinute = crsEndTime.getMinutes();
            }
            if(resultContent.tutorId !== undefined) {
                if (resultContent.tutorId.length > 0) {
                    var tutor_ids = resultContent.tutorId.split(',');
                    var tutor_names = resultContent.tutorName.split(',');
                    if (tutor_ids.length > 0) {
                        tutor_ids.forEach((tutor, i) => {
                            lTutor.push(tutor_ids[i]+"$$"+tutor_names[i]);
                        });
                    }
                }
            }
            this.setState({
                activelink: currpage,
                stTitle: 'Edit Workshop',
                course_docid: result1.values[0].docid,
                course_title: lTitle,
                course_type: lType,
                course_venue: lVenue,
                course_venue_address: lVenueAddress,
                course_venue_maps: lVenueMaps,
                course_meet_link: lMeetLink,
                course_short_desc: lShortDesc,
                course_long_desc: lLongDesc,
                course_language: lLanguage,
                course_picture: lPicture,
                course_video: lVideo,
                course_category: lCategory,
                course_subcategory: lSubCategory,
                course_tutor: lTutor,
                course_currency: lPriceCurrency,
                course_price: lPrice,
                course_date: crsDate,
                course_start_time_hour: crsTimeSHour,
                course_start_time_minute: crsTimeSMinute,
                course_end_time_hour: crsTimeEHour,
                course_end_time_minute: crsTimeEMinute,
                categoryList: result2,
                tutorList: result3,
                selectedFileDocument: null,
                selectedFileDocument2: null,
                formErrors: {course_title: '', course_venue: '', course_venue_address: '', course_venue_maps: '', course_meet_link: '', course_short_desc: '', course_long_desc: '', course_language: '', course_category: '', course_subcategory: '', course_tutor: '', course_currency: '', course_price: '', course_date: '', course_start_time: '', course_end_time: '', selectedFileDocument:'', selectedFileDocument2:''},
                coursetitleValid: false,
                coursevenueValid: false,
                coursevenueaddressValid: false,
                coursevenueMapsValid: false,
                coursemeetlinkValid: false,
                courseshortdescValid: false,
                courselongdescValid: false,
                courselanguageValid: false,
                coursecategoryValid: false,
                coursesubcategoryValid: false,
                coursetutorValid: false,
                coursecurrencyValid: false,
                coursepriceValid: false,
                coursedateValid: false,
                coursestarttimehourValid: false,
                coursestarttimeminuteValid: false,
                courseendtimehourValid: false,
                courseendtimeminuteValid: false,
                selectedFileDocumentValid: false,
                selectedFileDocument2Valid: false,
                formValid: false,
                input_disabled: result4.length > 0? true : false
            }) 
          } else {
            window.location.href=global.config.urlCms+"/"+currpage+"/add";
          }
        } else {
          window.location.href=global.config.urlCms+"/"+currpage+"/add";
        }
      } else if(typelink === 'add') {
        document.getElementById('loading-area').style.display = 'block'; 
        var [result2b, result3b] = await Promise.all([this.loadCategory(), this.loadTutor()]);
        if(result2b) {
          document.getElementById('loading-area').style.display = 'none'; 
          var crsLanguage = "", crsCurrency;
          if(docid === "eng") { crsLanguage = "English"; crsCurrency = "USD"; }
          else if(docid === "idn") { crsLanguage = "Indonesian"; crsCurrency = "IDR"; }
            
          this.setState( prevState => {
              return {
                ...prevState.activelink, activelink: currpage,
                ...prevState.stTitle, stTitle: GetText() ? "Tambah Workshop" : "Add Workshop",
                ...prevState.categoryList, categoryList: result2b,
                ...prevState.tutorList, tutorList: result3b,

                ...prevState.course_currency, course_currency: crsCurrency,
                ...prevState.course_language, course_language: crsLanguage,
                ...prevState.tab_language, tab_language: crsLanguage,
                
              }
          })
        }
    }  
    
  }
  componentDidMount() {
    var urlpath = window.location.pathname;
    if(urlpath.substring(urlpath.length-1,urlpath.length) === "/") {
      urlpath = urlpath.substring(0,urlpath.length-1);
    }
    var arrUrl = urlpath.split("/");
    
    var tabKey="", tabKey2="", docId="";
    if(arrUrl.length===4 && arrUrl[arrUrl.length-1] !== "") {
      tabKey = arrUrl[arrUrl.length-2];
      tabKey2 = arrUrl[arrUrl.length-1];
    } else if(arrUrl.length===5 && arrUrl[arrUrl.length-1] !== "") {
        tabKey = arrUrl[arrUrl.length-3];
        tabKey2 = arrUrl[arrUrl.length-2];
        docId = arrUrl[arrUrl.length-1];
    }
    if(tabKey === "") {
      this.setState({
          activelink: "pnf",
          stTitle : (tabKey2 === "add")? "Add " : (tabKey2 === "edit") ? "Edit " : "",
        });
    } else {
      this.loadData(tabKey,tabKey2,docId);
    }
    
  }

  render() {
    var selcategoryList = [];
    if(this.state.categoryList.values.length > 0) {
      for(var u in this.state.categoryList.values) {
        var selvalue = this.state.categoryList.values[u].docid+'$$'+this.state.categoryList.values[u].content.categoryName;
        selcategoryList.push(<option value={selvalue} key={u}>{this.state.categoryList.values[u].content.categoryName}</option>)
      }
    }

    var selsubcategoryList = [];
    if(this.state.subcategoryList.length > 0) {
        for(var v in this.state.subcategoryList) {
        var selvalue2 = this.state.subcategoryList[v].subdocid+'$$'+this.state.subcategoryList[v].subcontent.subcategoryName;
        selsubcategoryList.push(<option value={selvalue2} key={v}>{this.state.subcategoryList[v].subcontent.subcategoryName}</option>)
        }
    }
    var seltutorList = [];
    if(this.state.tutorList.values.length > 0) {
      for(var x in this.state.tutorList.values) {
        var selvalue3 = this.state.tutorList.values[x].docid+'$$'+this.state.tutorList.values[x].content.tutorName;
        seltutorList.push(<option value={selvalue3} key={x}>{this.state.tutorList.values[x].content.tutorName}</option>)
      }
    }
  
    var hourList = [];
    for(var h=0; h<24; h++) {
        hourList.push(<option value={h} key={h}>{h.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}</option>)
    }
    var minuteList = [];
    for(var m=0; m<60; m+=15) {
        minuteList.push(<option value={m} key={m}>{m.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}</option>)
    }

    var optLanguage = [];
    if(this.state.tab_language === "English") {
        optLanguage.push(<option value="English" selected>English</option>)
    } else if(this.state.tab_language === "Indonesian") {
        optLanguage.push(<option value="Indonesian" selected>Indonesian</option>)
    } else {
        optLanguage.push(<option value="">-- please select --</option>)
        optLanguage.push(<option value="English">English</option>)
        optLanguage.push(<option value="Indonesian">Indonesian</option>)
    }

    var optCurrency = [];
    if(this.state.tab_language === "English") {
        optCurrency.push(<option value="USD" selected>USD</option>)
    } else if(this.state.tab_language === "Indonesian") {
        optCurrency.push(<option value="IDR" selected>IDR</option>)
    } else {
        optCurrency.push(<option value="">-- please select --</option>)
        optCurrency.push(<option value="USD">USD</option>)
        optCurrency.push(<option value="IDR">IDR</option>)
    }
    return (
      <div className="setting">
       <Container>
        { (this.state.activelink === "pnf")? 
          <Error404 />
          : 
          (localStorage.getItem("ulvl") === global.config.userLevel[0] || localStorage.getItem("ulvl") === global.config.userLevel[2])? 
          (<>
            <div className="row py-5 mb-5">
              {/* === start - body */}
                <div className="col-md-3 bg-light">
                  { 
                    (this.state.activelink !== "none") && 
                      <MenuLeftCMS value={{tabkey:this.state.activelink}}/>
                  
                  }
                </div>
                <div className="col-md-9 p-4">
                  <div className="row">
                    <div>
                      <h3 className="mb-4">{GetText() ? " Workshop" : " Workshop"}</h3>
                    </div>
                    <div className="mt-3">
                      <div className="row show">
                          <div className="col-6"><h5>{this.state.stTitle} </h5></div>
                          <div className="col-6 d-flex justify-content-end">
                             <Button variant="secondary" size="sm" onClick={this.handleBack.bind(this, this.state.activelink,this.state.course_docid)}>&#9664; back</Button>
                          </div>
                      </div>
                      <div className="row mt-3">
                            <form onSubmit={this.handleSubmit}>
                                <div >
                                  <input type="hidden" required name="course_docid" value={this.state.course_docid} ref={(input) => this.textInputCourseId = input} />
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_title">Title</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="course_title"
                                            placeholder={this.state.course_title}
                                            value={this.state.course_title}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputCourseTitle = input}
                                            disabled={this.state.input_disabled}
                                            />
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_title}/>  }</div>
                                    </div>
                                </div>
                                
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_short_desc">Short Description</label>
                                    <div className="col-md-9">
                                        <textarea name="course_short_desc" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.course_short_desc} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputCourseShortDesc = input}
                                                maxlength="350"/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_short_desc}/>  }</div>
                                        <div className="mt-2 fieldComments">
                                        &bull; Content must not exceed more than 350 characters
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_long_desc">Full Description</label>
                                    <div className="col-md-9">
                                        <textarea name="course_long_desc" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.course_long_desc} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputCourseLongDesc = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_long_desc}/>  }</div>
                                    </div>
                                </div>
                            
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_category">Category</label>
                                    <div className="col-md-9">
                                        <select name="course_category" className="form-control" 
                                            value={this.state.course_category} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputCourseCategory = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        <option value="" key="">-- please select --</option>
                                        {selcategoryList}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_category}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_subcategory">Sub Category</label>
                                    <div className="col-md-9">
                                        <select name="course_subcategory" className="form-control" 
                                            value={this.state.course_subcategory} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputCourseSubcategory = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        <option value="" key="">-- please select --</option>
                                        {selsubcategoryList}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_subcategory}/>  }</div>
                                    </div>
                                </div>                                
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_tutor">Tutor</label>
                                    <div className="col-md-9">
                                        <select name="course_tutor" className="form-control" 
                                            value={this.state.course_tutor} 
                                            onChange={this.handleUserMultiSelect}
                                            ref={(input) => this.textInputCourseTutor = input}
                                            multiple={true}
                                        >
                                        {seltutorList}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_tutor}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_language">Language</label>
                                    <div className="col-md-9">
                                        <select name="course_language" className="form-control" 
                                            value={this.state.course_language} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputCourseLanguage = input}
                                            disabled={this.state.input_disabled}
                                        >
                                        {optLanguage}
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_language}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_price">Price</label>
                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <select name="course_currency" className="form-control" style={{width:"150px"}}
                                                value={this.state.course_currency} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseCurrency = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            {optCurrency}
                                            </select>
                                            <input type="number" className="form-control ms-2" name="course_price"
                                                placeholder={this.state.course_price}
                                                value={this.state.course_price}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputCoursePrice = input}
                                                disabled={this.state.input_disabled}
                                                min="0"/> 
                                        </div>
                                        
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_price}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_date">Event Date</label>
                                    <div className="col-md-9">
                                            <DatePicker
                                                onChange={this.handleEventDate}
                                                value={this.state.course_date}
                                                selected={this.state.course_date}
                                                dateFormat="MMMM d, yyyy"
                                                className="form-control"
                                                disabled={this.state.input_disabled}
                                                />
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_date}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_start_time">Start Time</label>
                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <select name="course_start_time_hour" className="form-control" style={{width:"70px"}}
                                                value={this.state.course_start_time_hour} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseStartTimeHour = input}
                                                disabled={this.state.input_disabled}
                                            >{hourList}
                                            </select> &nbsp;
                                            <select name="course_start_time_minute" className="form-control mgL10" style={{width:"70px"}}
                                                value={this.state.course_start_time_minute} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseStartTimeMinute = input}
                                                disabled={this.state.input_disabled}
                                            >{minuteList}
                                            </select>
                                        </div>
                                        
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_start_time}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_end_time">End Time</label>
                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <select name="course_end_time_hour" className="form-control" style={{width:"70px"}}
                                                value={this.state.course_end_time_hour} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseEndTimeHour = input}
                                                disabled={this.state.input_disabled}
                                            >{hourList}
                                            </select> &nbsp;
                                            <select name="course_end_time_minute" className="form-control mgL10" style={{width:"70px"}}
                                                value={this.state.course_end_time_minute} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseEndTimeMinute = input}
                                                disabled={this.state.input_disabled}
                                            >{minuteList}
                                            </select>
                                        </div>
                                        
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_end_time}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_type">Type</label>
                                    <div className="col-md-9">
                                        <select name="course_type" className="form-control" 
                                            value={this.state.course_type} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputCourseType = input}
                                        >
                                            <option value="">-- please select --</option>
                                            <option value="Online">Online</option>
                                            <option value="Offline">Offline</option>
                                        </select>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_type}/>  }</div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_venue">Venue</label>
                                    <div className="col-md-9">
                                        <div>
                                            <div>Address</div>
                                            <div>
                                                <textarea name="course_venue_address" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.course_venue_address} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputCourseVenueAddress = input}/>
                                            </div>
                                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_venue_address}/>  }</div>
                                            <div>Location (will be shown when the user hasn't purchase this)</div>
                                            <div>
                                                <input type="text" className="form-control" name="course_venue"
                                                    placeholder={this.state.course_venue ? this.state.course_venue : ''}
                                                    value={this.state.course_venue ? this.state.course_venue : ''}
                                                    onChange={this.handleUserInput}
                                                    ref={(input) => this.textInputCourseVenue = input}/>
                                                
                                            </div>
                                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_venue}/>  }</div>
                                            <div>Google Maps Link</div>
                                            <div>
                                                <input type="text" className="form-control" name="course_venue_maps"
                                                    placeholder={this.state.course_venue_maps ? this.state.course_venue_maps : ''}
                                                    value={this.state.course_venue_maps ? this.state.course_venue_maps : ''}
                                                    onChange={this.handleUserInput}
                                                    ref={(input) => this.textInputCourseVenueMaps = input}/>
                                                
                                            </div>
                                            <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_venue_maps}/>  }</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_meet_link">Meet Link</label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" name="course_meet_link"
                                            placeholder={this.state.course_meet_link ? this.state.course_meet_link : ''}
                                            value={this.state.course_meet_link ? this.state.course_meet_link : ''}
                                            onChange={this.handleUserInput}
                                            ref={(input) => this.textInputCourseMeetLink = input}/>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.course_meet_link}/>  }</div>
                                        <a href="https://meet.google.com/new" className="btn btn-sm btn-secondary" target="_blank" rel="noreferrer">Create a link</a>
                                    </div>
                                </div>
                                {(this.state.course_picture !== "") && 
                                <div className="row mt-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9 d-flex" >
                                      {/* display: flex; flex-direction: row; column-gap: 15px; align-items: flex-end; */}
                                        <img width="150" src={this.state.course_picture} alt=""/>
                                        <a className="linkText ms-2" href="#!" onClick={this.onDeleteFileHandler} id="deletePicture"><FaTrash /> Delete Picture &raquo;</a>
                                    </div>
                                </div> 
                                }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_document">Upload Picture</label>
                                    <div className="col-md-9">
                                        <input id="fileDocument" type="file"
                                            onChange={this.onChangeFileHandler} 
                                            disabled={this.state.input_disabled}
                                        />
                                        <div className="mt-2 fieldComments">
                                        
                                        &bull; Please upload file in jpg, jpeg, png format.<br/>
                                        &bull; Dimension image min 350 px x 350 px with ratio 1 : 1
                                        
                                        </div>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                        
                                    </div>
                                </div>
                                {(this.state.course_video !== "" && this.state.course_video !== undefined)?
                                <div className="row mt-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9 d-flex">
                                        <iframe src={this.state.course_video} title={this.state.course_title} do-not-allow="autoplay"></iframe>
                                        <a className="linkText ms-2" href="#!" onClick={this.onDeleteFileHandler} id="deleteVideo"><FaTrash /> Delete Video &raquo;</a>
                                    </div>
                                </div>
                                : '' }
                                <div className="row mt-3">
                                    <label className="col-md-3" htmlFor="course_document2">Upload Video Intro</label>
                                    <div className="col-md-9">
                                        <input id="fileDocument2" type="file"
                                            onChange={this.onChangeFileHandler}>
                                        </input> 
                                        <div className="mt-2 fieldComments">
                                        &bull; Please upload file in mp4 format.
                                        </div>
                                        <div className="mt-1">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                        
                                    </div>
                                </div>
                                <div className="row mt-4 mb-3">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-9"><Button type="submit" variant="secondary">Submit</Button></div>
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              {/* === end - body */}
            </div>
            
            </>
          )
        : 
          <div><CTNA/></div>
        }
        <div id="loading-area" className="d-none loading"></div> 
        </Container>
      </div>
    );
  }
}
