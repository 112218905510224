let Credentials = require('./oa_credentials.json');
let sessionUToken = "";
let vers = "lctr";
if(window.location.hostname === "novaio.academy" || window.location.hostname === "www.novaio.academy") { vers = "lvtr"; } 
// if(localStorage.getItem('google_id') !== null) { sessionUToken = localStorage.getItem('google_id'); }
if(localStorage.getItem('doc_id') !== null && localStorage.getItem('doc_id') !== undefined) { sessionUToken = localStorage.getItem('doc_id'); }
module.exports = global.config = {
    /* #### LIVE VERSION ####### */
    urlLink: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/apina',
    urlLinkXn: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/apipg/paymentid',
    urlLinkSt: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/apipg/paymentus',
    urlHost: 'https://novaio.academy',
    urlCms: '/c',
    clientLoginId: "748862631768-6b6mdqdpi8bomjhe232sm83205qrc8cq.apps.googleusercontent.com", 
    xenditKey: 'xnd_public_production_xv6wQuc6XyPwuahcJWyxotlqBCyV8fkXFXv3RqFQ9wuOU79PYunFVnzdLdMqETb',
    stripeKey: 'pk_live_4BCPwPEqHS0d3VKKa9kGv8NC',

    /* #### DEVELOPMENT VERSION ####### */
    // urlLink: 'http://localhost:4001',
    // clientLoginId: "4115767564-q28j63gonn7lcege7rt6spj3ncol31c9.apps.googleusercontent.com", 
    // xenditKey: 'xnd_public_development_LSJ98v4cd0HnwdwtGdBgbDVM4mUSp2HUZUGFz2GzMO4zDdry0gURMDMzbQSFXRJf',
    // stripeKey:'sk_test_O0a7CIvh87SI3s2eYV0dWYCW',

    /* #### GENERAL ####### */
    timezone: 'en-GB',
    datetimeoption: '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}',
    randomLoginKey: "kyY732L",
    
    authBearerKey: Credentials.web.client_secret+vers+sessionUToken,
    credApiKey: Credentials.web.api_key,
    kindcode:  {
        training: [
            "93Y210","73P159","18J332","74G679","38S299","2Y8KH0","52I768","90K114","61L385","J3R201",
            "98E213","88L352","17U196","44P737","77W190","G1K529","42D105","82E401","02K195","27T189",
            "51T359"
        ]
    },
    subscribePlan: [
        {name:["bulan","month"],period:1,type:"Monthly",price:[30000,2],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]},
        {name:["tahun","year"],period:1,type:"Yearly",price:[300000,20],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]}
    ],
    userLevel: ["Admin","User","Content Editor","Admin Company"],
    memberPlan: ["Guest", "Member"],
    subscribeMemberStatus: ["No Subscribe","On Process","Subscribe","Expired"],
    subscribeStatus: ["New Request","Active","Expired","Cancel"],
    libraryStatus: ["Draft","Publish","Unpublish"],
    courseStatus: ["Draft","Publish","Unpublish"],
    ticketStatus: ["active","inactive"],
    voucherStatus: ["Active","Inactive"],
    voucherQuotaType: ["Unlimited", "Limited"],
    orderItemType: ["Course","Subscription"],
    orderStatus: ["Pending Payment","Pending Verification","Verified","Canceled","Expired","Not Verified"],
    paymentStatus: ["Unpaid","Paid"],
    paymentMethod: {
        "ENG": [
            {"label":"Credit Card", "value": "Credit Card"},
            {"label":"Bank Transfer", "value": "Bank Transfer"}
        ],
        "IDN": [
            {"label":"Kartu Kredit", "value": "Credit Card"},
            {"label":"Bank Transfer", "value": "Bank Transfer"},
            {"label":"Virtual Account", "value": "Virtual Account"},
            {"label":"QRIS", "value": "QRIS"},
            {"label":"EWallet", "value": "eWallet"}
        ],
    },
    paymentAccount: {
        "Bank Transfer" : [
            {"BankCode": "BCA", "BankName": "Bank Central Asia",  "BankAccount": "2060629245","BankHolder": "PT. POLARISWEB"}
        ]
    },
    currency: {
        symbol: ["Rp ","$ "],
        name: ["IDR","USD"],
        countrycode: ["ID","EN"]
    },
    paymentWallets : [
        { "name": "DANA", "code": "ID_DANA", "logo": "/img/ewallet-logo-dana.jpg"},
        { "name": "OVO", "code": "ID_OVO", "logo": "/img/ewallet-logo-ovo.jpg"},
        { "name": "LinkAja", "code": "ID_LINKAJA", "logo": "/img/ewallet-logo-linkaja.jpg"},
        { "name": "ShopeePay", "code": "ID_SHOPEEPAY", "logo": "/img/ewallet-logo-shopeepay.jpg"},
        { "name": "AstraPay", "code": "ID_ASTRAPAY", "logo": "/img/ewallet-logo-astrapay.jpg"},
      ],
    faqCategory: {
        "ENG": [
            {"label":"General", "value": "General"},
            {"label":"Library", "value": "Library"},
            {"label":"Workshop", "value": "Workshop"},
            {"label":"Order", "value": "Order"},
        ],
        "IDN": [
            {"label":"Umum", "value": "General"},
            {"label":"Library", "value": "Library"},
            {"label":"Workshop", "value": "Workshop"},
            {"label":"Pesanan", "value": "Order"},
        ],
    },
    storageUrl: 'https://storage.googleapis.com/ps-product-internalportal-api.appspot.com/',
}