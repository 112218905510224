import React, { Component } from 'react';
// import Moment from 'moment';
import { Container, Tab, Nav } from 'react-bootstrap';
import { FaFileLines, FaCheck } from 'react-icons/fa6';
import { GetText } from '../config/helper';
import '../config/global';
import defLogo from '../img/default-logo.png';
import Popup from '../inc/PopUp';
import Loading from '../inc/Loading';
// import '../cms/cptraining/ctinc/popup.css';
import SettingsPopup from './SettingsPop';
import DateTimeFormats from '../config/date-time-format';

const authKey = global.config.kindcode.training[6]+global.config.authBearerKey;

export default class Account extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      memberEmail: localStorage.getItem('email'),
      companyName: "",
      companyDomain: "",
      companyLogo: "",
      companyExpiredSubs: "",
      companyMemberList: [],
      tabKey: '',
      isOpen: false,
      memberdoc_id: '',
      otherContent: '', 
      isLoad: false
    }
  }

  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false
      }
    })
  } 
  
  urldetailCompanyMember(valId,valContent) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.memberdoc_id, memberdoc_id: valId,
          ...prevState.otherContent, otherContent: '{"sendcontent":'+valContent+'}', 
      }
    })
  }
  setTab() {
    var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);
    if(tabKey === "settings") { tabKey = "companyMember"; }
    return tabKey ? tabKey : 'companyMember';
  }
  componentDidMount() {
    var dataSend = {
      "memberid": this.state.memberId,
      "companydomain": localStorage.getItem("mcomp")
    }
    fetch(global.config.urlLink+"/traininguserclient", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      },
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    })
    .then(resultJson => {
      if(resultJson.status === "200") {
        var memberCList = [];
        if(resultJson.values.memberListData.length >> 0) {
          resultJson.values.memberListData.forEach(function (item) {
            var llogin = item.content[0].maincontent.lastLogin;
            if( llogin === undefined) { llogin = ""; } 
            else { llogin = llogin._seconds; }
            var persentage_complete_value = 0;
            if(item.subcontent3.length > 0) {
              var complete_value = 0;
              var numcourse = 0;
              var allowCalc = true;
              for(var lb=0; lb< item.subcontent3.length; lb++) {
                if(localStorage.getItem("mcomp") === "aegis.com.bn") { 
                  if(item.subcontent3[lb].subcontent.libraryId === "uFVVvRrtCkecprqkLcip") { allowCalc = true; }
                  else { allowCalc = false; }
                }
                if(allowCalc === true) {
                  numcourse++;
                  if(item.subcontent3[lb].subcontent.completion !== "" && item.subcontent3[lb].subcontent.completion !== undefined) {
                    complete_value += Number(item.subcontent3[lb].subcontent.completion);
                  } else { complete_value += 0; }
                }
              }
              if(complete_value > 0) { persentage_complete_value = (complete_value/numcourse).toFixed(0); }
            }
            
            memberCList.push(
              {
                "docid":item.docid,
                "membername":item.content[0].maincontent.memberName, 
                "memberemail": item.content[0].maincontent.memberEmail,
                "memberpicture": item.content[0].maincontent.memberPicture,
                "memberexpiredsubs": item.content[0].maincontent.expiredSubscribeMember !== undefined? item.content[0].maincontent.expiredSubscribeMember._seconds : '',
                "memberlastlogin": llogin,
                "numoflibrary": item.subcontent3.length,
                "progresslibrary": persentage_complete_value,
                "memberdetail": item
              }
            );
          });
        }
        this.setState({
          companyName: resultJson.values.companyData[0].content.companyName,
          companyDomain: resultJson.values.companyData[0].content.companyDomain,
          companyLogo: resultJson.values.companyData[0].content.companyLogo,
          companyExpiredSubs: resultJson.values.companyData[0].content.expiredSubscription !== undefined? DateTimeFormats(resultJson.values.companyData[0].content.expiredSubscription._seconds,"date-long") : '',
          companyMemberList: memberCList,
          isLoad: true
        });
      }
    });
  }

  render() {   
    return (
      <div className="profile">
      {this.state.isLoad === true? 
       <>
       <Container>
          <div className="row py-5 mb-5">
            <Tab.Container defaultActiveKey={this.setTab}>
              <div className="col-md-4">
                <div className="text-center">
                  <img className="mb-4" src={this.state.companyLogo === ""? defLogo :this.state.companyLogo} alt="Logo" width={100}/>
                  <h5><strong>{this.state.companyName}</strong></h5>
                  <h6 className="text-secondary">{this.state.companyDomain}</h6>
                  <div className="text-secondary">{GetText() ? "Berakhir pada tanggal " : "Expired on "} {this.state.companyExpiredSubs}</div>
                </div>
                <Nav variant="pills" className="flex-column pills-scroll mt-5 mb-5 mb-md-0  d-none">
                  <Nav.Item><Nav.Link eventKey="companyMember">{GetText() ? "Anggota" : "Member"}</Nav.Link></Nav.Item>
                </Nav>
              </div>
              <div className="col-md-8 p-4 bg-light">
                <Tab.Content>
                  <Tab.Pane eventKey="companyMember">
                    <h3 className="mb-4">{GetText() ? "Daftar Anggota" : "Member List"}</h3>
                    <div className="table-wrapper">
                      {
                        this.state.companyMemberList.length > 0 ? (
                          <table className="table">
                            <thead className="table-dark">
                              <tr>
                                <th width="50" className="text-center">No.</th>
                                <th>{GetText() ? "Data Anggota" : "Member"}</th>
                                {/* <th width="150">{GetText() ? "Tanggal Berakhir" : "Expired Date"}</th> */}
                                <th width="100">{GetText() ? "Sudah Bergabung" : "Has Joined"}</th>
                                <th width="100">{GetText() ? "Materi Yang Diambil" : "Taken Course"}</th>
                                <th width="100">{GetText() ? "Perkembangan" : "Progress"}</th>
                                <th width="80">Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                this.state.companyMemberList
                                .sort((a, b) => a.memberexpiredsubs < b.memberexpiredsubs)
                                .map((Company_Member_Item, i) => {
                                  return (
                                    <tr key={"company-member-list-" + i}>
                                      <td className="text-center">{(i + 1)}.</td>
                                      <td>
                                        {Company_Member_Item.membername}
                                        <br/><span style={{fontSize:"12px"}}>{Company_Member_Item.memberemail}</span>
                                        {
                                        Company_Member_Item.memberexpiredsubs ?
                                        <>
                                          <br/><span style={{fontSize:"12px"}}>
                                          {GetText() ? "Berakhir tanggal " : "Expired on "}
                                          <strong>{DateTimeFormats(Company_Member_Item.memberexpiredsubs,"date-long")}</strong>
                                          </span>
                                        </>
                                        : <></>
                                        }
                                        
                                      </td>
                                      {/* <td>
                                        {Company_Member_Item.memberexpiredsubs ? (Moment.unix(Company_Member_Item.memberexpiredsubs).format('D MMMM Y')) : ('')}
                                      </td> */}
                                      <td>
                                        {Company_Member_Item.memberlastlogin !== ""? <FaCheck /> : '-'}
                                      </td>
                                      <td>{Company_Member_Item.numoflibrary}</td>
                                      <td>{Company_Member_Item.progresslibrary}%</td>
                                      <td >
                                      <FaFileLines className="mx-2" style={{height:"30px", cursor:"Pointer"}} onClick={this.urldetailCompanyMember.bind(this, Company_Member_Item.docid, JSON.stringify(Company_Member_Item.memberdetail))}/>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <p className="mt-4">{GetText() ? "Belum ada anggota" : "No Member"}</p>
                          </div>
                        )
                      }
                    </div>
                  </Tab.Pane>
                  
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Container>
        <div>
        {this.state.isOpen && <Popup
            content={<><SettingsPopup/></>}
            id={this.state.memberdoc_id} 
            title={"Member Information"}
            others={this.state.otherContent}
            styles={"70%"}
            handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
          />}
        </div>
        </>
      : <Loading value={{id:'settings-area', type: 'screen'}}/> 
    }
      </div>
    );
  }
}